import { ApolloCache, DefaultOptions } from '@apollo/client';
import * as Localization from 'expo-localization';

import { APP_SLUG, IS_PRODUCTION, manifest } from '../constants';
import { logout } from '../hooks/useLogout';
import { createApolloCacheBase, createApolloClientBase } from '../lib/apolloClientBase';
import { getAuthorizationHeader, setAuthTokenExpiresAt } from '../lib/auth';

export const createApolloCache = createApolloCacheBase;

export const createApolloClient = (
  uri: string,
  {
    subscriptionUri,
    connectToDevTools = false,
    getAuthHeader: _getAuthHeader,
    cache = createApolloCache(),
    defaultOptions = {},
  }: {
    subscriptionUri?: string;
    connectToDevTools?: boolean;
    getAuthHeader?: () => Promise<string>;
    cache?: ApolloCache<unknown>;
    defaultOptions?: DefaultOptions;
  } = {},
) => {
  return createApolloClientBase({
    uri,
    subscriptionUri,
    isProduction: IS_PRODUCTION,
    onUpdateAuthTokenExpiry: (date) => void setAuthTokenExpiresAt(date),
    onLogout: logout,
    clientName: APP_SLUG,
    clientVersion: manifest.version,
    clientTimezone: Localization.timezone,
    clientLocale: Localization.locale,
    getAuthHeader: _getAuthHeader || getAuthorizationHeader,
    connectToDevTools,
  });
};

import { ComponentProps, forwardRef } from 'react';
import {
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';
import { TextInput as RNTextInput } from 'react-native';
import { TextInputMask } from 'react-native-masked-text';
import { z } from 'zod';

import { TextInput } from '../components/TextInput';
import { ControllerProps, InputRenderer } from '../form';

const TextInputWrapper = forwardRef<
  RNTextInput,
  ComponentProps<typeof TextInput> & ComponentProps<typeof TextInputMask>
>(({ customTextInput, customTextInputProps, ...p }, ref) => <TextInput {...p} ref={ref} />);

export function PhoneInput({ value, onChangeValue, ...props }: ComponentProps<typeof TextInput>) {
  return (
    <TextInputMask
      type="custom"
      options={{
        mask: '(999) 999 - 9999',
      }}
      customTextInput={TextInputWrapper}
      customTextInputProps={{
        placeholder: '(555) 555 - 5555',
        inputMode: 'tel',
        autoCompleteType: 'tel',
        ...props,
      }}
      onChangeText={onChangeValue}
      value={value || ''}
    />
  );
}

export const PhoneInputRender: InputRenderer<ComponentProps<typeof PhoneInput>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  const formContext = useFormContext();
  if (formContext && props.label) formContext.labels[name] = props.label as string;
  return (
    <PhoneInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      error={error?.message}
      onChangeValue={(v) => {
        onChange(v);
        onBlur();
      }}
      value={value}
      {...props}
    />
  );
};

export function PhoneFormInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  shouldUnregister,
  ...rest
}: ControllerProps<TFieldValues, TName> & Parameters<typeof PhoneInputRender>[1]) {
  const validate: RegisterOptions<TFieldValues, TName>['validate'] = rest.required
    ? (v) => {
        const valid = z.string().min(1).safeParse(v);
        if (!valid.success) return 'Required';
        return;
      }
    : undefined;
  return (
    <Controller
      rules={{ validate }}
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={(props) => PhoneInputRender(props, rest)}
    />
  );
}

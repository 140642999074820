import * as Sentry from '@sentry/core';
import { useEffect, useMemo, useState } from 'react';

import { Text } from '../components/Text';
import { View } from '../components/View';
import { parseRemoteVTT, VTTObject } from '../lib/vtt';

export function Subtitles(props: { uri: string; positionMillis: number; enabled: boolean }) {
  const [vtt, setVTT] = useState<VTTObject>({ captions: [] });

  useEffect(() => {
    parseRemoteVTT(props.uri)
      .then(setVTT)
      .catch((e) => {
        setVTT({ captions: [{ caption: 'Something went wrong', startMillis: 0, endMillis: 0 }] });
        Sentry.captureException(e);
      });
  }, [props.uri]);

  const currentCaption = useMemo(() => {
    return vtt.captions.find(
      (caption) =>
        caption.startMillis <= props.positionMillis && props.positionMillis < caption.endMillis,
    );
  }, [vtt, props.positionMillis]);

  return props.enabled && currentCaption?.caption ? (
    <View
      pointerEvents="none"
      style={{ backgroundColor: 'rgba(0,0,0,0.6)', borderRadius: 10 }}
      aria-hidden
    >
      <Text text={currentCaption.caption} color="white" textAlign="center" />
    </View>
  ) : null;
}

import { createContext, useContext } from 'react';

export type SideEffect = {} & {
  kind: 'navigate';
  routeName: string;
  params?: object | undefined;
};

export const ChatSideEffectContext = createContext<(effect: SideEffect) => void>(() => {});

export function useChatSideEffect() {
  return useContext(ChatSideEffectContext);
}

// Adapted from:
// https://github.com/brentvatne/signature-example/blob/master/components/SignatureView.js
import { Component, createRef, ReactNode } from 'react';
import {
  GestureResponderEvent,
  PanResponder,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import { captureRef } from '../lib/captureRef';

type Point = { x: number; y: number };
type PathData = { key: string; d: string };
type Props = { style?: StyleProp<ViewStyle>; children?: ReactNode };
type State = {
  reaction: Reaction;
  donePaths: PathData[];
  newPaths: PathData[];
  currentMax: number;
  currentPoints: Array<Point>;
};

export class SignatureView extends Component<Props, State> {
  _panResponder = PanResponder.create({
    onStartShouldSetPanResponder: (evt, gs) => true,
    onMoveShouldSetPanResponder: (evt, gs) => true,
    onPanResponderGrant: (evt) => this.onTouch(evt),
    onPanResponderMove: (evt) => this.onTouch(evt),
    onPanResponderRelease: () => this.onResponderRelease(),
  });
  state: State = {
    currentMax: 0,
    currentPoints: [],
    donePaths: [],
    newPaths: [],
    reaction: new Reaction(),
  };
  viewRef = createRef<View>();

  clear() {
    this.setState({
      currentMax: 0,
      currentPoints: [],
      donePaths: [],
      newPaths: [],
      reaction: new Reaction(),
    });
  }

  async takeSnapshotAsync(options: {
    format: 'jpeg' | 'png';
    quality: number; // quality 0 for very poor 1 for very good
    result: 'file';
  }): Promise<{
    uri: string;
  }> {
    if (this.viewRef.current) {
      const result = await captureRef(this.viewRef.current, {
        format: options.format === 'jpeg' ? 'jpg' : 'png',
        quality: options.quality,
      });
      return { uri: result };
    }
    return { uri: '' };
  }

  onTouch(evt: GestureResponderEvent) {
    let [x, y] = [evt.nativeEvent.locationX, evt.nativeEvent.locationY];
    let newCurrentPoints = [...this.state.currentPoints];
    newCurrentPoints.push({ x, y });

    this.setState({
      donePaths: this.state.donePaths,
      currentPoints: newCurrentPoints,
      currentMax: this.state.currentMax,
    });
  }

  onResponderRelease() {
    let newPaths = this.state.donePaths;
    if (this.state.currentPoints.length > 0) {
      // Cache the shape object so that we aren't testing
      // whether or not it changed; too many components?
      newPaths.push({
        key: this.state.currentMax.toString(),
        d: this.state.reaction.pointsToSvg(this.state.currentPoints),
      });
    }

    this.state.reaction.addGesture(this.state.currentPoints);

    this.setState({
      donePaths: newPaths,
      currentPoints: [],
      currentMax: this.state.currentMax + 1,
    });
  }

  render() {
    return (
      <View style={[styles.drawContainer, this.props.style]}>
        <View
          {...this._panResponder.panHandlers}
          style={{ height: '100%', width: '100%' }}
          ref={this.viewRef}
          collapsable={false}
        >
          <Svg style={styles.drawSurface} width="100%" height="100%">
            <G>
              {this.state.donePaths.map((donePath) => {
                return (
                  <Path
                    key={donePath.key}
                    d={donePath.d}
                    stroke="#000000"
                    strokeWidth={3}
                    fill="none"
                  />
                );
              })}
              <Path
                key={this.state.currentMax}
                d={this.state.reaction.pointsToSvg(this.state.currentPoints)}
                stroke="#000000"
                strokeWidth={3}
                fill="none"
              />
            </G>
          </Svg>

          {this.props.children}
        </View>
      </View>
    );
  }
}

class Reaction {
  gestures: Array<Array<Point>>;
  replayedGestures: Array<Array<Point>>;
  _offsetX = 0;
  _offsetY = 0;

  constructor(gestures: Array<Array<Point>> = []) {
    this.gestures = gestures || [];
    this.replayedGestures = [[]];
  }

  addGesture(points: Point[]) {
    if (points.length > 0) {
      this.gestures.push(points);
    }
  }

  setOffset(options: { x: number; y: number }) {
    this._offsetX = options.x;
    this._offsetY = options.y;
  }

  pointsToSvg(points: Point[]) {
    let offsetX = this._offsetX;
    let offsetY = this._offsetY;

    if (points.length > 0) {
      let path = `M ${points[0].x - offsetX},${points[0].y - offsetY}`;
      points.forEach((point) => {
        path = path + ` L ${point.x - offsetX},${point.y - offsetY}`;
      });
      return path;
    } else {
      return '';
    }
  }

  replayLength() {
    return this.replayedGestures.length;
  }

  empty() {
    return this.gestures.length === 0;
  }

  copy() {
    return new Reaction(this.gestures.slice());
  }

  done() {
    return (
      this.empty() ||
      (this.replayedGestures.length === this.gestures.length &&
        this.lastReplayedGesture().length === this.gestures[this.gestures.length - 1].length)
    );
  }

  lastReplayedGesture() {
    return this.replayedGestures[this.replayedGestures.length - 1];
  }

  stepGestureLength() {
    let gestureIndex = this.replayedGestures.length - 1;
    if (!this.gestures[gestureIndex]) {
      return;
    }
    if (this.replayedGestures[gestureIndex].length >= this.gestures[gestureIndex].length) {
      this.replayedGestures.push([]);
    }
  }

  step() {
    if (this.done()) {
      return true;
    }
    this.stepGestureLength();
    let gestureIndex = this.replayedGestures.length - 1;
    let pointIndex = this.replayedGestures[gestureIndex].length;
    let point = this.gestures[gestureIndex][pointIndex];
    this.replayedGestures[gestureIndex].push(point);
    return false;
  }
}

let styles = StyleSheet.create({
  drawContainer: { flex: 1 },
  drawSurface: {
    backgroundColor: 'transparent',
  },
});

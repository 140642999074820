import { useApolloClient, useQuery } from '@apollo/client';
import { CommonActions as NavigationActions } from '@react-navigation/native';
import * as Sentry from '@sentry/react-native';
// import type { Dsn } from '@sentry/types';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { allowScreenCaptureAsync } from 'expo-screen-capture';
import * as Updates from 'expo-updates';
import invert from 'lodash/invert';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Clipboard, PanResponder, Platform } from 'react-native';
import { captureScreen } from 'react-native-view-shot';
import { z } from 'zod';

import { graphql } from '@oui/lib/src/graphql/tada';
import { ContentType } from '@oui/lib/src/types/graphql.generated';
import { clearFrescoMemoryCache } from '@oui/native-utils';

import AppContext, { useAppContext } from '../components/AppContext';
import { Button } from '../components/Button';
import { Checkbox } from '../components/Checkbox';
import { Modal } from '../components/Modal';
import { PickerInput } from '../components/PickerInput';
import { Text } from '../components/Text';
import { NumberInput, TextInput } from '../components/TextInput';
import { View } from '../components/View';
import { WorksheetListItem } from '../components/WorksheetListItem';
import { APP_SLUG, environment, IS_PRODUCTION, isHermes, manifest } from '../constants';
import { PickerFormInput, useZodForm } from '../form';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { useIsLoggedIn } from '../hooks/useIsLoggedIn';
import { useLogout } from '../hooks/useLogout';
import { usePersistedState } from '../hooks/usePersistedState';
import { checkForUpdateAsync, fetchUpdateAsync, reloadAsync } from '../lib/checkForUpdateAsync';
import { getInstallationIDAsync } from '../lib/getInstallationIDAsync';
import { LOCALES } from '../lib/i18n';
import { mockConversationCompleteV2 } from '../lib/mockUserState';
import { getConfigString } from '../lib/remoteConfig';
import { setDeviceInfo } from '../lib/setDeviceInfo';

// function attachmentUrlFromDsn(dsn: Dsn, eventId: string) {
//   const { host, path, projectId, port, protocol, user } = dsn;
//   return `${protocol}://${host}${port !== '' ? `:${port}` : ''}${
//     path !== '' ? `/${path}` : ''
//   }/api/${projectId}/events/${eventId}/attachments/?sentry_key=${user}&sentry_version=7&sentry_client=custom-javascript`;
// }

const DevMenuQuery = graphql(`
  query DevMenu {
    user {
      ID
      role {
        ID
        sessions {
          session {
            sessionID
            contentType
            name
          }
        }
      }
    }
  }
`);

const IS_SIMPLE_DEV_MENU = APP_SLUG === 'oui-aviva-staff' && IS_PRODUCTION;

function ReportErrorSubscreen(props: { onRequestClose: () => void }) {
  const [error, setError] = useState('');
  const [includeScreenshot, setIncludeScreenshot] = useState(!IS_SIMPLE_DEV_MENU);

  async function uploadScreenshot(id: string) {
    const uri = await captureScreen({ format: 'png' });
    const content = await (await fetch(`file://${uri}`)).blob();
    fetch(
      `https://storage.googleapis.com/upload/storage/v1/b/bug-reports.oui.dev/o?uploadType=media&name=${id}.png`,
      { method: 'POST', body: content },
    ).catch(Sentry.captureException);

    // const formData = new FormData();
    // formData.append('screenshot', content, 'screenshot.png');
    // const dsn = Sentry.getCurrentHub().getClient()?.getDsn();
    // console.log(formData, dsn, dsn ? attachmentUrlFromDsn(dsn, id) : null);
    // if (!dsn) return;
    // fetch(attachmentUrlFromDsn(dsn, id), {
    //   method: 'POST',
    //   body: formData,
    // });
  }

  return (
    <View spacing={24} style={{ height: 400 }}>
      <TextInput
        label="Describe your bug"
        multiline
        value={error}
        onChangeValue={setError}
        placeholder="Example: When I tried clicking on the 'Save' button, the screen turned blank."
      />
      {IS_SIMPLE_DEV_MENU ? null : (
        <Checkbox
          onChangeValue={setIncludeScreenshot}
          value={includeScreenshot}
          horizontal
          label="Include screenshot?"
        />
      )}
      <Button
        text="Submit report"
        onPress={() => {
          Sentry.withScope((scope) => {
            scope.setExtras({ error });
            const id = Sentry.captureException(`DevMenu error report - ${error}`);
            Clipboard.setString(id);
            setError('');
            props.onRequestClose();
            if (Platform.OS !== 'web' && includeScreenshot) {
              setTimeout(() => {
                // id is undefined if sentry is disabled
                void uploadScreenshot(id || Date.now().toString());
              }, 500);
            }
          });
        }}
      />
    </View>
  );
}

function UserSubscreen(props: { onNavigate: (action: NavigationActions.Action) => void }) {
  const { data: currentUser, isLoggedIn } = useCurrentUser();
  const logout = useLogout();
  const userID = currentUser?.currentUser?.user?.ID;
  const apollo = useApolloClient();
  const { data: learnData } = useQuery(DevMenuQuery, { skip: !userID });
  const sessionItems =
    learnData?.user?.role?.sessions?.map((t) => ({
      label: t.session.name,
      value: t.session.contentType as ContentType,
    })) ?? [];

  const form = useZodForm(z.object({ completeThrough: z.string() }), {
    defaultValues: { completeThrough: sessionItems[0]?.value },
  });
  const completeThrough = form.watch('completeThrough');

  return (
    <View spacing={8}>
      <Text text={`Logged in?: ${isLoggedIn ? 'yes' : 'no'}`} />
      {isLoggedIn ? (
        <View spacing={8}>
          <Text text={`ID: ${userID}`} />
          <Text text={`Email: ${currentUser?.currentUser?.user?.person.email}`} />
          <View spacing={8}>
            <View>
              <Text weight="bold" text="Complete sessions through:" />
              <View row spacing={16}>
                <View flex={1}>
                  <PickerFormInput
                    control={form.control}
                    name="completeThrough"
                    items={sessionItems}
                  />
                </View>
                <Button
                  text="Submit"
                  onPress={() => {
                    const promises: Promise<unknown>[] = [];
                    for (let index = 0; index < sessionItems.length; index = index + 1) {
                      const session = sessionItems[index];
                      promises.push(mockConversationCompleteV2(apollo, session.value));
                      if (session.value === completeThrough) break;
                    }
                    return Promise.all(promises);
                  }}
                />
              </View>
            </View>
            <Button text="Logout" onPress={logout} alignSelf="flex-start" />
          </View>
        </View>
      ) : (
        <View spacing={8}>
          <Button
            text="Create test user"
            onPress={() => {
              props.onNavigate(NavigationActions.navigate({ name: 'CreateTestUser' }));
            }}
            alignSelf="flex-start"
          />
        </View>
      )}
    </View>
  );
}

enum Subscreen {
  None = '',
  About = 'about',
  ReportError = 'reportError',
  User = 'user',
  Flags = 'flags',
  Other = 'other',
}

export function DevMenu({
  onNavigate,
  children,
}: {
  onNavigate: (action: NavigationActions.Action) => void;
  children: ReactNode;
}) {
  const [sentryId, setSentryId] = useState('');
  const [installationId, setInstallationId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [subscreen, setSubscreen] = useState<Subscreen>(Subscreen.None);
  const [locale, setLocale] = useState('');
  const [flagOverrides, setFlagOverrides] = useState<Record<string, boolean | number | null>>({});
  const openTimeoutRef = useRef(0);
  const isLoggedIn = useIsLoggedIn();
  const appContext = useAppContext();
  const [disableLocalAuthentication, setDisableLocalAuthentication] = usePersistedState(
    'disableLocalAuthentication',
    false,
  );
  const apollo = useApolloClient();
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);

  async function applyUpdate() {
    try {
      await fetchUpdateAsync();
      await reloadAsync();
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  const openDevMenu = useCallback(() => {
    const id = Sentry.captureMessage('DevMenu opened');
    setSentryId(id);
    checkForUpdateAsync()
      .then(({ isAvailable }) => {
        if (isAvailable) {
          setIsUpdateAvailable(true);
        }
      })
      .catch(Sentry.captureException);
    setShowModal(true);
  }, []);

  useEffect(() => {
    void getInstallationIDAsync().then(setInstallationId);
  }, []);

  const [panResponder] = useState(() =>
    PanResponder.create({
      onMoveShouldSetPanResponder: (_evt, gestureState) => {
        return gestureState.numberActiveTouches === (Device.isDevice ? 3 : 2);
      },
      onPanResponderGrant: (_evt, _gestureState) => {
        openTimeoutRef.current = setTimeout(
          () => {
            openDevMenu();
          },
          IS_PRODUCTION ? 1500 : 500,
        ) as unknown as number;
      },
      onPanResponderRelease: () => {
        clearTimeout(openTimeoutRef.current);
      },
      onPanResponderTerminate: () => {
        clearTimeout(openTimeoutRef.current);
      },
    }),
  );

  useEffect(() => {
    if (Platform.OS === 'web') {
      // eslint-disable-next-line
      (global as any).showDevMenu = openDevMenu;
      // eslint-disable-next-line
      (global as any).showReportErrorMenu = () => {
        openDevMenu();
        setSubscreen(Subscreen.ReportError);
      };
    }
  }, [openDevMenu]);

  const overriddenAppContext = useMemo(() => {
    const flags = { ...appContext.flags, ...flagOverrides };
    return {
      ...appContext,
      flags,
      locale: locale || appContext.locale,
    };
  }, [appContext, flagOverrides, locale]);

  return (
    <View style={{ flex: 1 }} {...panResponder.panHandlers}>
      <AppContext.Provider value={overriddenAppContext}>
        {children}
        <Modal
          visible={showModal}
          onRequestClose={() => {
            setShowModal(false);
            setSubscreen(Subscreen.None);
          }}
          _onBack={
            subscreen && !IS_SIMPLE_DEV_MENU
              ? () => {
                  setSubscreen(Subscreen.None);
                }
              : undefined
          }
          heading={subscreen ? invert(Subscreen)[subscreen] : 'Debug menu'}
          maxHeight={300}
        >
          <View>
            {subscreen === Subscreen.About ? (
              <View spacing={8}>
                <Text text={`Environment: ${environment}`} />
                <Text text={`Latest Version: ${manifest.version}`} />
                <Text text={`Build Version: ${Constants.nativeBuildVersion}`} />
                <Text text={`Sentry ID: ${sentryId}`} />
                <Text text={`Device ID: ${installationId}`} />
                <Text
                  text={`Published at: ${
                    'createdAt' in Updates.manifest ? Updates.manifest.createdAt : 'Build time'
                  }`}
                />
                {Updates.channel ? <Text text={`Release channel: ${Updates.channel}`} /> : null}
                <Text text={`API: ${getConfigString('apiUrl')}`} />
                <Text text={`Hermes: ${isHermes()}`} />
              </View>
            ) : subscreen === Subscreen.ReportError ? (
              <ReportErrorSubscreen
                onRequestClose={() => {
                  setShowModal(false);
                  setSubscreen(Subscreen.None);
                }}
              />
            ) : subscreen === Subscreen.Flags ? (
              <View spacing={8}>
                <Checkbox
                  label="Disable local authentication"
                  value={disableLocalAuthentication}
                  onChangeValue={setDisableLocalAuthentication}
                  horizontal
                />
                <PickerInput
                  label="Locale"
                  items={LOCALES.map((l) => ({ label: l, value: l }))}
                  value={locale}
                  onChangeValue={setLocale}
                />
                {(Object.keys(appContext.flags) as (keyof typeof appContext.flags)[]).map((key) => {
                  const flagType = typeof appContext.flags[key];
                  const value = flagOverrides[key] ?? appContext.flags[key];
                  const onChangeValue = (newValue: boolean | number | null) => {
                    setFlagOverrides({ ...flagOverrides, [key]: newValue });
                  };

                  return (
                    <View row key={key}>
                      {flagType === 'boolean' ? (
                        <Checkbox
                          value={value as boolean}
                          onChangeValue={onChangeValue}
                          label={key}
                          horizontal
                        />
                      ) : flagType === 'number' ? (
                        <NumberInput
                          value={value as number}
                          onChangeValue={onChangeValue}
                          label={key}
                        />
                      ) : null}
                    </View>
                  );
                })}
              </View>
            ) : subscreen === Subscreen.User ? (
              <UserSubscreen onNavigate={onNavigate} />
            ) : subscreen === Subscreen.Other ? (
              <View spacing={24}>
                <Button
                  text="Hope Kit"
                  onPress={() => {
                    onNavigate(
                      NavigationActions.navigate({
                        name: 'HopeKit',
                      }),
                    );
                    setShowModal(false);
                  }}
                />
                <Button
                  text="Activity Diary"
                  onPress={() => {
                    onNavigate(
                      NavigationActions.navigate({
                        name: 'ActivityDiary',
                      }),
                    );
                    setShowModal(false);
                  }}
                />
                <Button
                  text="Thought Diary"
                  onPress={() => {
                    onNavigate(
                      NavigationActions.navigate({
                        name: 'ThoughtDiary',
                      }),
                    );
                    setShowModal(false);
                  }}
                />
                <Button
                  text="Sleep Diary"
                  onPress={() => {
                    onNavigate(
                      NavigationActions.navigate({
                        name: 'SleepDiary',
                      }),
                    );
                    setShowModal(false);
                  }}
                />
                <Button
                  text="Clear Fresco Memory Cache"
                  onPress={() => {
                    clearFrescoMemoryCache();
                  }}
                />
                <Button
                  text="Open gallery"
                  onPress={() => {
                    onNavigate(
                      NavigationActions.navigate({
                        name: 'MediaPicker',
                        params: { returnRoute: 'home' },
                      }),
                    );
                    setShowModal(false);
                  }}
                />
                <Button
                  text="Copy push token"
                  onPress={async () => {
                    await Notifications.getPermissionsAsync();
                    const { data: token } = await Notifications.getExpoPushTokenAsync({
                      projectId: Constants.expoConfig?.extra?.eas.projectId,
                    });
                    Clipboard.setString(token);
                    if (isLoggedIn) {
                      return setDeviceInfo(apollo);
                    }
                    return;
                  }}
                />
                <Button text="Allow screen capture" onPress={allowScreenCaptureAsync} />
                <Button
                  text="JS Error"
                  onPress={() => {
                    try {
                      // eslint-disable-next-line
                      ((global as any).qwer as any)();
                    } catch (e) {
                      Sentry.captureException(e);
                    }
                  }}
                />
                <Button
                  text="Crash"
                  onPress={() => {
                    Sentry.nativeCrash();
                  }}
                />
              </View>
            ) : (
              <View style={{ marginTop: -20 }}>
                {isUpdateAvailable ? (
                  <View row style={{ paddingVertical: 10, justifyContent: 'space-between' }}>
                    <Text text="Update available: " />
                    <Button text="Apply" alignSelf="center" onPress={applyUpdate} />
                  </View>
                ) : null}
                {Object.entries(Subscreen).map(([key, value]) => {
                  return value ? (
                    <View
                      key={key}
                      style={{
                        borderBottomWidth: 1,
                        borderColor: '#eee',
                        marginHorizontal: -20,
                        paddingHorizontal: 20,
                      }}
                    >
                      <WorksheetListItem text={key} onPress={() => setSubscreen(value)} />
                    </View>
                  ) : null;
                })}
              </View>
            )}
          </View>
        </Modal>
      </AppContext.Provider>
    </View>
  );
}

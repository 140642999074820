import { ComponentProps } from 'react';

import { Icon } from './Icon';
import { Text } from './Text';
import { View } from './View';
import { useTheme } from '../styles';

export function Pill({
  color = '#69826F',
  backgroundColor = 'white',
  icon,
  text,
  size = 'small',
  onClose,
}: {
  color?: string;
  backgroundColor?: string;
  icon?: ComponentProps<typeof Icon>['name'];
  text: string;
  size?: 'small' | 'medium';
  onClose?: () => unknown;
}) {
  const { theme } = useTheme();
  return (
    <View
      style={{
        backgroundColor,
        borderRadius: 30,
        paddingHorizontal: 10,
        paddingVertical: 5,
        justifyContent: 'center',
      }}
      row
    >
      {icon ? <Icon size={12} name={icon} color={color} style={{ marginRight: 5 }} /> : null}
      <Text text={text} size={size === 'small' ? 13 : 15} color={color} weight="semibold" />
      {onClose ? (
        <Icon
          size={10}
          name="close"
          onPress={onClose}
          aria-label="Close"
          color={theme.color.gray400}
          style={{ marginLeft: 10 }}
        />
      ) : null}
    </View>
  );
}

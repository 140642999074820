import { useMutation } from '@apollo/client';

import { graphql } from '@oui/lib/src/graphql/tada';

import { AuthScreenContainer } from '../components/AuthScreenContainer';
import { Button } from '../components/Button';
import { ErrorPresenter } from '../components/ErrorPresenter';
import { Text } from '../components/Text';
import { EmailInput } from '../components/TextInput';
import { View } from '../components/View';
import { manifest } from '../constants';
import { useForm } from '../hooks/useForm';
import { useI18n } from '../lib/i18n';
import { logEvent } from '../lib/log';
import { useTheme } from '../styles';
import { AuthScreenProps } from '../types/navigation';

const RequestResetPasswordMutation = graphql(`
  mutation RequestResetPassword($email: String!) {
    issueTokenByEmail(email: $email)
  }
`);

export default function RequestResetPassword(props: AuthScreenProps<'RequestResetPassword'>) {
  const { theme } = useTheme();
  const [requestResetPassword, { data: mutationData }] = useMutation(RequestResetPasswordMutation);
  const { bind, data, validate, humanErrors } = useForm<{ email: string }>({
    email: props.route.params?.email ?? '',
  });
  const { $t } = useI18n();

  const request = async () => {
    if (validate()) {
      logEvent('request_reset_password');
      return requestResetPassword({ variables: { email: data.email } });
    }

    return;
  };

  return (
    <AuthScreenContainer
      heading={$t({ id: 'RequestResetPassword_heading', defaultMessage: 'Forgot your password?' })}
    >
      <View spacing={10}>
        {mutationData?.issueTokenByEmail === true ? (
          <Text
            text={$t({
              id: 'RequestResetPassword_success',
              defaultMessage: 'Please check your email to reset your password.',
            })}
            color={theme.color.success}
          />
        ) : mutationData?.issueTokenByEmail === false ? (
          <ErrorPresenter
            errorString={$t({
              id: 'RequestResetPassword_error',
              defaultMessage:
                "Something went wrong requesting a password reset email. Please make sure you've entered your email address properly.",
            })}
          />
        ) : (
          <Text
            text={$t(
              {
                id: 'RequestResetPassword_description',
                defaultMessage:
                  "Don't worry. Resetting your password is easy. Just tell us the email address you registered with {appName}.",
              },
              { appName: manifest.name },
            )}
            size={16}
            style={{ marginBottom: 20, textAlign: 'center' }}
          />
        )}
        <ErrorPresenter formErrors={humanErrors} />
        <EmailInput
          {...bind('email', {
            label: $t({ id: 'RequestResetPassword_emailLabel', defaultMessage: 'Email' }),
            validator: { type: 'email' },
          })}
          enterKeyHint="done"
          onSubmitEditing={() => void request()}
          testID="RequestResetPassword_email"
        />
        <Button
          onPress={request}
          disabled={!data.email}
          alignSelf="center"
          text={$t({
            id: 'RequestResetPassword_submitButton',
            defaultMessage: 'Request password reset',
          })}
          testID="RequestResetPassword_submit"
        />
      </View>
    </AuthScreenContainer>
  );
}

import { useRoute } from '@react-navigation/native';
import { useState } from 'react';

import { Button } from '@oui/app-core/src/components/Button';
import { RoundedSection } from '@oui/app-core/src/components/RoundedSection';
import { useArtifactRequest } from '@oui/app-core/src/hooks/useArtifactResult';

import { StackScreenProps } from '@src/types';

export function TestWidget() {
  const route = useRoute<StackScreenProps<'TestWidget'>['route']>();
  const [isComplete, setIsComplete] = useState(false);

  useArtifactRequest(route.name, isComplete);

  return (
    <RoundedSection color="#ebf1ed" secondaryColor="white" title="Test" testID="TestWidget">
      <Button
        text="Complete widget"
        testID="TestWidget_completeButton"
        onPress={() => setIsComplete(true)}
      />
    </RoundedSection>
  );
}

import { ComponentProps } from 'react';
import { TouchableOpacity } from 'react-native';

import { parseGQLDateTime } from '@oui/lib/src/gqlDate';

import { Button } from '../components/Button';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { useI18n } from '../lib/i18n';
import { useTheme } from '../styles';

export function WorksheetListItem(props: {
  text: string;
  description?: string;
  completedAt?: string;
  onPress: () => void;
  buttonProps?: Partial<ComponentProps<typeof Button>>;
  testID?: string;
}) {
  const { theme } = useTheme();
  const { formatDate } = useI18n();

  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={{ paddingVertical: 0 }}
      role="menuitem"
      testID={props.testID}
    >
      <View row style={{ justifyContent: 'space-between' }}>
        <View spacing={4} flex={1}>
          <Text text={props.text} size={17} weight="bold" numberOfLines={2} />
          {props.description ? (
            <Text text={props.description} numberOfLines={1} color={theme.color.gray400} />
          ) : null}
          {props.completedAt ? (
            <Text
              text={`Completed ${formatDate(parseGQLDateTime(props.completedAt), {
                month: 'short',
                day: '2-digit',
              })}`}
              size={15}
              color={theme.color.gray300}
            />
          ) : null}
        </View>
        <Button
          alignSelf="center"
          icon="caret-right"
          onPress={props.onPress}
          variant="text"
          aria-label="Go to item"
          {...props.buttonProps}
        />
      </View>
    </TouchableOpacity>
  );
}

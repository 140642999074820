import { useEffect, useRef } from 'react';
import { AppState, AppStateStatus } from 'react-native';

export async function pauseAppState<T>(callback: () => Promise<T>): Promise<T> {
  global.pauseAppState = true;
  const result = await callback();
  global.pauseAppState = false;
  return result;
}

export function useAppState(listener: (nextAppState: AppStateStatus) => void | Promise<void>) {
  const listenerRef = useRef(listener);
  listenerRef.current = listener;
  useEffect(() => {
    const fullListener = (nextAppState: AppStateStatus) => {
      if (global.pauseAppState) return;
      void listenerRef.current(nextAppState);
    };
    const listener = AppState.addEventListener('change', fullListener);
    return () => listener.remove();
  }, []);
}

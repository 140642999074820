import { useEffect } from 'react';
import { Control, FieldPath, FieldValues } from 'react-hook-form';

import states from '@oui/lib/src/metadata/states.json';
import { AddressInputSchema } from '@oui/lib/src/types';

import { Label, Text } from '../../components/Text';
import { View } from '../../components/View';
import { PickerFormInput, TextFormInput, useSubFormContext } from '../../form';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { useTheme } from '../../styles';

/**
 * Allows editing an Address conforming to AddressInputSchema within a react-hook-form
 */
export function AddressFormInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  requiresMailingAddress,
}: {
  name: TName;
  control: Control<TFieldValues>;
  requiresMailingAddress: boolean;
}) {
  const { getName, setValue, watch } = useSubFormContext(AddressInputSchema, control, name);
  const { width } = useWindowDimensions();
  const { theme } = useTheme();

  const addressValue = watch();

  useEffect(() => {
    // ensure rank/use are always specified
    if (!addressValue?.rank) setValue('rank', 0);
    if (!addressValue?.use) setValue('use', 'HOME');
  }, [setValue, addressValue]);

  return (
    <View testID="AddressFormInput" style={{ gap: 10 }}>
      <Label
        style={{ marginLeft: 16 }}
        testID="AddressFormInput_label"
        text={
          <>
            Mailing address
            {requiresMailingAddress ? (
              <Text text=" *" color={theme.color.danger} weight="semibold" />
            ) : null}
          </>
        }
      />
      <TextFormInput
        control={control}
        name={getName('line.0')}
        required={requiresMailingAddress}
        aria-label="Street address"
        placeholder="Street address"
      />
      <TextFormInput
        control={control}
        name={getName('line.1')}
        aria-label="Address Line 2"
        placeholder="Address Line 2"
      />
      <View row style={{ gap: 10, flexWrap: 'wrap' }}>
        <View style={{ flex: 1, flexBasis: width < 600 ? '100%' : undefined }}>
          <TextFormInput
            control={control}
            name={getName('city')}
            required={requiresMailingAddress}
            aria-label="City"
            placeholder="City"
          />
        </View>
        <View style={{ flex: 1 }}>
          <PickerFormInput
            control={control}
            name={getName('state')}
            items={states.map((s) => ({ label: s.name, value: s.abbreviation }))}
            required={requiresMailingAddress}
            aria-label="State"
            placeholder="State"
          />
        </View>
        <View style={{ flex: 1 }}>
          <TextFormInput
            control={control}
            name={getName('postalCode')}
            required={requiresMailingAddress}
            aria-label="ZIP code"
            placeholder="ZIP Code"
          />
        </View>
      </View>
    </View>
  );
}

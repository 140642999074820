import * as FileSystem from 'expo-file-system';
import { useRef } from 'react';
import { Platform } from 'react-native';

import { ChatInputBaseProps, ChatInputSignatureProps, Kind } from '@oui/lib/src/types';

import { Button } from '../components/Button';
import { SignatureView as SignaturePad } from '../components/SignatureView';
import { View } from '../components/View';
import { DETOX_SIGNATURE } from '../constants';

export function ChatInputSignature(props: ChatInputSignatureProps & ChatInputBaseProps) {
  const pad = useRef<SignaturePad>(null);

  return (
    <View
      style={{ height: 200, flexGrow: 1, paddingVertical: 10, paddingHorizontal: 20 }}
      spacing={4}
      testID="ChatInputSignature"
    >
      <View style={{ flex: 1 }}>
        {global.e2e ? null : <SignaturePad ref={pad} style={{ borderWidth: 1 }} />}
        <View
          style={{
            height: 1,
            width: '90%',
            left: '5%',
            backgroundColor: 'black',
            position: 'absolute',
            bottom: 15,
          }}
          pointerEvents="none"
        />
      </View>
      <Button
        aria-label="Clear signature"
        alignSelf="center"
        icon="close"
        variant="text"
        onPress={() => {
          if (pad.current) {
            pad.current.clear();
          }
        }}
        style={{ position: 'absolute', top: 12, right: 25 }}
      />
      <Button
        alignSelf="center"
        text="Sign"
        variant="contained"
        onPress={() => {
          if (global.e2e) {
            const uri = DETOX_SIGNATURE;
            props.onInput({ kind: Kind.InputSignature, props: { uri } });
          } else if (pad.current) {
            return pad.current
              .takeSnapshotAsync({ quality: 0.8, format: 'png', result: 'file' })
              .then((result) => {
                if (Platform.OS === 'web') {
                  return new Promise<void>((res) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(result.uri as any); // eslint-disable-line
                    reader.onloadend = function () {
                      // TODO this reqder.result returns base64 but the resulting image is blank for some reason :/
                      const uri = (reader.result as string) || '';
                      props.onInput({ kind: Kind.InputSignature, props: { uri } });
                      res();
                    };
                  });
                } else {
                  return FileSystem.readAsStringAsync(result.uri, {
                    encoding: FileSystem.EncodingType.Base64,
                  }).then((base64) => {
                    const uri = `data:image/png;base64,${base64}`;
                    props.onInput({ kind: Kind.InputSignature, props: { uri } });
                  });
                }
              });
          }

          return;
        }}
        testID="ChatInputSignature_submit"
      />
    </View>
  );
}

ChatInputSignature.defaultProps = {
  onInput: () => {},
  disabled: false,
};

export default ChatInputSignature;

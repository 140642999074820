import { ComponentProps } from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import { Icon } from '../components/Icon';
import { Small, Text } from '../components/Text';
import { View } from '../components/View';
import { useTheme } from '../styles';

function TabHeader({
  text,
  icon,
  count,
  active,
  style,
  onPress,
  testID,
  disabled,
}: {
  text: string;
  icon?: ComponentProps<typeof Icon>['name'];
  count?: number;
  active: boolean;
  style?: StyleProp<ViewStyle>;
  onPress: () => void;
  testID?: string;
  disabled?: boolean;
}) {
  const { theme } = useTheme();
  const color = active ? theme.color.primary100 : theme.color.gray300;
  return (
    <TouchableOpacity
      disabled={disabled}
      style={[style, { alignItems: 'center' }]}
      onPress={onPress}
      testID={testID}
      role="tab"
      aria-selected={active}
      aria-disabled={disabled}
    >
      <View
        row
        style={[
          {
            borderColor: 'transparent',
            borderBottomWidth: 2,
            paddingBottom: 10,
            justifyContent: 'flex-end',
            opacity: disabled ? 0.5 : undefined,
          },
          active ? { borderColor: theme.color.primary100 } : null,
        ]}
        spacing={12}
      >
        {icon ? <Icon name={icon} color={color} /> : null}
        <Text text={text} weight="semibold" color={color} size={15} />
        {typeof count === 'number' ? (
          <View
            style={{
              borderRadius: 30,
              backgroundColor: active ? '#F7EEEF' : theme.color.gray700,
              paddingVertical: 1,
              paddingHorizontal: 10,
            }}
          >
            <Small color={color} text={count.toString()} />
          </View>
        ) : null}
      </View>
    </TouchableOpacity>
  );
}

export type DiaryTabsProps<T extends string> = {
  value: T;
  onChangeValue: (newValue: T) => void;
  style?: StyleProp<ViewStyle>;
  topOfRoundedSection?: boolean;
  itemStyle?: StyleProp<ViewStyle>;
  items: Array<{
    icon?: ComponentProps<typeof Icon>['name'];
    text: string;
    value: T;
    disabled?: boolean;
    count?: number;
  }>;
  testID?: string;
};

export function DiaryTabs<T extends string>({
  value: activeTab,
  onChangeValue: setActiveTab,
  style,
  items,
  itemStyle,
  testID,
  topOfRoundedSection,
}: DiaryTabsProps<T>) {
  const { theme } = useTheme();
  return (
    <View
      testID={testID}
      row
      style={[
        {
          borderBottomWidth: 1,
          borderColor: theme.color.gray600,
        },
        topOfRoundedSection
          ? {
              borderTopStartRadius: 30,
              borderTopEndRadius: 30,
              paddingTop: 10,
              marginTop: -15,
              backgroundColor: 'white',
              marginHorizontal: -20,
              marginBottom: 20,
            }
          : null,
        style,
      ]}
    >
      {items.map((item) => (
        <TabHeader
          key={item.value}
          text={item.text}
          icon={item.icon}
          count={item.count}
          active={activeTab === item.value}
          onPress={() => setActiveTab(item.value)}
          testID={testID ? `${testID}_${item.value}` : undefined}
          disabled={item.disabled}
          style={[{ flex: 1 }, itemStyle]}
        />
      ))}
    </View>
  );
}

import { ScrollView } from 'react-native';

import { Button } from '@oui/app-core/src/components/Button';
import { Heading } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import useWindowDimensions from '@oui/app-core/src/hooks/useWindowDimensions';
import { useI18n } from '@oui/app-core/src/lib/i18n';

import StaticSessionCoverBg from '../assets/Static_session_cover.svg';

export const StaticSessionCover = (props: {
  title: string;
  onNext: () => void;
  onBack: () => void;
}) => {
  const { $t } = useI18n();
  const { screen } = useWindowDimensions();

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: '#202E53',
      }}
    >
      <StaticSessionCoverBg
        aria-label={undefined}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingHorizontal: 40,
          paddingVertical: 20,
          justifyContent: 'space-between',
          flex: 1,
        }}
        testID={`StaticSessionCover`}
      >
        <View
          spacing={16}
          style={{
            alignItems: 'center',
            marginTop: screen.height * 0.25,
          }}
        >
          <Heading
            testID="StaticSessionCover_title"
            text={props.title}
            textAlign="center"
            level={1}
            variant="light"
          />
          <View
            style={{
              width: 51,
              height: 2,
              backgroundColor: '#BED3C3',
            }}
          ></View>
        </View>

        <View row style={{ marginTop: 20, justifyContent: 'space-between' }}>
          <Button
            testID="StaticSessionCover_backButton"
            text={$t({ id: 'StaticSessionCover_back', defaultMessage: 'Back' })}
            variant="text"
            color="#FFF"
            onPress={props.onBack}
          />
          <Button
            testID="StaticSessionCover_nextButton"
            text={$t({ id: 'StaticSessionCover_next', defaultMessage: 'Next' })}
            onPress={props.onNext}
          />
        </View>
      </ScrollView>
    </View>
  );
};

import { Platform } from 'react-native';
import SystemSetting from 'react-native-system-setting';

export async function getVolume() {
  if (Platform.OS !== 'web') {
    return SystemSetting.getVolume();
  }

  return 1;
}

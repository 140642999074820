/* eslint-disable @typescript-eslint/no-explicit-any */
import { getFocusedRouteNameFromRoute, Route } from '@react-navigation/native';

import { useAppContext } from '../components/AppContext';

export function useActiveRoute<T extends Route<string, object | undefined>>(): T {
  const { navigationContainer } = useAppContext();
  const route = (navigationContainer?.getCurrentRoute() ?? {
    name: '',
    params: undefined,
  }) as Route<string>;
  const focusedRouteName = getFocusedRouteNameFromRoute(route) ?? route.name;
  if (route.name !== focusedRouteName) {
    return { name: (route.params as any).screen, params: (route.params as any).params } as any;
  }
  return route as any;
}

import {
  BottomTabNavigationOptions,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import { defineMessages } from 'react-intl';
import { TouchableWithoutFeedback, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Text } from '@oui/app-core/src/components/Text';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';

import { Icon } from '@src/components/Icon';
import CRP from '@src/screens/CRP';
import { Home } from '@src/screens/Home';
import { Learn } from '@src/screens/Learn';
import { Practice } from '@src/screens/Practice';
import { UserProfile } from '@src/screens/UserProfile';
import { TabParamList } from '@src/types';

function TabLabel(props: { focused: boolean; color: string; text: string }) {
  return (
    <View
      style={{
        alignItems: 'center',
        paddingVertical: 2,
        marginTop: -4,
        width: '100%',
      }}
    >
      <Text
        text={props.text}
        color={props.color}
        size={13}
        weight="semibold"
        numberOfLines={1}
        textAlign="center"
      />
      <View
        style={{
          backgroundColor: props.focused ? props.color : 'transparent',
          height: 2,
          width: '70%',
        }}
      ></View>
    </View>
  );
}

const tabLabels = defineMessages({
  home: { id: 'Tabs_homeTab', defaultMessage: 'Home' },
  learn: { id: 'Tabs_learnTab', defaultMessage: 'Learn' },
  practice: { id: 'Tabs_practiceTab', defaultMessage: 'Practice' },
  myPlan: { id: 'Tabs_myPlanTab', defaultMessage: 'MyPlan' },
  profile: { id: 'Tabs_profileTab', defaultMessage: 'Profile' },
});

const Tab = createBottomTabNavigator<TabParamList>();
export const TabNavigator = () => {
  const insets = useSafeAreaInsets();
  const { theme } = useTheme();
  const { $t } = useI18n();

  const tabAccessibilityLabel = (values: { tabName: string; count: number }) => {
    return $t(
      { id: 'Tabs_tabAccessibilityLabel', defaultMessage: '{tabName}, {count} of {total}' },
      { ...values, total: 5 },
    ) as string;
  };

  return (
    <Tab.Navigator
      screenOptions={({ route }) => {
        const options: BottomTabNavigationOptions = {
          headerShown: false,
          tabBarStyle: { height: 60 + insets.bottom, backgroundColor: theme.color.primary100 },
          tabBarActiveTintColor: 'white',
          tabBarInactiveTintColor: 'rgba(255,255,255,0.6)',
          // Adapted from https://github.com/react-navigation/react-navigation/blob/a484c1d7834f195ac0cfccda6a9f905218bc2274/packages/bottom-tabs/src/views/BottomTabItem.tsx#L156-L164
          // customized accessibilityRole
          tabBarButton: ({ children, style, onPress, to, accessibilityRole, ...rest }) => (
            <TouchableWithoutFeedback {...rest} role="tab" onPress={onPress}>
              <View style={style}>{children}</View>
            </TouchableWithoutFeedback>
          ),
          tabBarIcon: ({ color: tintColor }) => {
            const routeName = route.name;

            let icon = null;
            if (routeName === 'Home') {
              icon = <Icon color={tintColor} name="home" />;
            } else if (routeName === 'Aid') {
              icon = <Icon color={tintColor} name="my-plan" />;
            } else if (routeName === 'Learn') {
              icon = <Icon color={tintColor} name="sessions" />;
            } else if (routeName === 'Practice') {
              icon = <Icon color={tintColor} name="practice" />;
            } else if (routeName === 'Profile') {
              icon = <Icon color={tintColor} name="person" />;
            }

            return <View style={{ paddingTop: 4 }}>{icon}</View>;
          },
        };
        return options;
      }}
      initialRouteName="Home"
    >
      <Tab.Screen
        name="Home"
        component={Home}
        options={{
          tabBarLabel: (p) => <TabLabel {...p} text={$t(tabLabels.home)} />,
          tabBarTestID: 'NAV_Home',
          tabBarAccessibilityLabel: tabAccessibilityLabel({
            tabName: $t(tabLabels.home),
            count: 1,
          }),
        }}
      />
      <Tab.Screen
        name="Learn"
        component={Learn}
        options={{
          title: $t(tabLabels.learn),
          tabBarLabel: (p) => <TabLabel {...p} text={$t(tabLabels.learn)} />,
          tabBarTestID: 'NAV_Learn',
          tabBarAccessibilityLabel: tabAccessibilityLabel({
            tabName: $t(tabLabels.learn),
            count: 2,
          }),
        }}
      />
      <Tab.Screen
        name="Practice"
        component={Practice}
        options={{
          title: $t(tabLabels.practice),
          tabBarLabel: (p) => <TabLabel {...p} text={$t(tabLabels.practice)} />,
          tabBarTestID: 'NAV_Practice',
          tabBarAccessibilityLabel: tabAccessibilityLabel({
            tabName: $t(tabLabels.practice),
            count: 3,
          }),
        }}
      />
      <Tab.Screen
        name="Aid"
        component={CRP}
        options={{
          title: $t(tabLabels.myPlan),
          tabBarLabel: (p) => <TabLabel {...p} text={$t(tabLabels.myPlan)} />,
          tabBarTestID: 'NAV_Aid',
          tabBarAccessibilityLabel: tabAccessibilityLabel({
            tabName: $t(tabLabels.myPlan),
            count: 4,
          }),
        }}
      />
      <Tab.Screen
        name="Profile"
        component={UserProfile}
        options={{
          title: $t(tabLabels.profile),
          tabBarLabel: (p) => <TabLabel {...p} text={$t(tabLabels.profile)} />,
          tabBarTestID: 'NAV_Profile',
          tabBarAccessibilityLabel: tabAccessibilityLabel({
            tabName: $t(tabLabels.profile),
            count: 5,
          }),
        }}
      />
    </Tab.Navigator>
  );
};

'use client';

import { createContext, useContext, useEffect, useState } from 'react';

import { LogoutContext } from '../hooks/useLogout';
import { getIsLoggedIn, isInitialized, onAuthStateChanged } from '../lib/auth';

// Ideally this wouldn't be necessary to support RSC but we don't currently have
// a better way of initializing auth.ts in that context due to the singleton nature
// of the module and the shared nature of RSC rendering. Using a react context ensures
// we don't accidentally share token state across requests
export const InitialTokenContext = createContext<string | null>('');

export function useIsLoggedIn() {
  const token = useContext(InitialTokenContext);
  const { isLoggingOut } = useContext(LogoutContext);
  const [isLoggedIn, setIsLoggedIn] = useState(isInitialized() ? getIsLoggedIn() : !!token);
  useEffect(() => {
    const unsub = onAuthStateChanged((loggedIn) => {
      setIsLoggedIn(loggedIn);
    });
    return () => {
      unsub();
    };
  }, []);
  return isLoggedIn && !isLoggingOut;
}

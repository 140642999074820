import { useEffect, useRef } from 'react';

import { Button } from '../components/Button';
import { FlippableCard, InternalCardProps } from '../components/CardStack';
import { Icon } from '../components/Icon';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { useI18n } from '../lib/i18n';
import { useTheme } from '../styles';

export function ReviewCompleteCard({
  doneText,
  onActive,
  onDone,
  onReset,
  resetText,
  text,
  ...rest
}: {
  doneText?: string;
  onActive?: () => void;
  onDone: () => void;
  onReset: () => void;
  resetText?: string;
  text?: string;
}) {
  const internalProps = rest as InternalCardProps;
  const { theme } = useTheme();
  const onActiveRef = useRef(onActive);
  onActiveRef.current = onActive;
  const { $t } = useI18n();

  const isActive = internalProps.index === internalProps.animatedIndex.value;
  useEffect(() => {
    if (isActive) {
      onActiveRef.current?.();
    }
  }, [isActive]);

  return (
    <FlippableCard
      {...internalProps}
      // Needs to be a FlippableCard for proper stacking,
      // but we disable flipping by setting backChildren to null
      backChildren={null}
      frontChildren={
        <View style={{ paddingHorizontal: 20 }} testID="ReviewCompleteCard">
          <View
            style={{
              backgroundColor: '#ebf1ed',
              borderRadius: 35,
              height: 50,
              width: 50,
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'center',
            }}
            aria-hidden
          >
            <Icon name="check" color={theme.color.success} aria-label={undefined} size={24} />
          </View>
          <Text
            text={
              text ??
              $t({
                id: 'ReviewCompleteCard_complete',
                defaultMessage: 'Review complete',
              })
            }
            size={21}
            weight="semibold"
            style={{ marginTop: 12, marginBottom: 40 }}
            textAlign="center"
          />
          <Button
            _useGestureHandler
            onPress={onDone}
            text={
              doneText ??
              $t({
                id: 'ReviewCompleteCard_doneButton',
                defaultMessage: 'Done',
              })
            }
            variant="solid"
            style={{ marginBottom: 20 }}
            alignSelf="stretch"
            testID="ReviewCompleteCard_doneButton"
          />
          <Button
            _useGestureHandler
            onPress={onReset}
            text={
              resetText ??
              $t({
                id: 'ReviewCompleteCard_resetButton',
                defaultMessage: 'Reset cards',
              })
            }
            alignSelf="stretch"
            testID="ReviewCompleteCard_resetButton"
          />
        </View>
      }
    />
  );
}

import { HeaderBackButton } from '@react-navigation/elements';
import { useNavigation } from '@react-navigation/native';
import equals from 'fast-deep-equal';
import { produce } from 'immer';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Button } from '@oui/app-core/src/components/Button';
import { ConfirmationModal } from '@oui/app-core/src/components/ConfirmationModal';
import { HeaderButtons } from '@oui/app-core/src/components/HeaderButtons';
import { ListItemTextInput } from '@oui/app-core/src/components/ListItemTextInput';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Label, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useMyStoryMyPlanCompositionSections } from '@oui/app-core/src/hooks/useComposition';
import { card, useTheme } from '@oui/app-core/src/styles';
import type { CrisisTimeline } from '@oui/lib/src/types/avro';

import { SoloOnboardingStepBadge } from '@src/components/SoloOnboarding';
import { TextInput } from '@src/components/TextInput';
import { StackScreenProps } from '@src/types';

function EditCard(props: {
  step: 'A' | 'B' | 'C';
  description: string;
  title: string;
  children?: ReactNode;
}) {
  return (
    <View style={[card, { paddingVertical: 20, paddingHorizontal: 28 }]} spacing={20}>
      <View row spacing={10}>
        <SoloOnboardingStepBadge step={props.step} size={25} />
        <Label text={props.title} />
      </View>
      <Text text={props.description} size={15} />
      {props.children}
    </View>
  );
}

export function EditSuicideMode(props: StackScreenProps<'EditSuicideMode'>) {
  const navigation = useNavigation<StackScreenProps<'EditSuicideMode'>['navigation']>();

  const { theme } = useTheme();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { data, update } = useMyStoryMyPlanCompositionSections();
  const [localData, setLocalData] = useState(data);

  useEffect(() => {
    if (!localData && data) {
      setLocalData(data);
    }
  }, [localData, data]);

  async function save() {
    if (localData) {
      await update({
        RISK_FACTORS: localData.RISK_FACTORS,
        CRISIS_TIMELINE: localData.CRISIS_TIMELINE,
      });
      navigation.goBack();
    }
  }

  const saveRef = useRef(save);
  saveRef.current = save;

  function isDirty() {
    return !equals(localData, data);
  }

  const isDirtyRef = useRef(isDirty);
  isDirtyRef.current = isDirty;

  useEffect(() => {
    navigation.setOptions({
      headerLeft: ({ tintColor }) => {
        return (
          <HeaderBackButton
            tintColor={tintColor}
            onPress={() => {
              if (isDirtyRef.current()) {
                setShowConfirmationModal(true);
              } else {
                navigation.goBack();
              }
            }}
          />
        );
      },
      headerRight: () => {
        return (
          <HeaderButtons>
            <Button
              text="Save"
              onPress={() => saveRef.current()}
              testID="EditSuicideMode_saveButton"
            />
          </HeaderButtons>
        );
      },
    });
  }, [navigation]);

  if (!localData?.CRISIS_TIMELINE) return null;

  function getCrisisTimelineSectionInputProps(
    section: NonNullable<CrisisTimeline['timeline'][number]['suicideModeSection']>,
  ): {
    testID: string;
    value: NonNullable<typeof localData>['CRISIS_TIMELINE']['timeline'];
    onChangeValue: (
      newValue: NonNullable<typeof localData>['CRISIS_TIMELINE']['timeline'],
    ) => unknown;
  } {
    if (!localData?.CRISIS_TIMELINE) return null!;
    return {
      value:
        localData!.CRISIS_TIMELINE.timeline.filter((i) => i.suicideModeSection === section) ?? [],
      onChangeValue: (newValue) => {
        const newLocalData = produce(localData, (draft) => {
          draft.CRISIS_TIMELINE.timeline = [
            ...draft.CRISIS_TIMELINE.timeline.filter((i) => i.suicideModeSection !== section),
            ...newValue.map((i) => ({ ...i, suicideModeSection: section, isWarningSign: false })),
          ];
        });
        setLocalData(newLocalData);
      },
      testID: `EditSuicideMode_${section}Input`,
    };
  }

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1, padding: 20, paddingBottom: 200 }}
      testID="EditSuicideMode"
    >
      <View spacing={30}>
        <EditCard
          step="A"
          title="Baseline"
          description="Baseline risk factors are things about you or things that have happened to you that increase your likelihood of becoming suicidal."
        >
          <ListItemTextInput
            label="Baseline risk factors"
            bulletColor={theme.color.primary100}
            value={localData.RISK_FACTORS ?? []}
            onChangeValue={(newValue) => {
              setLocalData({ ...localData, RISK_FACTORS: newValue });
            }}
            testID="EditSuicideMode_riskFactorsInput"
          />
        </EditCard>
        <EditCard
          step="B"
          title="Events"
          description="Events are stressful situations or problems that led up to your recent suicide crisis."
        >
          <ListItemTextInput
            label="Events"
            bulletColor={theme.color.primary100}
            {...getCrisisTimelineSectionInputProps('beforeEvents')}
          />
        </EditCard>
        <EditCard
          step="C"
          title="Crisis"
          description="Feelings, thoughts, behaviors, and physical sensations were you experienced in the days and hours leading up peak of your crisis."
        >
          <View spacing={25}>
            <ListItemTextInput
              label="Feelings"
              bulletColor={theme.color.primary100}
              {...getCrisisTimelineSectionInputProps('feelings')}
            />
            <ListItemTextInput
              label="Thoughts"
              bulletColor={theme.color.primary100}
              {...getCrisisTimelineSectionInputProps('thoughts')}
            />
            <ListItemTextInput
              label="Behaviors"
              bulletColor={theme.color.primary100}
              {...getCrisisTimelineSectionInputProps('behaviors')}
            />
            <ListItemTextInput
              label="Sensations"
              bulletColor={theme.color.primary100}
              {...getCrisisTimelineSectionInputProps('physiological')}
            />
            <TextInput
              label="Suicide crisis"
              multiline
              inputStyle={{ fontFamily: 'OpenSansBold' }}
              value={
                localData.CRISIS_TIMELINE.timeline.find(
                  (i) => i.ID === localData.CRISIS_TIMELINE.crisisPeakID,
                )?.text ?? ''
              }
              onChangeValue={(newText) => {
                const newLocalData = produce(localData, (draft) => {
                  const item = draft.CRISIS_TIMELINE.timeline.find(
                    (i) => i.ID === draft.CRISIS_TIMELINE.crisisPeakID,
                  );
                  if (item) {
                    item.text = newText;
                  } else {
                    const newID = uuid();
                    draft.CRISIS_TIMELINE.crisisPeakID = newID;
                    draft.CRISIS_TIMELINE.timeline.push({
                      ID: newID,
                      text: newText,
                      isWarningSign: false,
                    });
                  }
                });
                setLocalData(newLocalData);
              }}
            />
          </View>
        </EditCard>
      </View>
      <ConfirmationModal
        onCancel={() => {
          setShowConfirmationModal(false);
          navigation.goBack();
        }}
        onConfirm={async () => {
          await save();
          setShowConfirmationModal(false);
        }}
        visible={showConfirmationModal}
        confirmText="Save"
        cancelText="Discard"
        title="Save changes?"
        description="You've made edits to your suicide mode. Would you like to save them?"
      />
    </ScrollView>
  );
}

import Url from 'url-parse';

export function classifyAsset(uri: string) {
  const { pathname: _pathname, query } = new Url(uri, {}, { parser: true });
  const pathname = _pathname.toLowerCase();
  const isVideo =
    pathname.endsWith('.mov') ||
    pathname.endsWith('.mp4') ||
    pathname.endsWith('.mov') ||
    query.contentType?.includes('video');
  const isAudio = pathname.endsWith('.mp3') || query.contentType?.includes('audio');
  const isImage =
    uri.startsWith('data:image') ||
    pathname.startsWith('data:image') ||
    pathname.endsWith('.jpeg') ||
    pathname.endsWith('.jpg') ||
    pathname.endsWith('.heic') ||
    pathname.endsWith('.png') ||
    query.contentType?.includes('image');

  return {
    isVideo,
    isAudio,
    isImage,
  };
}

import { ReactNode, useRef } from 'react';
import { RefreshControl, ScrollView, StyleSheet } from 'react-native';
import ViewShot from 'react-native-view-shot';

import { BezierCurve } from '@oui/app-core/src/components/BezierCurve';
import { TabHeader } from '@oui/app-core/src/components/TabHeader';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { card, useTheme } from '@oui/app-core/src/styles';

export function StaticMyPlanWrapper({ myPlan, label }: { myPlan: ReactNode; label: string }) {
  const { theme } = useTheme();
  const viewShotRef = useRef<ViewShot>(null);
  const { $t } = useI18n();

  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={{ paddingBottom: 20 }}
      refreshControl={
        <RefreshControl
          refreshing={false}
          onRefresh={async () => {}}
          title={$t({ id: 'MyPlan_refresh', defaultMessage: 'Update' })}
        />
      }
      testID="CRP"
    >
      <TabHeader heading={$t({ id: 'StaticMyPlan_heading', defaultMessage: 'MyPlan' })} />
      <View style={{ zIndex: 1, marginTop: 10 }}>
        <View style={StyleSheet.absoluteFillObject}>
          <BezierCurve
            viewBoxWidth={100}
            viewBoxHeight={70}
            startPoint={[0, 30]}
            firstControlPoint={[30, 70]}
            secondControlPoint={[70, 70]}
            endPoint={[100, 60]}
            fill="#9f5aa2"
          />
        </View>
        <ViewShot ref={viewShotRef}>
          <View
            style={[
              card,
              {
                alignSelf: 'stretch',
                backgroundColor: myPlan === null ? theme.color.gray800 : 'white',
                borderRadius: 20,
                marginHorizontal: 20,
                marginTop: 20,
                padding: 20,
              },
            ]}
            spacing={4}
          >
            <View
              style={{
                paddingHorizontal: 20,
                paddingBottom: 10,
                marginTop: -10,
                marginBottom: 10,
                borderBottomWidth: 1,
                borderColor: theme.color.gray600,
                marginHorizontal: -20,
              }}
            >
              <Text text={label} color={theme.color.gray300} weight="semibold" role="heading" />
            </View>
            {myPlan}
          </View>
        </ViewShot>
      </View>
    </ScrollView>
  );
}

import { Platform } from 'react-native';

import {
  Actor,
  ChatEnvelope,
  Envelopes,
  InputEnvelope,
  Kind,
  SideEffectEnvelope,
} from '@oui/lib/src/types';

export function isChatKind(kind: Kind): boolean {
  return kind < 100 && kind !== Kind.NULL;
}

export function isInputKind(kind: Kind): boolean {
  return kind >= 100 && kind < 200;
}

export function isSideEffectKind(kind: Kind): boolean {
  return kind >= 400 && kind < 500;
}

export function isChatEnvelope(envelope: Envelopes): envelope is ChatEnvelope {
  return isChatKind(envelope.kind);
}

export function isInputEnvelope(envelope: Envelopes): envelope is InputEnvelope {
  return isInputKind(envelope.kind);
}

export function isSideEffectEnvelope(envelope: Envelopes): envelope is SideEffectEnvelope {
  return isSideEffectKind(envelope.kind);
}

export function getEnvelopeDuration(envelope: Envelopes, isScreenReaderEnabled: boolean): number {
  if (Platform.OS === 'web' && global.e2e) return 500;
  if ((envelope as any).history) return 0;
  if (envelope.from === Actor.User) return 750;
  if (envelope.kind === Kind.ChatText) {
    const text = envelope.props.text.join('');
    return Math.min(Math.ceil(text.length / 25) * 750, 5000) * (isScreenReaderEnabled ? 2.5 : 1);
  } else if (isChatKind(envelope.kind)) {
    return 750;
  }
  return 0;
}

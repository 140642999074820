import { ComponentProps, ReactNode } from 'react';
import { RefreshControl, ScrollView, StyleSheet } from 'react-native';

import { BezierCurve } from '@oui/app-core/src/components/BezierCurve';
import { HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { TabHeader } from '@oui/app-core/src/components/TabHeader';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';

import { MyPlan } from '../components/MyPlan';

export function MyPlanTabContainer({
  onEdit,
  loading,
  refetch,
  data,
  headerNode,
  heading,
  children,
}: {
  onEdit: (() => void) | null;
  refetch: () => void;
  headerNode?: ReactNode;
  heading?: string;
  children: React.ReactNode;
} & Pick<ComponentProps<typeof MyPlan>, 'loading' | 'data'>) {
  const { theme } = useTheme();
  const { $t } = useI18n();

  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={{ paddingBottom: 20 }}
      refreshControl={
        <RefreshControl
          refreshing={loading}
          onRefresh={() => void refetch()}
          title={$t({ id: 'MyPlan_refresh', defaultMessage: 'Update' })}
        />
      }
      testID="CRP"
    >
      <TabHeader
        heading={heading ?? $t({ id: 'MyPlan_heading', defaultMessage: 'MyPlan' })}
        headerRight={
          data && onEdit ? (
            <HeaderItem
              aria-label={$t({ id: 'MyPlan_editButton', defaultMessage: 'Edit MyPlan' })}
              iconName="edit"
              title=""
              onPress={() => onEdit?.()}
              testID="CRP_editButton"
              color={theme.color.gray400}
            />
          ) : undefined
        }
      />
      <View style={{ zIndex: 1, marginTop: 10 }}>
        <View style={StyleSheet.absoluteFillObject}>
          <BezierCurve
            viewBoxWidth={100}
            viewBoxHeight={70}
            startPoint={[0, 30]}
            firstControlPoint={[30, 70]}
            secondControlPoint={[70, 70]}
            endPoint={[100, 60]}
            fill="#5f277e"
          />
        </View>
        {headerNode ? <View style={{ marginVertical: -6 }}>{headerNode}</View> : null}
        {children}
      </View>
    </ScrollView>
  );
}

export function MyPlanTab({
  lockedNode,
  highlightedSections,
  ...props
}: {
  onEdit: (() => void) | null;
  refetch: () => void | Promise<unknown>;
  headerNode?: ReactNode;
  heading?: string;
} & Pick<
  ComponentProps<typeof MyPlan>,
  'loading' | 'data' | 'lockedNode' | 'highlightedSections'
>) {
  return (
    <MyPlanTabContainer {...props}>
      <MyPlan
        loading={props.loading}
        data={props.data}
        cardUI
        lockedNode={lockedNode}
        highlightedSections={highlightedSections}
      />
    </MyPlanTabContainer>
  );
}

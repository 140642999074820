import 'core-js/features/promise';
import 'core-js/features/object';
import 'core-js/features/array';
import 'cross-fetch/polyfill';

// @ts-expect-error
import Modal from 'modal-react-native-web';

import '../global.css';
// WebDatePickerInner
import 'react-datepicker/dist/react-datepicker.css';
// WebTooltip
import '@reach/tooltip/styles.css';

// NB #root may not be available in storybook-web
if (global.document.querySelector('#root')) {
  Modal.setAppElement('#root');
}

const isOldBrowser = typeof Number.isNaN !== 'function';
if (isOldBrowser) {
  // @ts-expect-error
  import('../global.oldbrowser.css');
}

export default isOldBrowser
  ? Promise.all([import('core-js'), import('../lib/intlPolyfill')])
  : Promise.resolve();

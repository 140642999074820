import type { RouteProp } from '@react-navigation/core';
import type { StackNavigationProp } from '@react-navigation/stack';
import type { AssetInfo } from 'expo-media-library';

import type {
  DeeplinkConfigShape,
  NavigatorScreenParams,
} from '@oui/lib/src/generateReactNavigationTypedRoutes';
import type { InputArtifactResult } from '@oui/lib/src/types/actionEnvelopes';

import type { ArtifactScreen } from '../hooks/useArtifactResult';

export type MediaPickerResult =
  | {
      permission: 'denied';
      assets?: never;
    }
  | { permission: 'granted'; assets?: AssetInfo[] };

export { RouteProp };
export { NavigatorScreenParams, DeeplinkConfigShape };

export type BooleanParam = 'true' | 'false';
export type NumberParam = string & { _number: true };

export type QuizSetParams = { slug: string; title?: string };
export type ConversationParams = {
  _artifactResult?: InputArtifactResult;
  _mediaPickerResult?: MediaPickerResult;
  title?: string;
  ID: string;
  completed?: BooleanParam;
  num?: string;
};

export type AuthParamList = {
  Login: { resetPassword?: BooleanParam };
  RequestResetPassword: { email?: string };
  ResetPassword: { token?: string };
  SignUp: {
    email?: string | null;
    signupTokenFromActivationCode: string;
  };
};

export type CoreRootStackParamList = {
  TestArtifactResult: {
    artifactName: ArtifactScreen;
    // JSON.parse to get params object back
    params: string;
    _artifactResult?: InputArtifactResult;
  };
  QuizSet: QuizSetParams;
  Conversation: ConversationParams;
  LocalAuthenticationPrompt: undefined;
  FinishPatientRegistration: undefined;
  ContactsPicker: { returnRoute: string };
  PlacesPicker: { returnRoute: string; label?: string };
  TermsAndPrivacy: undefined;
  MediaPicker: {
    returnRoute: 'Conversation';
  };
  EditLessonsLearned: undefined;
  AccountSettings: { isEditing: BooleanParam };
} & AuthParamList;

export const CORE_DEEPLINK_CONFIG = {
  ContactsPicker: 'contacts/choose',
  Conversation: 'conversation/:ID',
  EditLessonsLearned: 'lessons-learned/edit',
  FinishPatientRegistration: 'finish-patient-registration',
  LocalAuthenticationPrompt: 'local-authentication-prompt',
  Login: 'auth/login',
  MediaPicker: 'media/choose',
  PlacesPicker: 'places/choose',
  QuizSet: 'quiz-set/:slug',
  RequestResetPassword: 'auth/requestreset',
  ResetPassword: 'auth/reset',
  SignUp: 'auth/signup',
  TermsAndPrivacy: 'terms-and-privacy',
  TestArtifactResult: 'TestArtifactResult', // value needs to match key since this config isn't interpolated in each package for testing
  AccountSettings: 'account',
} as const satisfies DeeplinkConfigShape<keyof CoreRootStackParamList>;

export type AuthScreenProps<T extends keyof AuthParamList, Other extends object = {}> = {
  navigation: StackNavigationProp<AuthParamList, T>;
  route: RouteProp<AuthParamList, T>;
} & Other;

export type StackScreenRouteProp<T extends keyof CoreRootStackParamList> = RouteProp<
  CoreRootStackParamList,
  T
>;

export type StackScreenProps<T extends keyof CoreRootStackParamList, Other extends object = {}> = {
  navigation: StackNavigationProp<CoreRootStackParamList, T>;
  route: StackScreenRouteProp<T>;
} & Other;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RootParamList extends CoreRootStackParamList {}
  }
}

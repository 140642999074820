import { useNavigation } from '@react-navigation/native';

import { ChatInputBaseProps, ChatInputContactsPickerProps, Kind } from '@oui/lib/src/types';

import ChatInputChoice from '../components/ChatInputChoice';
import { View } from '../components/View';
import { useContactsPickerResult } from '../hooks/useContactsPickerResult';
import { StackScreenProps } from '../types/navigation';

export function ChatInputContactsPicker(props: ChatInputContactsPickerProps & ChatInputBaseProps) {
  const { navigate } = useNavigation<StackScreenProps<'Conversation'>['navigation']>();

  const label: { [key: number]: string } = {
    0: 'Add from contacts',
    1: 'Add new',
  };
  if (props.allowSkip) {
    label[2] = 'Not now';
  }

  const returnRoute = useContactsPickerResult(({ contacts, permissionDenied }) => {
    if (permissionDenied) {
      props.onInput({
        kind: Kind.InputContactsPicker,
        props: { result: 'custom', contacts: [] },
      });
    } else {
      props.onInput({
        kind: Kind.InputContactsPicker,
        props: { result: 'contacts', contacts },
      });
    }
  });

  return (
    <View>
      <ChatInputChoice
        onInput={(action) => {
          if (action.kind === Kind.InputChoice) {
            const choice = action.props[0];
            if (choice === 0) {
              // Open contacts chooser
              navigate('ContactsPicker', { returnRoute });
            } else if (choice === 1) {
              // continue conversation
              props.onInput({
                kind: Kind.InputContactsPicker,
                props: { result: 'custom', contacts: [] },
              });
            } else if (choice === 2) {
              props.onInput({
                kind: Kind.InputContactsPicker,
                props: { result: 'skip', contacts: [] },
              });
            }
          }
        }}
        label={label}
      />
    </View>
  );
}

ChatInputContactsPicker.defaultProps = {
  onInput: () => {},
  disabled: false,
};

export default ChatInputContactsPicker;

import { NavigationAction, useNavigation } from '@react-navigation/native';
import { ComponentProps, useEffect, useRef, useState } from 'react';

import { ConfirmationModal } from './ConfirmationModal';

export function UnsavedChangesModal({
  cancelText = 'Discard',
  confirmText = 'Yes',
  hasUnsavedChanges,
  ...props
}: {
  cancelText?: string;
  confirmText?: string;
  hasUnsavedChanges: boolean;
  onCancel?: (payload: { continueNavigation: Function }) => void;
  onConfirm: (payload: { continueNavigation: Function }) => void | unknown | Promise<unknown>;
} & Partial<Omit<ComponentProps<typeof ConfirmationModal>, 'onCancel' | 'onConfirm' | 'visible'>>) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { addListener, dispatch } = useNavigation();
  const eventRef = useRef<NavigationAction>();

  useEffect(() => {
    return addListener('beforeRemove', (e) => {
      eventRef.current = undefined;

      if (hasUnsavedChanges && !showConfirmationModal) {
        e.preventDefault();
        eventRef.current = e.data.action;
        setShowConfirmationModal(true);
      }
    });
  }, [hasUnsavedChanges, addListener, showConfirmationModal]);

  function continueNavigation() {
    setShowConfirmationModal(false);
    if (eventRef.current) {
      dispatch(eventRef.current);
    }
  }

  return (
    <>
      <ConfirmationModal
        confirmText={confirmText}
        cancelText={cancelText}
        title="Save changes?"
        description="Your unsaved changes are about to be lost. Would you like to save before leaving?"
        {...props}
        onCancel={() => {
          if (props.onCancel) {
            props.onCancel({ continueNavigation });
          } else {
            continueNavigation();
          }
        }}
        onConfirm={() => props.onConfirm({ continueNavigation })}
        visible={showConfirmationModal}
      />
    </>
  );
}

import { RouteProp, useNavigation, useNavigationState, useRoute } from '@react-navigation/native';
import { useEffect } from 'react';

import { ChatInputArtifactProps } from '@oui/lib/src/types';
import { InputArtifactResult } from '@oui/lib/src/types/actionEnvelopes';

import { setPersistedState, usePersistedState } from '../hooks/usePersistedState';
import { ConversationParams } from '../types/navigation';

export type ArtifactScreen = ChatInputArtifactProps['artifactName'];

type Payload = InputArtifactResult;
export function useArtifactResult(callback: (payload: Payload) => void) {
  const { setParams } = useNavigation();
  const { name, params } =
    useRoute<RouteProp<{ Conversation: ConversationParams }, 'Conversation'>>();

  useEffect(() => {
    if (params?._artifactResult) {
      const artifactResult: Payload = params._artifactResult;
      setParams({ _artifactResult: undefined });
      callback(artifactResult);
    }
  }, [setParams, params?._artifactResult, callback]);

  return name;
}

export function useConversationRoute() {
  return useNavigationState((state) =>
    state?.routes.find((r) => r.name === 'Conversation' || r.name === 'TestArtifactResult'),
  )! as RouteProp<{ Conversation: ConversationParams }, 'Conversation'>;
}

export function startArtifactRequest(artifactScreen: ArtifactScreen) {
  return setPersistedState('artifactRequest', artifactScreen);
}

export function useArtifactRequest(artifactScreen: ArtifactScreen, isComplete: boolean) {
  const [artifactRequest] = usePersistedState('artifactRequest', null);
  const navigation = useNavigation();
  const conversationRoute = useConversationRoute();
  const hasRequest = artifactRequest === artifactScreen && conversationRoute;

  useEffect(() => {
    if (hasRequest) {
      navigation.setOptions({
        headerBackTitleVisible: false,
      });

      const remove = navigation.addListener('beforeRemove', (e) => {
        e.preventDefault();
        remove();
        setPersistedState('artifactRequest', null);
        navigation.navigate(conversationRoute?.name, {
          ...conversationRoute?.params,
          _artifactResult: { complete: isComplete },
        });
      });
      return remove;
    }
    return;
  }, [navigation, conversationRoute, isComplete, hasRequest]);
}

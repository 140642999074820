import * as Sentry from '@sentry/core';
import { useEffect, useState } from 'react';

import { Modal } from '../components/Modal';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { getVTTTranscript } from '../lib/vtt';

export function TranscriptModal(props: {
  heading?: string;
  uri: string;
  onRequestClose: () => void;
}) {
  const [transcript, setTranscript] = useState<string[]>([]);
  useEffect(() => {
    getVTTTranscript(props.uri)
      .then(setTranscript)
      .catch((e) => {
        setTranscript(['Something went wrong']);
        Sentry.captureException(e);
      });
  }, [props.uri]);
  return (
    <Modal
      visible
      heading={props.heading ?? 'Transcript'}
      onRequestClose={props.onRequestClose}
      maxHeight={500}
    >
      <View spacing={16}>
        {transcript.map((str, i) => (
          <Text text={str} key={i.toString()} />
        ))}
      </View>
    </Modal>
  );
}

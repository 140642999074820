import { Component } from 'react';

import { ChatInputBaseProps, ChatInputChoiceProps } from '@oui/lib/src/types';

import ChatInputMultipleChoice from '../components/ChatInputMultipleChoice';
import ChatInputSingleChoice from '../components/ChatInputSingleChoice';

export default class ChatInputChoice extends Component<ChatInputBaseProps & ChatInputChoiceProps> {
  static defaultProps = {
    min: 1,
    max: 1,
    label: {},
  };

  render() {
    return this.props.min === 1 && this.props.max === 1 ? (
      <ChatInputSingleChoice {...this.props} legacyChoice={true} />
    ) : (
      <ChatInputMultipleChoice {...this.props} legacyChoice={true} />
    );
  }
}

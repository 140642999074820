import type { NavigationAction } from '@react-navigation/core';
import { useLinkProps } from '@react-navigation/native';
import { stringify } from 'query-string';
import { MouseEvent as ReactMouseEvent, ReactNode } from 'react';
import { GestureResponderEvent, Pressable, StyleProp, ViewStyle } from 'react-native';

import { Text } from '../components/Text';
import { useTheme } from '../styles';

function getToFromParams(
  to: string,
  params?: Record<string, string | number | boolean | null | undefined>,
) {
  const paramsCopy = { ...params };
  const substitutedTo = to.replace(/:\w+/g, (token) => {
    const lookup = token.slice(1);
    const paramValue = paramsCopy[lookup];
    delete paramsCopy[lookup];
    return paramValue?.toString() ?? '';
  });

  return Object.keys(paramsCopy).length
    ? `/${substitutedTo}?${stringify(paramsCopy)}`
    : `/${substitutedTo}`;
}

export const LinkButton = ({
  to,
  action,
  params,
  children,
  disabled,
  style,
  size,
  hoverUnderline,
  ...rest
}: {
  action?: NavigationAction;
  to: string;
  params?: Record<string, string | number | boolean | null | undefined>;
  children: ReactNode;
  disabled?: boolean;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  size?: number;
  testID?: string;
  hoverUnderline?: boolean;
}) => {
  const { theme } = useTheme();
  const finalTo = getToFromParams(to, params);
  const props = useLinkProps({ to: finalTo, action });

  const onPress = (e: ReactMouseEvent<HTMLAnchorElement, MouseEvent> | GestureResponderEvent) => {
    if ('onPress' in rest) {
      rest.onPress?.();
    }

    props.onPress(e);
  };

  if (disabled) delete (props as any).href;
  return (
    <Pressable
      {...props}
      {...rest}
      onPress={disabled ? undefined : onPress}
      style={[disabled ? { opacity: 0.6 } : null, style]}
      // @ts-expect-error
      dataSet={{
        'link-button--text': hoverUnderline ? '' : undefined,
        // @ts-expect-error
        ...rest.dataSet,
      }}
    >
      {typeof children === 'string' ? (
        <Text color={theme.color.gray400} text={children} size={size} />
      ) : (
        children
      )}
    </Pressable>
  );
};

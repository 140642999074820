'use client';

import hexToRgba from 'hex-to-rgba';
import { ReactNode } from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  Modal as RNModal,
  StatusBar,
  StyleProp,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import { gestureHandlerRootHOC } from 'react-native-gesture-handler';

import { useTheme } from '../styles';

type Props = {
  onRequestClose: () => void;
  visible: boolean;
  children: ReactNode;
  style: StyleProp<ViewStyle>;
  testID?: string;
};

const ModalInner = gestureHandlerRootHOC((props: Props) => {
  const { theme } = useTheme();
  return (
    <TouchableOpacity
      testID={props.testID}
      accessible={false}
      activeOpacity={1}
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: hexToRgba(theme.color.gray100, '0.5'),
      }}
      onPress={props.onRequestClose}
    >
      {props.visible ? (
        <StatusBar
          backgroundColor={hexToRgba(theme.color.gray100, '0.5')}
          barStyle="light-content"
          translucent
        />
      ) : null}
      <TouchableOpacity accessible={false} activeOpacity={1} style={props.style}>
        {props.children}
      </TouchableOpacity>
    </TouchableOpacity>
  );
});

export function ModalContainer(props: Props) {
  return (
    <RNModal visible={props.visible} transparent onRequestClose={() => {}}>
      {Platform.OS === 'android' ? (
        <ModalInner {...props} />
      ) : (
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          behavior="padding"
          onAccessibilityEscape={props.onRequestClose}
        >
          <ModalInner {...props} />
        </KeyboardAvoidingView>
      )}
    </RNModal>
  );
}

import * as SecureStore from 'expo-secure-store';

import { Button } from '../components/Button';
import { Modal } from '../components/Modal';
import { TextInput } from '../components/TextInput';
import { View } from '../components/View';
import { useForm } from '../hooks/useForm';

export function SetPINModal(props: { onRequestClose: () => void; onSetPIN: () => void }) {
  const { validate, data, bind } = useForm({ pin: '', confirmation: '' });
  return (
    <Modal heading="Choose a PIN" visible={true} onRequestClose={props.onRequestClose}>
      <View spacing={12}>
        <TextInput
          {...bind('pin', { validator: { type: 'length', minimum: 4, maximum: 4 }, label: 'PIN' })}
          inputMode="numeric"
          secureTextEntry
        />
        <TextInput
          {...bind('confirmation', {
            label: 'Confirm PIN',
            validator: [
              { type: 'length', minimum: 4, maximum: 4 },
              () => {
                if (data.pin !== data.confirmation) return 'Confirmation does not match';
                return undefined;
              },
            ],
          })}
          inputMode="numeric"
          secureTextEntry
        />
        <Button
          text="Set PIN"
          onPress={async () => {
            if (validate()) {
              await SecureStore.setItemAsync('reauthPIN', data.pin, {
                keychainAccessible: SecureStore.WHEN_PASSCODE_SET_THIS_DEVICE_ONLY,
              });
              props.onSetPIN();
            }
          }}
        />
      </View>
    </Modal>
  );
}

// This file is automatically generated. See gqlcodegen.yml for details
// prettier-ignore
// @ts-ignore
import * as CustomScalars from "@oui/lib/src/types/scalars";
import * as Types from '@oui/lib/src/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientSupportersQueryVariables = Types.Exact<{
  patientID: Types.Scalars['UUID']['input'];
}>;


export type PatientSupportersQuery = { patientByPatientID: { __typename: 'PatientProfile', patient: { __typename: 'Patient', ID: CustomScalars.GQLUUID }, supporters: Array<{ __typename: 'PatientSupporter', supporterID: CustomScalars.GQLUUID, patientID: CustomScalars.GQLUUID, claimed: boolean | null, relation: Types.PatientSupporterRelation, reviewed: boolean | null, supporter: { __typename: 'Patient', ID: CustomScalars.GQLUUID, person: { givenName: string | null, familyName: string | null } } }> } | null };

export type ReviewPatientSupporterMutationVariables = Types.Exact<{
  patientSupporterID: Types.Scalars['UUID']['input'];
}>;


export type ReviewPatientSupporterMutation = { reviewPatientSupporter: { __typename: 'PatientSupporter', supporterID: CustomScalars.GQLUUID, patientID: CustomScalars.GQLUUID, reviewed: boolean | null } };


export const PatientSupportersDocument = gql`
    query PatientSupporters($patientID: UUID!) {
  patientByPatientID(patientID: $patientID) {
    __typename
    patient {
      __typename
      ID
    }
    supporters {
      __typename
      supporterID
      patientID
      supporter {
        __typename
        ID
        person {
          givenName
          familyName
        }
      }
      claimed
      relation
      reviewed
    }
  }
}
    `;

/**
 * __usePatientSupportersQuery__
 *
 * To run a query within a React component, call `usePatientSupportersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientSupportersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientSupportersQuery({
 *   variables: {
 *      patientID: // value for 'patientID'
 *   },
 * });
 */
export function usePatientSupportersQuery(baseOptions: Apollo.QueryHookOptions<PatientSupportersQuery, PatientSupportersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientSupportersQuery, PatientSupportersQueryVariables>(PatientSupportersDocument, options);
      }
export function usePatientSupportersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientSupportersQuery, PatientSupportersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientSupportersQuery, PatientSupportersQueryVariables>(PatientSupportersDocument, options);
        }
export type PatientSupportersQueryHookResult = ReturnType<typeof usePatientSupportersQuery>;
export type PatientSupportersLazyQueryHookResult = ReturnType<typeof usePatientSupportersLazyQuery>;

export function queryPatientSupporters(client: Apollo.ApolloClient<unknown>, variables: PatientSupportersQueryVariables, options?: Omit<Apollo.QueryOptions<PatientSupportersQuery, PatientSupportersQueryVariables>, "query" | 'variables'>) {
  return client.query<PatientSupportersQuery, PatientSupportersQueryVariables>({
    query: PatientSupportersDocument,
    variables,
    ...options,
  });
}

export const ReviewPatientSupporterDocument = gql`
    mutation ReviewPatientSupporter($patientSupporterID: UUID!) {
  reviewPatientSupporter(patientSupporterID: $patientSupporterID) {
    __typename
    supporterID
    patientID
    reviewed
  }
}
    `;

/**
 * __useReviewPatientSupporterMutation__
 *
 * To run a mutation, you first call `useReviewPatientSupporterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewPatientSupporterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewPatientSupporterMutation, { data, loading, error }] = useReviewPatientSupporterMutation({
 *   variables: {
 *      patientSupporterID: // value for 'patientSupporterID'
 *   },
 * });
 */
export function useReviewPatientSupporterMutation(baseOptions?: Apollo.MutationHookOptions<ReviewPatientSupporterMutation, ReviewPatientSupporterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReviewPatientSupporterMutation, ReviewPatientSupporterMutationVariables>(ReviewPatientSupporterDocument, options);
      }
export type ReviewPatientSupporterMutationHookResult = ReturnType<typeof useReviewPatientSupporterMutation>;

export function performReviewPatientSupporter(client: Apollo.ApolloClient<unknown>, variables: ReviewPatientSupporterMutationVariables, options?: Omit<Apollo.MutationOptions<ReviewPatientSupporterMutation, ReviewPatientSupporterMutationVariables>, "mutation" | 'variables'>) {
  return client.mutate<ReviewPatientSupporterMutation, ReviewPatientSupporterMutationVariables>({
    mutation: ReviewPatientSupporterDocument,
    variables,
    ...options,
  });
}

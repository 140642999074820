import { ReactNode } from 'react';
import { DimensionValue, Keyboard, ScrollView } from 'react-native';

import { Button } from '../components/Button';
import { ModalContainer } from '../components/ModalContainer';
import { OldHeading } from '../components/Text';
import { View } from '../components/View';
import { useTheme } from '../styles';

export function MandatoryModal(props: { children?: ReactNode; visible: boolean }) {
  const { Shadow } = useTheme();
  return (
    <ModalContainer
      visible={props.visible}
      onRequestClose={() => {}}
      style={[
        {
          width: '90%',
          maxWidth: 400,
          backgroundColor: 'white',
          marginHorizontal: 10,
          borderRadius: 20,
          paddingVertical: 26,
          paddingHorizontal: 12,
        },
        Shadow.high,
      ]}
    >
      {props.children}
    </ModalContainer>
  );
}

export function ModalContainerFullWidth(props: { children?: ReactNode }) {
  return <View style={{ marginHorizontal: -20 }}>{props.children}</View>;
}

export function Modal({
  ...props
}: {
  _onBack?: () => void;
  buttonText?: string;
  children: ReactNode;
  heading?: string;
  maxHeight?: number | string; // string only available on web
  maxWidth?: number | string; // string only available on web
  onPressButton?: () => void;
  onRequestClose: () => void;
  visible: boolean;
}) {
  const { theme } = useTheme();

  return (
    <ModalContainer
      {...props}
      style={{
        width: '90%',
        maxWidth: (props.maxWidth as DimensionValue) ?? 400,
        backgroundColor: 'white',
        marginHorizontal: 10,
      }}
    >
      <View
        row
        style={{
          justifyContent: 'space-between',
          paddingVertical: 12,
          paddingHorizontal: 20,
          borderBottomWidth: 1,
          borderColor: theme.color.gray600,
        }}
      >
        {props._onBack ? (
          <Button
            icon="arrow-left"
            variant="text"
            onPress={props._onBack}
            aria-label="Go back"
            style={{ marginRight: 8 }}
          />
        ) : null}
        {props.heading ? <OldHeading text={props.heading} style={{ marginBottom: 0 }} /> : null}
        <Button
          icon="close"
          variant="text"
          onPress={props.onRequestClose}
          aria-label="Close modal"
        />
      </View>
      {props.maxHeight ? (
        <ScrollView style={{ maxHeight: props.maxHeight as DimensionValue }}>
          <View
            style={{ padding: 20, zIndex: 1 }}
            onStartShouldSetResponder={() => {
              Keyboard.dismiss();
              return true;
            }}
          >
            {props.children}
          </View>
        </ScrollView>
      ) : (
        <View
          style={{ padding: 20, zIndex: 1 }}
          onStartShouldSetResponder={() => {
            Keyboard.dismiss();
            return true;
          }}
        >
          {props.children}
        </View>
      )}
      {props.buttonText && props.onPressButton ? (
        <View
          row
          style={{
            justifyContent: 'center',
            paddingVertical: 12,
            paddingHorizontal: 20,
            borderTopWidth: 1,
            borderColor: theme.color.gray600,
          }}
        >
          <Button text={props.buttonText} onPress={props.onPressButton} />
        </View>
      ) : null}
    </ModalContainer>
  );
}

import { useRoute } from '@react-navigation/native';

import { useConversationContext } from './ConversationContext';
import { Heading, Label } from './Text';
import { View } from './View';
import { StackScreenRouteProp } from '../types/navigation';

export function ChatHeader() {
  const route = useRoute<StackScreenRouteProp<'Conversation'>>();
  const title = route.params?.title ?? '';
  const sessionNum = route.params?.num ?? null;
  const { accentColor } = useConversationContext();
  return (
    <View style={{ paddingTop: 80 }}>
      <Heading level={1} text={title} textAlign="center" />
      {sessionNum !== null ? (
        <View style={{ marginTop: 10 }}>
          <Label text={`Session ${sessionNum}`} textAlign="center" />
          <View
            style={{
              backgroundColor: accentColor,
              marginLeft: -25,
              height: 2,
              width: 50,
              marginTop: 7,
              marginBottom: 10,
              alignSelf: 'center',
            }}
          />
        </View>
      ) : null}
    </View>
  );
}

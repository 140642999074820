import AsyncStorage from '@react-native-async-storage/async-storage';
import addHours from 'date-fns/addHours';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import { formatGQLDate } from '@oui/lib/src/gqlDate';

type PersistedState<T> = {
  date: string;
  value: T;
};

const getDateStringWithOffset = (offsetInHours: number) => {
  const date = new Date();
  const offset = addHours(date, offsetInHours);
  return formatGQLDate(offset);
};

export function useDailyState<S>(
  key: string,
  initialState: S | (() => S),
): [S, Dispatch<SetStateAction<S>>] {
  const [value, setValue] = useState<S>(initialState);
  // We keep valueRef around because setValueWithPersistence needs to be able
  // to pass the most recent value if it's passed an updater function which
  // may be cached in a closure somewhere else
  const valueRef = useRef(value);
  const storageKey = `useDailyState:${key}`;

  useEffect(() => {
    const date = getDateStringWithOffset(0);
    void AsyncStorage.getItem(storageKey).then(async (persistedValue) => {
      if (persistedValue) {
        const decoded: PersistedState<S> = JSON.parse(persistedValue);
        if (decoded.date === date) {
          setValue(decoded.value);
          valueRef.current = decoded.value;
        } else {
          await AsyncStorage.removeItem(storageKey);
        }
      }
    });
  }, [storageKey]);

  const setValueWithPersistence: Dispatch<SetStateAction<S>> = useCallback(
    (newValueOrFunc) => {
      const newValue: S =
        typeof newValueOrFunc === 'function'
          ? (newValueOrFunc as unknown as (prevState: S) => S)(valueRef.current)
          : newValueOrFunc;

      const date = getDateStringWithOffset(0);
      void AsyncStorage.setItem(
        storageKey,
        JSON.stringify({
          date,
          value: newValue,
        }),
      );
      setValue(newValue);
      valueRef.current = newValue;
    },
    [storageKey],
  );

  return [value, setValueWithPersistence];
}

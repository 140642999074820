import ReactDatePicker from 'react-datepicker';

import { Button } from '../components/Button';
import { View } from '../components/View';

export function WebDatePickerInner(props: {
  date: Date;
  minuteInterval: number;
  mode: 'date' | 'datetime' | 'time';
  onCancel: () => void;
  onConfirm?: () => void;
  onDateChange: (date: Date) => void;
  minimumDate?: Date;
  maximumDate?: Date;
}) {
  return (
    <View style={{ alignItems: 'center' }} testID="DateTimePicker">
      <ReactDatePicker
        fixedHeight
        inline
        onChange={props.onDateChange}
        selected={props.date}
        showTimeSelect={props.mode === 'datetime' || props.mode === 'time'}
        showTimeSelectOnly={props.mode === 'time'}
        timeIntervals={props.minuteInterval}
        showMonthDropdown={true}
        showYearDropdown={true}
        dropdownMode="select"
        minDate={props.mode === 'time' ? undefined : props.minimumDate}
        maxDate={props.mode === 'time' ? undefined : props.maximumDate}
        minTime={props.mode === 'time' ? props.minimumDate : undefined}
        maxTime={props.mode === 'time' ? props.maximumDate : undefined}
      />
      {global.e2e ? (
        <Button
          // Not used in V2 e2e tests
          text="Confirm"
          onPress={() => props.onDateChange(new Date())}
        />
      ) : null}
    </View>
  );
}

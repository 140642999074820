import { useMutation } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import * as Sentry from '@sentry/core';
import batchPromises from 'batch-promises';
import * as FileSystem from 'expo-file-system';
import * as ImageManipulator from 'expo-image-manipulator';
import * as MediaLibrary from 'expo-media-library';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { graphql } from '@oui/lib/src/graphql/tada';
import { ChatInputBaseProps, ChatInputMediaPickerProps, Kind } from '@oui/lib/src/types';
import { UploadType } from '@oui/lib/src/types/graphql.generated';

import { ActivityIndicator } from '../components/ActivityIndicator';
import ChatInputChoice from '../components/ChatInputChoice';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { SessionUri } from '../lib/resumableUpload';
import { resumableUploadManager } from '../lib/resumableUploadManager';
import { StackScreenProps } from '../types/navigation';

const ChatInputMediaPickerUploadMutation = graphql(`
  mutation ChatInputMediaPickerUpload($input: ResumableAssetCreateInput!) {
    uploadResumableAsset(input: $input) {
      resumableUploadAssetURI
    }
  }
`);

export function ChatInputMediaPicker(props: ChatInputMediaPickerProps & ChatInputBaseProps) {
  const { setParams, navigate } = useNavigation<StackScreenProps<'Conversation'>['navigation']>();
  const { name: currentRouteName, params } = useRoute<StackScreenProps<'Conversation'>['route']>();
  const [uploadsRemaining, setUploadsRemaining] = useState(0);
  const [retryCount, setRetryCount] = useState(0);
  const [allowSkip, setAllowSkip] = useState(props.allowSkip ?? false);
  const [uploadAsset] = useMutation(ChatInputMediaPickerUploadMutation);

  const label: { [key: number]: string } = {
    0: 'Add from gallery',
  };
  if (allowSkip) {
    label[1] = 'Not now';
  }

  const processAsset = useCallback(
    async ({ filename, mediaType: type, localUri, uri, width, height }: MediaLibrary.AssetInfo) => {
      const ID = uuid();
      const filenameParts = filename.split('.');
      const suffix = filenameParts[filenameParts.length - 1];

      const lowerCaseSuffix = suffix.toLowerCase();
      const finalUri = await (['heic', 'jpeg', 'jpg', 'png'].includes(lowerCaseSuffix)
        ? ImageManipulator.manipulateAsync(uri, [], {
            format:
              lowerCaseSuffix === 'png'
                ? ImageManipulator.SaveFormat.PNG
                : ImageManipulator.SaveFormat.JPEG,
            compress: 0.7,
          }).then((data) => data.uri)
        : localUri ?? uri);

      // For debugging
      // https://github.com/ouihealth/oui/issues/660
      const fileInfo = await FileSystem.getInfoAsync(finalUri ?? uri);
      const start = Date.now();
      const finalSuffix = finalUri.split('.').reverse()[0];
      const finalFilename = filename.replace(`.${suffix}`, `.${finalSuffix}`);
      const breadcrumbData = {
        fileInfo: JSON.stringify(fileInfo),
        filename,
        finalFilename,
        finalSuffix,
        finalUri,
        height,
        localUri,
        type,
        uri,
        width,
      };
      Sentry.addBreadcrumb({
        message: 'ChatInputMediaPicker uploading asset',
        data: breadcrumbData,
      });

      return uploadAsset({
        variables: {
          input: {
            context: props.context,
            key: `${props.keyPrefix}_${ID}`,
            uploadType: type === MediaLibrary.MediaType.video ? UploadType.VIDEO : UploadType.IMAGE,
            fileName: finalFilename,
          },
        },
      })
        .then(async (assetResult) => {
          const id = await resumableUploadManager.uploadFile(
            finalUri,
            assetResult.data!.uploadResumableAsset.resumableUploadAssetURI as SessionUri,
          );
          return new Promise<void>((resolve) => {
            const remove = resumableUploadManager.addListener(id, ({ percent }) => {
              if (percent === 100) {
                resolve();
                remove();
              }
            });
          });
        })
        .catch((e) => {
          Sentry.withScope((scope) => {
            scope.setExtras({
              duration: Date.now() - start,
              filename,
              type,
              localUri,
              uri,
            });
            Sentry.captureException(e);
          });
        })
        .then(() => {
          Sentry.addBreadcrumb({
            message: 'ChatInputMediaPicker upload complete',
            data: {
              duration: Date.now() - start,
              ...breadcrumbData,
            },
          });
          setUploadsRemaining((i) => i - 1);
        });
    },
    [props.context, props.keyPrefix, uploadAsset],
  );

  const onInput = props.onInput;
  useEffect(() => {
    const result = params?._mediaPickerResult;
    if (result) {
      setParams({ _mediaPickerResult: undefined });
      if (result.assets) {
        const assets = result.assets;
        if (!assets.length) {
          setAllowSkip(true);
          setRetryCount((c) => c + 1);
          return;
        }
        setUploadsRemaining(assets.length);
        batchPromises(3, assets, processAsset)
          .then(() => {
            onInput({
              kind: Kind.InputMediaPicker,
              props: { result: 'gallery' },
            });
          })
          .catch((e) => Sentry.captureException(e));
      } else if (result.permission === 'denied') {
        onInput({
          kind: Kind.InputMediaPicker,
          props: { result: 'denied' },
        });
      }
    }
  }, [onInput, processAsset, params._mediaPickerResult, setParams]);

  return (
    <View flex={uploadsRemaining ? 1 : 0}>
      {uploadsRemaining ? (
        <View row style={{ justifyContent: 'center', flex: 1 }} spacing={8}>
          <ActivityIndicator />
          <Text text={`Uploading (${uploadsRemaining} remaining)...`} />
        </View>
      ) : (
        <ChatInputChoice
          key={retryCount.toString()}
          onInput={(action) => {
            if (action.kind === Kind.InputChoice) {
              const choice = action.props[0];
              if (choice === 0) {
                navigate('MediaPicker', {
                  returnRoute: currentRouteName,
                });
              } else if (choice === 1) {
                props.onInput({
                  kind: Kind.InputMediaPicker,
                  props: { result: 'skip' },
                });
              }
            }
          }}
          label={label}
        />
      )}
    </View>
  );
}

ChatInputMediaPicker.defaultProps = {
  onInput: () => {},
  disabled: false,
};

export default ChatInputMediaPicker;

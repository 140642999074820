import { useCurrentPatient, useProgressByContent } from '@oui/app-core/src/hooks/useCurrentUser';
import { ProductVariant } from '@oui/lib/src/types';

export function useIsEnvironmentSafetyLocked() {
  const supportedVariants: string[] = [
    ProductVariant.AVIVA_MILITARY,
    ProductVariant.AVIVA_INPATIENT,
  ];
  const { user } = useCurrentPatient();
  const { data: progress } = useProgressByContent();
  const completion = progress.ENVIRONMENT_SAFETY?.completion ?? 0;
  const isSupportedVariant =
    user?.role.product.slug && supportedVariants.includes(user.role.product.slug);
  return isSupportedVariant && completion === 0;
}

import { ChatInputBaseProps, ChatInputButtonProps, Kind } from '@oui/lib/src/types';

import ChatInputSingleChoice from '../components/ChatInputSingleChoice';

export default function ChatInputButton(props: ChatInputBaseProps & ChatInputButtonProps) {
  return (
    <ChatInputSingleChoice
      label={{ '0': props.label }}
      onInput={() => props.onInput({ kind: Kind.InputButton, props: {} })}
      variant="button"
    />
  );
}

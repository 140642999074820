import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/core';
import { ComponentProps, useCallback, useState } from 'react';
import { Image } from 'react-native';
import { v4 } from 'uuid';

import { graphql } from '@oui/lib/src/graphql/tada';
import { HealthOui } from '@oui/lib/src/types/avro/socialDistractions';

import { Text } from '../components/Text';
import { TypeaheadInput } from '../components/TypeaheadInput';
import { View } from '../components/View';
import { useTheme } from '../styles';

type Place = HealthOui.SocialDistractionPlace;

const PlacesTypeaheadAutocompleteQuery = graphql(`
  query PlacesTypeaheadAutocomplete($query: String!, $sessionToken: String!, $latlng: LatLngInput) {
    placeAutocomplete(latlng: $latlng, query: $query, sessionToken: $sessionToken) {
      description
      structuredFormatting {
        mainText
        secondaryText
      }
      placeId
    }
  }
`);

const PlacesTypeaheadDetailsQuery = graphql(`
  query PlacesTypeaheadDetails($placeId: String!, $sessionToken: String!) {
    placeDetails(placeId: $placeId, sessionToken: $sessionToken) {
      formattedAddress
      geometry {
        location {
          lat
          lng
        }
      }
      name
      placeId
    }
  }
`);

export function PlacesTypeahead({
  attribution = true,
  onSelect,
  location,
  ...props
}: {
  attribution?: boolean;
  onSelect: (place: Place) => void;
  testID?: string;
  location?: { lat: number; lng: number };
} & Omit<ComponentProps<typeof TypeaheadInput>, 'onSelect' | 'onSearch' | 'renderItem'>) {
  const [sessionToken, setSessionToken] = useState(() => v4());
  const [fetchAutocomplete] = useLazyQuery(PlacesTypeaheadAutocompleteQuery);
  const [fetchDetails] = useLazyQuery(PlacesTypeaheadDetailsQuery);

  const { theme } = useTheme();
  const onSearch = useCallback(
    async (q: string) => {
      if (!q) return [];
      const results = await fetchAutocomplete({
        variables: { query: q, sessionToken, latlng: location },
      });
      return (
        results.data?.placeAutocomplete.map((r) => ({
          place: r,
          value: r.placeId,
        })) ?? []
      );
    },
    [fetchAutocomplete, sessionToken, location],
  );

  return (
    <TypeaheadInput
      attribution={
        attribution ? (
          <Image
            source={require('../assets/powered_by_google_on_white.png')}
            style={{ width: '100%', height: 20 }}
            resizeMode="center"
          />
        ) : null
      }
      onSelect={(o) => {
        let token = sessionToken;
        // reset sessionToken since it is only valid through autocompletion and a single details request
        // https://developers.google.com/maps/documentation/places/web-service/details#sessiontoken
        setSessionToken(v4());
        fetchDetails({ variables: { placeId: o.value, sessionToken: token } })
          .then(({ data }) => {
            if (data?.placeDetails) {
              const details = data.placeDetails;
              onSelect({
                name: o.place.structuredFormatting.mainText ?? details.name,
                ID: details.placeId,
                latitude: details.geometry.location.lat,
                longitude: details.geometry.location.lng,
              });
            }
          })
          .catch((e) => {
            Sentry.captureException(e);
          });
      }}
      onSearch={onSearch}
      renderItem={(option) => {
        return (
          <View key={option.value} style={{ padding: 10 }} row spacing={8}>
            <Text text={option.place.structuredFormatting.mainText} />
            <Text
              text={option.place.structuredFormatting.secondaryText ?? ''}
              numberOfLines={1}
              size={11}
              color={theme.color.gray400}
              style={{ flex: 1 }}
            />
          </View>
        );
      }}
      {...props}
    />
  );
}

import hexToRgba from 'hex-to-rgba';
import { StyleSheet, View } from 'react-native';

import { useTheme } from '../styles';

export function SegmentedProgressBar({
  length,
  progress,
  variant,
  height = 12,
  gap = 2,
}: {
  length: number;
  progress: number;
  variant?: 'dark';
  height?: number;
  gap?: number;
}) {
  const padding = 4;
  const { theme } = useTheme();

  return (
    <View style={{ padding, flexDirection: 'row', height: height + padding * 2, flex: 1, gap }}>
      {Array.from({ length: length }).map((_, i) => {
        const isFirst = i === 0;
        const isLast = i === length - 1;
        const isPending = i > progress - 1;

        let backgroundColor;

        if (isPending) {
          backgroundColor = variant === 'dark' ? hexToRgba('#FFFFFF', 0.3) : theme.color.accent300;
        } else {
          backgroundColor = variant === 'dark' ? 'white' : theme.color.accent100;
        }

        return (
          <View
            key={i}
            style={[
              {
                height: '100%',
                flex: 1,
                backgroundColor,
              },
              isFirst ? styles.first : isLast ? styles.last : undefined,
            ]}
          />
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  first: {
    borderTopStartRadius: 20,
    borderBottomStartRadius: 20,
  },
  last: {
    borderBottomEndRadius: 20,
    borderTopEndRadius: 20,
  },
});

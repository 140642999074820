import { ComponentProps } from 'react';
import { Platform, ActivityIndicator as RNActivityIndicator } from 'react-native';

import { useTheme } from '@oui/app-core/src/styles';

export function ActivityIndicator(props: ComponentProps<typeof RNActivityIndicator>) {
  const { theme } = useTheme();
  return (
    <RNActivityIndicator
      color={Platform.OS === 'android' ? theme.color.primary100 : undefined}
      {...props}
      aria-label="Loading"
      aria-busy
    />
  );
}

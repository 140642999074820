import { useQuery } from '@apollo/client';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ContentType } from '@oui/lib/src/types';

import { useTheme } from '../../../styles';
import { ActivityIndicator } from '../../ActivityIndicator';
import { ScrollView } from '../../ScrollView';
import { View } from '../../View';
import { SessionActivities } from '../Activities/Activities';
import { MarkComplete } from '../MarkComplete/MarkComplete';
import { SessionVideos, SessionVideosQuery } from '../Videos/Videos';

export function VideosActivities({
  testID,
  contentType,
  onComplete,
}: {
  testID?: string;
  contentType: ContentType;
  onComplete: () => void;
}) {
  const { theme } = useTheme();
  const { data, loading } = useQuery(SessionVideosQuery, {
    variables: {
      contentType,
    },
  });
  const insets = useSafeAreaInsets();

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: theme.color.gray800 }}
      testID={testID}
      contentContainerStyle={{ padding: 20, paddingBottom: insets.bottom + 20 }}
    >
      {loading ? (
        <ActivityIndicator />
      ) : (
        <View spacing={50}>
          {data?.user?.role?.sessions[0]?.session.content?.quizSetCollections.length ? (
            <SessionVideos contentType={contentType} />
          ) : null}
          {data?.user?.role?.sessions[0]?.session.content?.activities.length ? (
            <SessionActivities contentType={contentType} />
          ) : null}
          {data?.user?.role?.sessions[0]?.progress?.completed ? null : (
            <MarkComplete contentType={contentType} onComplete={onComplete} />
          )}
        </View>
      )}
    </ScrollView>
  );
}

import { useEffect } from 'react';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
} from 'react-native-reanimated';

import { useConversationContext } from './ConversationContext';
import { View } from './View';

const DOT_SIZE = 10;

export function ChatTypingIndicator(_props: {}) {
  const { receivedMessageBorderColor } = useConversationContext();
  const step = useSharedValue(0);
  useEffect(() => {
    step.value = withRepeat(withSequence(withTiming(1, { duration: 1500 })), -1, false);
  }, [step]);

  const dot1 = useAnimatedStyle(() => ({
    opacity: interpolate(step.value, [0, 0.3, 0.9, 1], [0, 1, 1, 0]),
  }));
  const dot2 = useAnimatedStyle(() => ({
    opacity: interpolate(step.value, [0.25, 0.55, 0.9, 1], [0, 1, 1, 0]),
  }));
  const dot3 = useAnimatedStyle(() => ({
    opacity: interpolate(step.value, [0.6, 0.8, 0.8, 1], [0, 1, 1, 0]),
  }));

  return (
    <View row spacing={8} style={{ paddingVertical: 6 }}>
      <Animated.View
        style={[
          {
            width: DOT_SIZE,
            height: DOT_SIZE,
            borderRadius: DOT_SIZE / 2,
            backgroundColor: receivedMessageBorderColor,
            opacity: 1,
          },
          dot1,
        ]}
      />
      <Animated.View
        style={[
          {
            width: DOT_SIZE,
            height: DOT_SIZE,
            borderRadius: DOT_SIZE / 2,
            backgroundColor: receivedMessageBorderColor,
          },
          dot2,
        ]}
      />
      <Animated.View
        style={[
          {
            width: DOT_SIZE,
            height: DOT_SIZE,
            borderRadius: DOT_SIZE / 2,
            backgroundColor: receivedMessageBorderColor,
          },
          dot3,
        ]}
      />
    </View>
  );
}

import { Text } from '../Text';
import { View } from '../View';

export interface AvatarProps {
  name: string;
  size?: number;
  round?: boolean;
}

const colorGroups = [
  ['#F8E9E9', '#AE595B'],
  ['#EEE8EE', '#8B688D'],
  ['#D9EDED', '#008689'],
  ['#FFF5D4', '#A88207'],
];

function getColorForName(name: string) {
  if (!name.length) return colorGroups[0];
  const value = name.split('').reduce((carry, el) => carry + el.toUpperCase().charCodeAt(0), 0);
  return colorGroups[value % colorGroups.length];
}

const getInitials = (name: string) => {
  let firstLetters = name.match(/\b\w/g) || [];
  return ((firstLetters.shift() || '') + (firstLetters.pop() || '')).toUpperCase();
};

export const Avatar: React.FC<AvatarProps> = ({ name, round = true, size = 25 }) => {
  const initials = getInitials(name);
  const [backgroundColor, color] = getColorForName(name);

  return (
    <View
      aria-label={`User Avatar for ${name}`}
      testID={`Avatar_${initials}`}
      style={{
        width: size,
        height: size,
        backgroundColor,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: round ? size / 2 : 0,
      }}
    >
      <Text color={color} weight="bold" size={size / 2.2} text={initials} />
    </View>
  );
};

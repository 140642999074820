// Use a separate file from useCurrentUser.ts to prevent circular import with lib/auth.ts
import { graphql } from '@oui/lib/src/graphql/tada';

const CommonUserFragment = graphql(`
  fragment CommonUser on OuiUserType @_unmask {
    __typename
    ... on OuiAdmin {
      ID
    }
    ... on Registrar {
      ID
    }
    ... on Practitioner {
      ID
    }
    ... on Patient {
      ID
    }
  }
`);

export const CurrentOuiUserFragment = graphql(
  `
    fragment CurrentOuiUser on CurrentOuiUser @_unmask {
      __typename
      userID
      username
      createdAt
      attributes {
        admin
        testUser
        trialAuditor
      }
      role
      roles {
        ID
        role
        user {
          ...CommonUser
        }
        organization {
          __typename
          ID
        }
      }
      primaryOrganization {
        __typename
        ID
        isTrialOrganization
      }
      user {
        __typename
        ... on OuiAdmin {
          ID
          person {
            email
            givenName
            familyName
            phone
          }
        }
        ... on Registrar {
          ID
          person {
            email
            givenName
            familyName
            phone
          }
        }
        ... on Practitioner {
          ID
          person {
            email
            givenName
            familyName
            phone
          }
        }
        ... on Patient {
          ID
          role {
            ID
            roleType
            product {
              ID
              slug
            }
          }
          productVersion
          productStatic
          onboardingVariant
          person {
            email
            givenName
            familyName
            phone
            birthDate
            race
            genderIdentity
            sexualOrientation
            militaryStatus
            address {
              use
              line
              city
              state
              postalCode
            }
          }
        }
      }
    }
  `,
  [CommonUserFragment],
);

export const CurrentUserQueryName = 'CurrentUser';
export type CurrentUserQueryName = typeof CurrentUserQueryName;
export const CurrentUserQuery = graphql(
  `
    query CurrentUser {
      currentUser: ouiUser {
        ...CurrentOuiUser
      }
    }
  `,
  [CurrentOuiUserFragment],
);

import { remoteConfigDefaults } from '../constants';

type Key = keyof typeof remoteConfigDefaults;

export async function initConfig() {}
export async function initLastConfig() {}

export function getConfigString(key: Key) {
  return remoteConfigDefaults[key];
}

import { useFocusEffect, useNavigation } from '@react-navigation/native';
import * as ScreenOrientation from 'expo-screen-orientation';
import { useCallback } from 'react';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { ArtifactButton } from '@oui/app-core/src/components/ArtifactButton';
import { LessonsLearned } from '@oui/app-core/src/components/LessonsLearned/LessonsLearned';
import { ProfileHeader } from '@oui/app-core/src/components/ProfileHeader/ProfileHeader';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Heading } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useMyStoryMyPlanCompositionSections } from '@oui/app-core/src/hooks/useComposition';
import { useCurrentPatient, useProgressByContent } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { ProductVariant } from '@oui/lib/src/types/graphql.generated';

import { PatientSupporters } from '@src/components/PatientSupporters';

import { TabScreenProps } from '../types';

function Records() {
  const { $t } = useI18n();
  const { user } = useCurrentPatient();
  const { data: progress } = useProgressByContent();
  const { navigate } = useNavigation<TabScreenProps<'Profile'>['navigation']>();
  const { data } = useMyStoryMyPlanCompositionSections();
  const hasData = progress.MYPLAN?.completed && !!data;

  const doneText = $t({ id: 'UserProfile_recordComplete', defaultMessage: 'Done' });
  const lockedText = $t({ id: 'UserProfile_recordLocked', defaultMessage: 'Complete session 1' });

  if (
    ([ProductVariant.AVIVA_MILITARY, ProductVariant.AVIVA_INPATIENT] as string[]).includes(
      user?.role.product.slug!,
    )
  ) {
    const suicideMode = $t({
      id: 'UserProfile_suicideModeTitle',
      defaultMessage: 'My Suicide Mode',
    });
    return hasData ? (
      <ArtifactButton
        testID="ArtifactButton_SuicideMode"
        badgeText={doneText}
        assetName="SuicideMode"
        title={suicideMode}
        numCompleted={1}
        onPress={() => navigate('SuicideMode', { step: 'review' })}
      />
    ) : (
      <ArtifactButton
        testID="ArtifactButton_SuicideMode__locked"
        bannerText={lockedText}
        assetName="SuicideMode"
        title={suicideMode}
        numCompleted={0}
        onPress={() => {}}
      />
    );
  }

  const riskCurve = $t({ id: 'UserProfile_riskCurveTitle', defaultMessage: 'Risk Curve' });

  return hasData ? (
    <ArtifactButton
      testID="ArtifactButton_RiskCurve"
      badgeText={doneText}
      assetName="RiskCurve"
      title={riskCurve}
      numCompleted={1}
      onPress={() => navigate('RiskCurve', { step: 'review' })}
    />
  ) : (
    <ArtifactButton
      testID="ArtifactButton_RiskCurve__locked"
      bannerText={lockedText}
      assetName="RiskCurve"
      title={riskCurve}
      numCompleted={0}
      onPress={() => {}}
    />
  );
}

export function UserProfile() {
  const { $t } = useI18n();
  const insets = useSafeAreaInsets();

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        void ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
        return () => ScreenOrientation.unlockAsync();
      }

      return;
    }, []),
  );

  return (
    <View style={{ flex: 1 }} testID="Profile">
      <ScrollView
        bottomOverflowColor="white"
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingTop: insets.top,
          backgroundColor: 'white',
          paddingBottom: 20,
        }}
        testID="Profile_scrollView"
      >
        <ProfileHeader />
        <LessonsLearned />
        <View>
          <Heading
            level={2}
            text={$t({
              id: 'UserProfile_recordsHeading',
              defaultMessage: 'Records',
            })}
            style={{
              paddingHorizontal: 20,
              marginBottom: 12,
            }}
          />
          <View style={{ paddingHorizontal: 20 }}>
            <Records />
            <PatientSupporters
              style={{
                marginTop: 25,
              }}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  );
}

import { HeaderBackButton } from '@react-navigation/elements';
import { useNavigation, useRoute } from '@react-navigation/native';
import * as Contacts from 'expo-contacts';
import { produce } from 'immer';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  SafeAreaView,
  SectionList,
  SectionListStatic,
  TouchableHighlight,
  TouchableOpacity,
} from 'react-native';

import { Button } from '../components/Button';
import { Checkbox } from '../components/Checkbox';
import { Text } from '../components/Text';
import { TextInput } from '../components/TextInput';
import { View } from '../components/View';
import { useI18n } from '../lib/i18n';
import { askAsync } from '../lib/permissionsManager';
import { useTheme } from '../styles';
import { StackScreenProps } from '../types/navigation';

const ContactsSectionList: SectionListStatic<Contacts.Contact> = SectionList;

const CONTACT_FIELDS = [
  Contacts.Fields.ID,
  Contacts.Fields.ContactType,
  Contacts.Fields.Name,
  Contacts.Fields.FirstName,
  Contacts.Fields.MiddleName,
  Contacts.Fields.LastName,
  Contacts.Fields.MaidenName,
  Contacts.Fields.NamePrefix,
  Contacts.Fields.NameSuffix,
  Contacts.Fields.Nickname,
  Contacts.Fields.PhoneticFirstName,
  Contacts.Fields.PhoneticMiddleName,
  Contacts.Fields.PhoneticLastName,
  Contacts.Fields.Emails,
  Contacts.Fields.PhoneNumbers,
  Contacts.Fields.Addresses,
  Contacts.Fields.Company,
  Contacts.Fields.JobTitle,
  Contacts.Fields.Department,
  Contacts.Fields.ImageAvailable,
  Contacts.Fields.Image,
  Contacts.Fields.RawImage,
  Contacts.Fields.ExtraNames,
  Contacts.Fields.Relationships,
];
const ITEM_HEIGHT = 60;

const SectionListItem = memo(function SectionListItem({
  sortCharacter,
  checked,
  id,
  name,
  onPress,
}: {
  sortCharacter: string;
  checked: boolean;
  id: string;
  name: string;
  onPress: (id: string) => void;
}) {
  const { theme } = useTheme();
  const onPressWithId = () => onPress(id);
  const nameParts = (name ?? '')
    .split(' ')
    .map((part, i) =>
      i > 0 && part[0]?.toUpperCase() === sortCharacter
        ? [part[0], `${part.slice(1) ?? ''} `]
        : `${part} `,
    )
    .flat();

  return (
    <TouchableHighlight
      onPress={onPressWithId}
      underlayColor={theme.color.accent100}
      style={[
        { padding: 15, height: ITEM_HEIGHT },
        checked ? { backgroundColor: theme.color.accent100 } : null,
      ]}
    >
      <View row spacing={8}>
        <Checkbox
          onChangeValue={onPressWithId}
          value={checked}
          testID={`ContactsPicker_Item_${name}`}
        />
        <>
          {nameParts.map((part, i) => {
            const matchesSortCharacter = part?.toUpperCase() === sortCharacter;
            return (
              <Text text={part} weight={matchesSortCharacter ? 'semibold' : undefined} key={i} />
            );
          })}
        </>
      </View>
    </TouchableHighlight>
  );
});

export function ContactsPicker(props: {
  onChooseContacts?: (result: { contacts: Contacts.Contact[]; permissionDenied?: boolean }) => void;
}) {
  const { theme } = useTheme();
  const navigation = useNavigation<StackScreenProps<'ContactsPicker'>['navigation']>();
  const route = useRoute<StackScreenProps<'ContactsPicker'>['route']>();
  const list = useRef<typeof ContactsSectionList>(null);
  const [filter, setFilter] = useState('');
  const [checked, setChecked] = useState<{ [key: string]: boolean | undefined }>({});
  const [items, setItems] = useState<Contacts.Contact[]>([]);
  const navigate = navigation.navigate;
  const returnRoute = route.params.returnRoute;
  const onChooseContacts = props.onChooseContacts;
  const onChoose = useCallback(
    (contacts: Contacts.Contact[], permissionDenied?: boolean) => {
      if (onChooseContacts) {
        onChooseContacts({ contacts, permissionDenied });
      } else {
        navigate({
          name: returnRoute as any, // eslint-disable-line @typescript-eslint/no-explicit-any
          params: { _contactPickerResult: { contacts, permissionDenied } },
          merge: true,
        });
      }
    },
    [navigate, returnRoute, onChooseContacts],
  );
  const { $t } = useI18n();

  const setOptions = navigation.setOptions;
  useEffect(() => {
    setOptions({
      title: 'Contacts',
      headerLeft: ({ tintColor }) => (
        <HeaderBackButton
          onPress={() => {
            onChoose([]);
          }}
          tintColor={tintColor}
        />
      ),
      headerRight: undefined,
    });
  }, [setOptions, onChoose]);

  useEffect(() => {
    void askAsync('Contacts', () => Contacts.requestPermissionsAsync(), {
      retryIfDenied: true,
    }).then(({ status }) => {
      if (status === 'granted') {
        return Contacts.getContactsAsync({
          fields: CONTACT_FIELDS,
        }).then(({ data }) => {
          setItems(sortBy(data, ['lastName', 'name']));
        });
      }

      onChoose([], true);
      return;
    });
  }, [onChoose]);

  const contactsByInitial = useMemo(() => {
    const lowercaseFilter = filter.toLowerCase();
    const filtered = filter
      ? items.filter(
          (contact) =>
            `${contact.name}${contact.lastName}${contact.firstName}${contact.nickname}`
              .toLowerCase()
              .indexOf(lowercaseFilter) >= 0,
        )
      : items;
    function getFirstCharGroup(str: string) {
      const first = str[0].toUpperCase();
      return first.match(/[A-Z]/) ? first : '#';
    }
    return groupBy(filtered, (item) => {
      const name =
        item.lastName ||
        item.name ||
        item.firstName ||
        item.nickname ||
        item.middleName ||
        'No name';
      const fallbackName =
        item.name || item.firstName || item.nickname || item.middleName || 'No name';
      const nameChar = getFirstCharGroup(name);
      if (nameChar === '#') return getFirstCharGroup(fallbackName);
      return nameChar;
    });
  }, [items, filter]);

  const sidebarInitials = useMemo(() => {
    return Object.keys(contactsByInitial).sort();
  }, [contactsByInitial]);

  const data = useMemo(() => {
    return Object.entries(contactsByInitial)
      .map(([initial, contacts]) => ({
        title: initial,
        data: contacts,
      }))
      .sort((a, b) => (a.title < b.title ? -1 : 1));
  }, [contactsByInitial]);

  const onToggle = useCallback((itemID: string) => {
    setChecked(
      produce((draft) => {
        if (draft[itemID]) {
          delete draft[itemID];
        } else {
          draft[itemID] = true;
        }
      }),
    );
  }, []);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <TextInput
        value={filter}
        onChangeValue={setFilter}
        placeholder={$t({ id: 'ContactsPicker_searchPlaceholder', defaultMessage: 'Search' })}
        variant="flat"
      />
      <View flex={1} spacing={12}>
        <View flex={1} row style={{ alignItems: 'stretch' }}>
          <ContactsSectionList
            ref={list as any} // eslint-disable-line
            getItemLayout={(_, index) => ({
              length: ITEM_HEIGHT,
              offset: ITEM_HEIGHT * index,
              index,
            })}
            renderItem={({ section, item, index }) => {
              return (
                <SectionListItem
                  sortCharacter={section.title}
                  onPress={onToggle}
                  id={item.id || index.toString()}
                  checked={!!checked[item?.id || index]}
                  name={item.name}
                />
              );
            }}
            ItemSeparatorComponent={() => <View style={{ backgroundColor: '#eee', height: 1 }} />}
            renderSectionFooter={() => <View style={{ height: 15 }} />}
            renderSectionHeader={({ section: { title } }) => (
              <View style={{ padding: 15 }}>
                <Text
                  text={title}
                  weight="semibold"
                  size={15}
                  style={{ lineHeight: 22 }}
                  color={theme.color.gray300}
                />
              </View>
            )}
            sections={data}
            keyExtractor={(item, index) => item.id?.toString() ?? index.toString()}
            style={[{ flex: 1 }]}
          />
          <View
            style={{
              justifyContent: 'center',
              width: 25,
              top: 80,
              bottom: 0,
              position: 'absolute',
              right: 8,
            }}
          >
            {sidebarInitials.map((initial, i) => (
              <TouchableOpacity
                key={initial}
                onPress={() => {
                  if (list.current) {
                    list.current.scrollToLocation!({
                      itemIndex: 0,
                      sectionIndex: i,
                    });
                  }
                }}
                style={{
                  paddingHorizontal: 4,
                  alignItems: 'center',
                }}
              >
                <Text
                  text={initial}
                  size={15}
                  style={{ lineHeight: 22 }}
                  color={theme.color.gray300}
                />
              </TouchableOpacity>
            ))}
          </View>
        </View>
        <Button
          testID="ContactsPicker_Choose"
          text={$t(
            {
              id: 'ContactsPicker_chooseButton',
              defaultMessage: 'Add {numContacts, plural, one{1 contact} other{# contacts}}',
            },
            {
              numContacts: Object.values(checked).filter((val) => !!val).length,
            },
          )}
          onPress={() => {
            onChoose(items.filter((contact, index) => !!checked[contact.id ?? index]));
          }}
          alignSelf="center"
          disabled={Object.keys(checked).length === 0}
        />
      </View>
    </SafeAreaView>
  );
}

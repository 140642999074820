import { Image } from 'react-native';

import { Small, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useTheme } from '@oui/app-core/src/styles';

/**
 * Shows user contact information for the suicide lifeline
 */
export const LifelineBanner = () => {
  const { theme } = useTheme();
  return (
    <View
      testID="LifelineBanner"
      row
      style={{ gap: 15, paddingHorizontal: 15, paddingVertical: 10 }}
    >
      <Image
        source={require('../../assets/LifelineBannerLogo.png')}
        style={{ width: 38, height: 38 }}
      />
      <Text
        size={theme.typography.small.size}
        lineHeight={theme.typography.small.lineHeight}
        color={theme.color.gray300}
        style={{ flex: 1 }} // prevent text overflow on native
        text={
          <>
            <Small text="Call or text 988" /> if you need immediate help. Aviva does not provide
            emergency support.
          </>
        }
      />
    </View>
  );
};

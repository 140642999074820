/* when reauthenticating, it's possible for a user to tap a button and push on a new screen before Reauth screen locks down
 * these helpers make it easier to give the reauth animation time to take over the screen
 * https://github.com/ouihealth/oui/issues/1259
 */

let pauseInteractionUntil: number | undefined;

export function pauseInteractionForMillis(numMillis: number) {
  pauseInteractionUntil = Date.now() + numMillis;
}

export function isInteractionPaused() {
  return typeof pauseInteractionUntil === 'number' && Date.now() < pauseInteractionUntil;
}

import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import * as ScreenOrientation from 'expo-screen-orientation';
import { useCallback } from 'react';
import { Platform, RefreshControl, StatusBar } from 'react-native';

import { Icon } from '@oui/app-core/src/components/Icon';
import { PracticeTile } from '@oui/app-core/src/components/PracticeTile';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { TabHeader } from '@oui/app-core/src/components/TabHeader';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { Environment, environment } from '@oui/app-core/src/constants';
import { useActionCounts } from '@oui/app-core/src/hooks/practices';
import { useProgressByContent } from '@oui/app-core/src/hooks/useCurrentUser';
import Sentry from '@oui/app-core/src/sentry';
import { useTheme } from '@oui/app-core/src/styles';
import { ContentType } from '@oui/lib/src/types';

import { StaticScreenProps } from '../types/navigation';

export function PracticeStatic() {
  const { theme } = useTheme();
  const isFocused = useIsFocused();
  const { navigate } = useNavigation<StaticScreenProps<'Practice'>['navigation']>();
  const { data: progress, refetch, loading } = useProgressByContent();
  const { data: countByActionType, refetch: refetchActionCounts } = useActionCounts();

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
      }
      try {
        refetch();
        refetchActionCounts();
      } catch (e) {
        // HMR error in dev
        if (environment !== Environment.DEVELOPMENT) {
          Sentry.captureException(e);
        }
      }
      return () => {
        if (Platform.OS !== 'web') {
          ScreenOrientation.unlockAsync();
        }
      };
    }, [refetch, refetchActionCounts]),
  );

  return (
    <View style={{ flex: 1 }}>
      {isFocused ? <StatusBar backgroundColor="white" barStyle="dark-content" /> : null}
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={!!loading}
            onRefresh={() => {
              return Promise.all([refetch(), refetchActionCounts()]);
            }}
            title="Update"
            progressViewOffset={150}
          />
        }
        testID="PracticeStatic_scrollView"
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingHorizontal: 20,
          paddingBottom: 20,
        }}
      >
        <TabHeader heading="Practice" />
        <View
          style={{
            marginHorizontal: -20,
            backgroundColor: theme.color.dark,
            padding: 16,
            marginBottom: 20,
            justifyContent: 'center',
          }}
          row
          spacing={12}
        >
          <Icon name="practice" color="white" size={24} />
          <Text
            text={`${countByActionType.TOTAL ?? 0} total practices done`}
            weight="semibold"
            size={21}
            color="white"
          />
        </View>
        <Heading text="Controlling a crisis" style={{ marginBottom: 12 }} level={2} />
        <View row spacing={20}>
          <PracticeTile
            testID="PracticeTile_01"
            title="Suicide Context review"
            description=""
            color="#9f5aa3"
            icon="cards"
            onPress={() =>
              navigate('StaticReview', {
                slug: ContentType.STATIC_01,
                sessionTitle: 'Suicide Context review',
              })
            }
            lockedUntil={progress.STATIC_01?.completed ? undefined : 1}
            practiceCount={countByActionType.STATIC_01}
          />
          <PracticeTile
            testID="PracticeTile_02"
            title="Understanding Suicide review"
            description=""
            color="#9f5aa3"
            icon="cards"
            onPress={() =>
              navigate('StaticReview', {
                slug: ContentType.STATIC_02,
                sessionTitle: 'Understanding Suicide review',
              })
            }
            lockedUntil={progress.STATIC_02?.completed ? undefined : 2}
            practiceCount={countByActionType.STATIC_02}
          />
        </View>
        <Heading text="Manage risk factors" style={{ marginTop: 40, marginBottom: 12 }} level={2} />
        <View row spacing={20}>
          <PracticeTile
            testID="PracticeTile_03"
            title="Suicide & Stress review"
            description=""
            color="#202E53"
            icon="cards"
            onPress={() =>
              navigate('StaticReview', {
                slug: ContentType.STATIC_03,
                sessionTitle: 'Suicide Statistics review',
              })
            }
            lockedUntil={progress.STATIC_03?.completed ? undefined : 3}
            practiceCount={countByActionType.STATIC_03}
          />
          <PracticeTile
            testID="PracticeTile_04"
            title="Suicide & Depression review"
            description=""
            color="#202E53"
            icon="cards"
            onPress={() =>
              navigate('StaticReview', {
                slug: ContentType.STATIC_04,
                sessionTitle: 'Suicide & Stress review',
              })
            }
            lockedUntil={progress.STATIC_04?.completed ? undefined : 4}
            practiceCount={countByActionType.STATIC_04}
          />
        </View>
        <View row spacing={20} style={{ marginTop: 20 }}>
          <PracticeTile
            testID="PracticeTile_05"
            title="Suicide & Anxiety review"
            description=""
            color="#202E53"
            icon="cards"
            onPress={() =>
              navigate('StaticReview', {
                slug: ContentType.STATIC_05,
                sessionTitle: 'Suicide & Depression review',
              })
            }
            lockedUntil={progress.STATIC_05?.completed ? undefined : 5}
            practiceCount={countByActionType.STATIC_05}
          />
          <PracticeTile
            testID="PracticeTile_06"
            title="Suicide & Chronic illness review"
            description=""
            color="#202E53"
            icon="cards"
            onPress={() =>
              navigate('StaticReview', {
                slug: ContentType.STATIC_06,
                sessionTitle: 'Suicide & Anxiety review',
              })
            }
            lockedUntil={progress.STATIC_06?.completed ? undefined : 6}
            practiceCount={countByActionType.STATIC_06}
          />
        </View>
        <View row spacing={20} style={{ marginTop: 20 }}>
          <PracticeTile
            testID="PracticeTile_07"
            title="Suicide & Cancer review"
            description=""
            color="#202E53"
            icon="cards"
            onPress={() =>
              navigate('StaticReview', {
                slug: ContentType.STATIC_07,
                sessionTitle: 'Suicide & Chronic illness review',
              })
            }
            lockedUntil={progress.STATIC_07?.completed ? undefined : 7}
            practiceCount={countByActionType.STATIC_07}
          />
          <PracticeTile
            testID="PracticeTile_08"
            title="Suicide & Schizophrenia review"
            description=""
            color="#202E53"
            icon="cards"
            onPress={() =>
              navigate('StaticReview', {
                slug: ContentType.STATIC_08,
                sessionTitle: 'Suicide & Cancer review',
              })
            }
            lockedUntil={progress.STATIC_08?.completed ? undefined : 8}
            practiceCount={countByActionType.STATIC_08}
          />
        </View>
        <View row spacing={20} style={{ marginTop: 20 }}>
          <View flex={1}>
            <PracticeTile
              testID="PracticeTile_09"
              title="Suicide & Addiction review"
              description=""
              color="#202E53"
              icon="cards"
              onPress={() =>
                navigate('StaticReview', {
                  slug: ContentType.STATIC_09,
                  sessionTitle: 'Suicide & Addiction review',
                })
              }
              lockedUntil={progress.STATIC_09?.completed ? undefined : 9}
              practiceCount={countByActionType.STATIC_09}
            />
          </View>
          <View flex={1} />
        </View>
        <Heading text="Review skills" style={{ marginTop: 40, marginBottom: 12 }} level={2} />
        <View row spacing={20} style={{ marginTop: 20 }}>
          <PracticeTile
            testID="PracticeTile_10"
            title="Common warning signs review"
            description=""
            color="#1B9FA0"
            icon="cards"
            onPress={() =>
              navigate('StaticReview', {
                slug: ContentType.STATIC_10,
                sessionTitle: 'Common warning signs review',
              })
            }
            lockedUntil={progress.STATIC_10?.completed ? undefined : 10}
            practiceCount={countByActionType.STATIC_10}
          />
          <PracticeTile
            testID="PracticeTile_11"
            title="Common risk factors review"
            description=""
            color="#1B9FA0"
            icon="cards"
            onPress={() =>
              navigate('StaticReview', {
                slug: ContentType.STATIC_11,
                sessionTitle: 'Common risk factors review',
              })
            }
            lockedUntil={progress.STATIC_11?.completed ? undefined : 11}
            practiceCount={countByActionType.STATIC_11}
          />
        </View>
        <View row spacing={20} style={{ marginTop: 20 }}>
          <View flex={1}>
            <PracticeTile
              testID="PracticeTile_12"
              title="What's next review"
              description=""
              color="#1B9FA0"
              icon="cards"
              onPress={() =>
                navigate('StaticReview', {
                  slug: ContentType.STATIC_12,
                  sessionTitle: "What's next review",
                })
              }
              lockedUntil={progress.STATIC_12?.completed ? undefined : 12}
              practiceCount={countByActionType.STATIC_12}
            />
          </View>
          <View flex={1} />
        </View>
      </ScrollView>
    </View>
  );
}

import { ReactNode } from 'react';

import { ActivityDiaryProvider } from '@oui/activity-diary';
import { ConversationProvider } from '@oui/app-core/src/components/ConversationContext';
import { useCurrentPatient } from '@oui/app-core/src/hooks/useCurrentUser';
import { HopeKitProvider } from '@oui/hope-kit';
import { RelaxProvider } from '@oui/relax-diary';
import { SleepDiaryProvider } from '@oui/sleep-diary';

import { ProductVariant } from '@src/types';

export function WidgetProviders({ children }: { children?: ReactNode }) {
  const { user } = useCurrentPatient();

  return (
    <RelaxProvider>
      <ActivityDiaryProvider>
        <SleepDiaryProvider>
          <HopeKitProvider name="Hope kit">
            <ConversationProvider
              showTabsBeforeCompletion={user?.role.product.slug === ProductVariant.AVIVA_INPATIENT}
              showTabsAfterCompletion={user?.role.product.slug === ProductVariant.AVIVA_ADOLESCENT}
            >
              {children}
            </ConversationProvider>
          </HopeKitProvider>
        </SleepDiaryProvider>
      </ActivityDiaryProvider>
    </RelaxProvider>
  );
}

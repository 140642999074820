import { ApolloClient } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Sentry from '@sentry/core';
import * as Crypto from 'expo-crypto';
import { NativeModulesProxy } from 'expo-modules-core';
import * as SecureStore from 'expo-secure-store';
import Cookies from 'js-cookie';
import omit from 'lodash/omit';
import { Platform } from 'react-native';

import { getUserIDForThirdParties } from '@oui/lib/src/getUserIDForThirdParties';
import { graphql, readFragment } from '@oui/lib/src/graphql/tada';
import { GQLDateTime } from '@oui/lib/src/types/scalars';

import { APP_SLUG, IS_PRODUCTION } from '../constants';
import { CurrentOuiUserFragment, CurrentUserQuery } from '../hooks/useCurrentUser.query';
import { addBreadcrumb, setUser, setUserProperties } from '../lib/log';
import { clearAll } from '../lib/mmkv';

export const LoginMutation = graphql(
  `
    mutation Login($email: String!, $password: String!) {
      loginWithEmail(email: $email, password: $password) {
        __typename
        ... on BaseError {
          message
        }
        ... on InvalidPasswordError {
          message
        }
        ... on UserDisabledError {
          message
        }
        ... on UserSession {
          token {
            expiresAt
            value
          }
          currentOuiUser {
            ...CurrentOuiUser
          }
        }
      }
    }
  `,
  [CurrentOuiUserFragment],
);

const ReauthenticateMutation = graphql(`
  mutation Reauthenticate($email: String!, $password: String!) {
    reauthenticate(email: $email, password: $password)
  }
`);

function getSecureStoreCompatibleKey(key: string): string {
  return key.replace(/[^\w\d\.\-\_]/g, '_');
}

function isCryptoAvailable() {
  return !!NativeModulesProxy.ExpoCrypto;
}

const HASH_SALT = 'bc22d4a6ff53d8406c6ad864195ed144';
const PASSWORD_HASH_STORAGE_KEY = 'passwordHash';
async function hashPassword(password: string) {
  if (isCryptoAvailable()) {
    return await Crypto.digestStringAsync(
      Crypto.CryptoDigestAlgorithm.SHA512,
      HASH_SALT + password,
    );
  }
  throw new Error('expo-crypto is unavailable');
}

async function hashAndStorePassword(password: string) {
  if (isCryptoAvailable()) {
    const hash = await hashPassword(password);
    if (Platform.OS !== 'web') {
      // delete so we can update keychainAccessible
      await SecureStore.deleteItemAsync(PASSWORD_HASH_STORAGE_KEY);
      await SecureStore.setItemAsync(PASSWORD_HASH_STORAGE_KEY, hash, {
        keychainAccessible: SecureStore.WHEN_UNLOCKED_THIS_DEVICE_ONLY,
      });
    }
  }
}

function getTokenFromCookie(key: string) {
  const maybeStr = Cookies.get(key);
  if (maybeStr) {
    return JSON.parse(global.window.atob(maybeStr)) as Token | null;
  }
  return null;
}

const authStorage: {
  getItem: (key: string) => Promise<Token | null | undefined>;
  setItem: (key: string, token: Token | null) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
} = process.env.NEXT_PUBLIC_API_CLIENT
  ? {
      getItem: async (key) => {
        return getTokenFromCookie(key);
      },
      setItem: async (key, value) => {
        Cookies.set(key, global.window.btoa(JSON.stringify(value)), {
          expires: value ? new Date(value.expiresAt) : 0,
        });
      },
      removeItem: async (key) => {
        Cookies.remove(key);
      },
    }
  : Platform.OS === 'web'
    ? {
        getItem: async (key) => {
          const value = await AsyncStorage.getItem(key);
          return value ? (JSON.parse(value) as Token | null) : null;
        },
        setItem: async (key, value) => {
          await AsyncStorage.setItem(key, JSON.stringify(value));
        },
        removeItem: async (key) => {
          await AsyncStorage.removeItem(key);
        },
      }
    : {
        getItem: async (key) => {
          const value = await SecureStore.getItemAsync(getSecureStoreCompatibleKey(key));
          if (value) {
            const parsedValue = JSON.parse(value) as Token | null;
            // setItem so that we update keychainAccessible. Can be removed in a future version
            // once we are satisfied most users have migrated
            await authStorage.setItem(key, parsedValue);
            return parsedValue;
          }
          return null;
        },
        setItem: async (key, token) => {
          const value = JSON.stringify(token);
          if (value.length > 2048 - 100) {
            Sentry.captureMessage('Auth SecureStore usage close to length limit', {
              level: 'warning',
              extra: {
                length: value.length,
              },
            });
          }
          // delete so we can update keychainAccessible
          await SecureStore.deleteItemAsync(getSecureStoreCompatibleKey(key));
          return SecureStore.setItemAsync(getSecureStoreCompatibleKey(key), value, {
            keychainAccessible: SecureStore.WHEN_UNLOCKED_THIS_DEVICE_ONLY,
          });
        },
        removeItem: async (key: string) => {
          return SecureStore.deleteItemAsync(getSecureStoreCompatibleKey(key));
        },
      };

const USER_SESSION_TOKEN_STORAGE_KEY = process.env.NEXT_PUBLIC_API_CLIENT
  ? `${process.env.NEXT_PUBLIC_API_CLIENT}.session`
  : 'ouitoken';

let initialized = false;
export function isInitialized() {
  return initialized;
}

let apollo: ApolloClient<unknown>;

type Token = { value: string; expiresAt: GQLDateTime };
let ouiToken: Token | null;
let sessionTimeout: NodeJS.Timeout;

export function getAuthorizationHeader() {
  const authToken = getAuthToken();
  return authToken ? `Bearer ${authToken}` : undefined;
}

function getOuiToken() {
  // If we use cookie storage we should refresh our token in case the expiry has changed from a Set-Cookie header
  if (process.env.NEXT_PUBLIC_API_CLIENT && global.window) {
    // We need this until all our nextjs projects use our own managed cookie instead of next-auth
    const maybeToken = getTokenFromCookie(USER_SESSION_TOKEN_STORAGE_KEY);
    if (maybeToken) {
      ouiToken = getTokenFromCookie(USER_SESSION_TOKEN_STORAGE_KEY);
    }
  }
  if (ouiToken && new Date(ouiToken.expiresAt) > new Date()) {
    return ouiToken;
  }
  return null;
}

export function setAuthApolloClient(apolloClient: ApolloClient<unknown>) {
  apollo = apolloClient;
}

function startSessionTimeoutTimer() {
  if (Platform.OS === 'web') {
    if (!getOuiToken()) return;
    if (sessionTimeout) clearTimeout(sessionTimeout);

    function getMsToTokenExpiry() {
      const token = getOuiToken();
      if (!token?.expiresAt) return 0;
      const msUntilExpiry = new Date(token.expiresAt).getTime() - new Date().getTime();
      return Math.max(msUntilExpiry, 0);
    }

    function runSessionTimeout(ms: number) {
      sessionTimeout = setTimeout(
        () => {
          const token = getOuiToken();
          if (token) {
            runSessionTimeout(getMsToTokenExpiry());
          } else {
            // TODO maybe try to call useLogout?
            global.window.location.reload();
          }
        },
        Math.min(ms, 5 * 60 * 1000),
      );
    }
    runSessionTimeout(getMsToTokenExpiry());
  }
}

export async function initAuth(
  apolloClient: ApolloClient<unknown>,
): Promise<{ isLoggedIn: boolean }> {
  if (initialized) {
    return Promise.resolve({ isLoggedIn: getIsLoggedIn() });
  }
  setAuthApolloClient(apolloClient);

  const parsedToken = await authStorage.getItem(USER_SESSION_TOKEN_STORAGE_KEY);
  if (parsedToken && new Date(parsedToken.expiresAt) > new Date()) {
    ouiToken = parsedToken;
  }

  const response = await (getOuiToken()
    ? apollo.query({ query: CurrentUserQuery, fetchPolicy: 'cache-first' })
    : null);

  // If we have a token, but didn't get a user back, that means the token is not valid
  if (ouiToken && response?.data && !response.data.currentUser) {
    await persistOuiToken(null);
    ouiToken = null;
  }

  initialized = true;

  const user = response?.data.currentUser;
  const email = user?.user?.person.email;

  if (user) {
    const claims = user.attributes;
    setUserProperties({
      testUser: claims?.testUser || email?.includes('detox+'),
    });
  }

  setUser({
    userID: user ? getUserIDForThirdParties(user) : undefined,
    email: IS_PRODUCTION && APP_SLUG !== 'oui-aviva-staff' ? undefined : email,
  });

  addBreadcrumb({ category: 'auth', message: 'init', data: { isLoggedIn: !!user } });
  emitAuthStateChanged(!!user);
  startSessionTimeoutTimer();
  return { isLoggedIn: !!user };
}

export async function DANGEROUS_overrideOuiToken(token: Token | null) {
  initialized = true;
  await persistOuiToken(token);
}

async function persistOuiToken(token: Token | null) {
  ouiToken = token;
  await authStorage.setItem(USER_SESSION_TOKEN_STORAGE_KEY, token);
  startSessionTimeoutTimer();
}

let _callbacks: Array<(isLoggedIn: boolean) => void> = [];
export function onAuthStateChanged(callback: (isLoggedIn: boolean) => void) {
  _callbacks.push(callback);
  return () => {
    _callbacks = _callbacks.filter((c) => c !== callback);
  };
}

function emitAuthStateChanged(isLoggedIn: boolean) {
  _callbacks.map((cb) => cb(isLoggedIn));
}

async function clearStorage() {
  if (Platform.OS !== 'web') {
    await SecureStore.deleteItemAsync(PASSWORD_HASH_STORAGE_KEY);
    await SecureStore.deleteItemAsync('reauthPIN');
  }
  await apollo?.clearStore();
  return Promise.all([clearAll(), AsyncStorage.clear()]).catch((e) => {
    // if we can't clear it's not worth stopping the entire process
    // this specific error message happens when we try to clear async storage but there are no entries
    if (!e.toString().startsWith('Error: Failed to delete storage directory')) {
      Sentry.captureException(e);
    }
  });
}

export function getAuthToken(): string | null {
  return getOuiToken()?.value ?? null;
}

export async function setAuthTokenExpiresAt(newExpiry: string) {
  const token = getOuiToken();
  if (token) {
    await persistOuiToken({ ...token, expiresAt: newExpiry as GQLDateTime });
  }
}

export function getIsLoggedIn(): boolean {
  return !!getOuiToken();
}

async function getCurrentUserEmail(): Promise<{ email: string } | null> {
  if (!ouiToken) return null;
  const user = await apollo.query({ query: CurrentUserQuery, fetchPolicy: 'cache-first' });
  const email = user.data.currentUser?.user?.person.email;
  return email ? { email } : null;
}

export async function signOut() {
  addBreadcrumb({ category: 'auth', message: 'sign-out' });
  await clearStorage();

  ouiToken = null;
  clearTimeout(sessionTimeout);
  await authStorage.removeItem(USER_SESSION_TOKEN_STORAGE_KEY);
  setUser({ userID: null, email: null });
  emitAuthStateChanged(false);
}

export async function login(email: string, password: string) {
  // just in case some data from the last user was somehow still persisted, we proactively clear
  // storage before signing in a new user so there is no chance of showing a user the wrong user's data
  await clearStorage();
  addBreadcrumb({ category: 'auth', message: 'login' });

  const response = await apollo.mutate({ mutation: LoginMutation, variables: { email, password } });

  if (response.data?.loginWithEmail.__typename !== 'UserSession') {
    throw new Error(response.data?.loginWithEmail.message);
  }

  await persistOuiToken(
    response.data?.loginWithEmail.token
      ? omit(response.data?.loginWithEmail.token, '__typename')
      : null,
  );

  const user = response.data?.loginWithEmail.currentOuiUser;
  setUser({
    userID: user ? getUserIDForThirdParties(readFragment(CurrentOuiUserFragment, user)) : undefined,
    email: IS_PRODUCTION && APP_SLUG !== 'oui-aviva-staff' ? undefined : email,
  });

  emitAuthStateChanged(true);

  addBreadcrumb({ category: 'auth', message: 'login-success' });
  await hashAndStorePassword(password);
  await AsyncStorage.setItem('lastSeen', Date.now().toString());
}

export async function reauthenticate(email: string, password: string) {
  const user = await getCurrentUserEmail();
  if (user) {
    addBreadcrumb({ category: 'auth', message: 'reauthenticate' });
    const passwordHash =
      Platform.OS === 'web' ? null : await SecureStore.getItemAsync(PASSWORD_HASH_STORAGE_KEY);
    if (passwordHash && isCryptoAvailable()) {
      const candidateHash = await hashPassword(password);
      if (passwordHash !== candidateHash) {
        throw new Error('INVALID_PASSWORD');
      }
    } else {
      if (!email) {
        const response = await (getOuiToken()
          ? apollo.query({ query: CurrentUserQuery, fetchPolicy: 'cache-first' })
          : null);
        const user = response?.data.currentUser;
        email = user?.user?.person.email ?? '';
      }
      await apollo.mutate({ mutation: ReauthenticateMutation, variables: { email, password } });
    }
    await hashAndStorePassword(password);
    addBreadcrumb({ category: 'auth', message: 'reauthenticate-success' });
    await AsyncStorage.setItem('lastSeen', Date.now().toString());
  } else {
    addBreadcrumb({
      category: 'auth',
      message: 'reauthenticate called without current user',
      level: 'warning',
    });
    await login(email, password);
  }
}

export async function getIdTokenResult() {
  return getAuthToken();
}

import { ApolloError, useApolloClient } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StrictMode, useState } from 'react';
import { StatusBar } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Button } from '@oui/app-core/src/components/Button';
import { ErrorPresenter } from '@oui/app-core/src/components/ErrorPresenter';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useForm } from '@oui/app-core/src/hooks/useForm';
import { useWindowDimensions } from '@oui/app-core/src/hooks/useWindowDimensions';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { logEvent } from '@oui/app-core/src/lib/log';
import { graphql } from '@oui/lib/src/graphql/tada';

import FullLogo from '@src/assets/Full_logo.svg';
import WaitingForActivation from '@src/assets/Waiting_for_Activation.svg';
import { TextInput } from '@src/components/TextInput';
import { StackScreenProps } from '@src/types';

export const WelcomeQuery = graphql(`
  query Welcome($activationTokenValue: String!) {
    ouiUserEmailWithToken(value: $activationTokenValue)
  }
`);

function ClaimActivationTokenForm() {
  const navigation = useNavigation<StackScreenProps<'Welcome'>['navigation']>();
  const [error, setError] = useState('');
  const { validate, bind, data, humanErrors } = useForm<{
    activationTokenValue: string;
  }>({
    activationTokenValue: '',
  });
  const apollo = useApolloClient();
  const { $t } = useI18n();

  const request = () => {
    setError('');
    if (validate() && data.activationTokenValue) {
      logEvent('submit_claim_code');
      const token = data.activationTokenValue.toString();
      return apollo
        .query({ query: WelcomeQuery, variables: { activationTokenValue: token } })
        .then((result) => {
          if (result.data?.ouiUserEmailWithToken) {
            logEvent('submit_claim_code_success');
            const email = result.data.ouiUserEmailWithToken;
            navigation.navigate('SignUp', {
              signupTokenFromActivationCode: token,
              email: email.endsWith('@signup.oui.dev') ? undefined : email,
            });
          } else {
            logEvent('submit_claim_code_error');
            setError('Claim code not found');
          }
        })
        .catch((e: ApolloError) => {
          logEvent('submit_claim_code_error');
          if (
            e.message.includes('no rows') ||
            e.graphQLErrors?.find((gqlE) => gqlE.extensions?.message === 'UNAUTHORIZED')
          ) {
            setError('Claim code not found');
          }
        });
    }
    return;
  };

  const { error: formError, ...props } = bind('activationTokenValue', {
    label: $t({
      id: 'Welcome_activationTokenLabel',
      defaultMessage: 'Enter claim code',
    }),
    validator: { type: 'present' },
  });

  return (
    <View spacing={12} flex={1}>
      <ErrorPresenter errorString={error} formErrors={humanErrors} />
      <TextInput
        placeholder="********"
        error={formError ?? error}
        {...props}
        testID="Welcome_enterClaimCode"
        onSubmitEditing={() => void request()}
        inputMode="numeric"
      />
      <Button
        text={$t({ id: 'Welcome_submitClaimCodeButton', defaultMessage: 'Next' })}
        testID="Welcome_submitClaimCodeButton"
        onPress={request}
        alignSelf="center"
      />
    </View>
  );
}

export function Welcome() {
  const navigation = useNavigation<StackScreenProps<'Welcome'>['navigation']>();
  const { height } = useWindowDimensions();
  const { $t } = useI18n();
  const insets = useSafeAreaInsets();

  return (
    <View
      style={{
        marginTop: height < 600 ? 20 : 40,
        paddingTop: insets.top,
        paddingBottom: insets.bottom,
        flex: 1,
        alignItems: 'stretch',
      }}
    >
      <ScrollView
        style={{ flexGrow: 1 }}
        contentContainerStyle={{ flexGrow: 1 }}
        disableBottomSafeAreaInset
      >
        <StatusBar barStyle="dark-content" translucent />
        <View style={{ alignItems: 'center' }}>
          <FullLogo
            height={height / 5}
            aria-label={$t({
              id: 'Welcome_logoAccessibilityLabel',
              defaultMessage: 'Aviva Logo',
            })}
            role="img"
          />
        </View>
        <View flex={1} style={{}} spacing={12}>
          <View
            style={{ alignItems: 'center', position: 'absolute', bottom: 0, left: 0, right: 0 }}
          >
            <WaitingForActivation height={height / 4} aria-label={undefined} />
          </View>
          <Text
            text={$t({ id: 'Welcome_title', defaultMessage: 'Welcome to Aviva' })}
            testID="Welcome_title"
            size={height < 600 ? 24 : 32}
            weight="bold"
            style={{ textAlign: 'center' }}
          />
          <View style={{ marginHorizontal: 20 }}>
            <StrictMode>
              <ClaimActivationTokenForm />
            </StrictMode>
          </View>
        </View>
      </ScrollView>
      <View>
        <View
          style={{
            padding: 0,
            margin: 0,
            borderTopWidth: 1,
            alignSelf: 'stretch',
          }}
        >
          <View style={{ padding: 16, alignItems: 'center' }} spacing={8}>
            <Button
              onPress={() => navigation.navigate('Login', {})}
              text={$t({
                id: 'Welcome_navigateToLoginButton',
                defaultMessage: 'I already have an account',
              })}
              testID="Welcome_navigateToLoginButton"
              variant="text"
              alignSelf="center"
            />
          </View>
        </View>
      </View>
    </View>
  );
}

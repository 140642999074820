import { Component } from 'react';

import { ChatInputBaseProps, ChatInputSingleChoiceProps, Kind } from '@oui/lib/src/types';

import { Button } from '../components/Button';
import { Checkbox } from '../components/Checkbox';
import { View } from '../components/View';

type State = { checkedValue: string };

export default class ChatInputChoice extends Component<
  ChatInputBaseProps & ChatInputSingleChoiceProps & { legacyChoice?: boolean },
  State
> {
  static defaultProps = {
    label: {},
  };
  state: State = { checkedValue: '' };

  handleSubmit(value: string) {
    if (this.state.checkedValue) return;
    const { onInput } = this.props;
    this.setState({ checkedValue: value });
    if (onInput) {
      // Give the radio button a chance to show as checked before sending the event + hiding
      setTimeout(() => {
        if (this.props.legacyChoice) {
          onInput({ kind: Kind.InputChoice, props: [Number(value)] });
        } else {
          onInput({ kind: Kind.InputSingleChoice, props: Number(value) });
        }
      }, 500);
    }
  }

  render() {
    const { style } = this.props;
    const submit = (val: string) => () => this.handleSubmit(val);
    const numChoices = Object.entries(this.props.label).length;
    return (
      <View
        style={[
          {
            paddingTop: 8,
            paddingLeft: 16,
            paddingBottom: 8,
            paddingRight: 16,
          },
          style,
        ]}
      >
        <View
          spacing={8}
          row={this.props.variant === 'button'}
          style={
            this.props.variant === 'button' ? { justifyContent: 'center', flexWrap: 'wrap' } : null
          }
          aria-label={numChoices === 1 ? undefined : this.props.lastTextContent}
          role={numChoices === 1 ? 'none' : 'radiogroup'}
        >
          {Object.entries(this.props.label).map(([i, str]) => {
            const isChosen = this.state.checkedValue === i;
            return this.props.variant === 'button' ? (
              <Button
                testID={`ChatInputSingleChoice_${i}`}
                onPress={submit(i)}
                text={str}
                key={i}
                style={{ marginVertical: 4 }}
                variant={isChosen ? 'solid' : 'contained'}
              />
            ) : (
              <Checkbox
                testID={`ChatInputSingleChoice_${i}`}
                horizontal
                key={i}
                onChangeValue={submit(i)}
                label={str}
                variant="radio"
                value={isChosen}
              />
            );
          })}
        </View>
      </View>
    );
  }
}

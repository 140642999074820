import { useMutation, useQuery } from '@apollo/client';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';
import * as ScreenOrientation from 'expo-screen-orientation';
import { useCallback } from 'react';
import { Image, Platform, StyleSheet, TouchableOpacity } from 'react-native';

import { graphql } from '@oui/lib/src/graphql/tada';

import { useI18n } from '../../lib/i18n';
import { resumableUploadManager, SessionUri } from '../../lib/resumableUploadManager';
import Sentry from '../../sentry';
import { useTheme } from '../../styles';
import { Avatar } from '../Avatar/Avatar';
import { HeaderButtons, HeaderItem } from '../HeaderButtons';
import { Icon } from '../Icon';
import { Heading } from '../Text';
import { View } from '../View';

export const ProfileHeaderQuery = graphql(`
  query ProfileHeader {
    user {
      givenName
      familyName
      ID
    }
    avatar: asset(context: "user", key: "avatar")
  }
`);

export const ProfileHeaderMutation = graphql(`
  mutation ProfileHeader {
    uploadResumableAsset(
      input: { context: "user", key: "avatar", fileName: "avatar.jpeg", uploadType: IMAGE }
    ) {
      resumableUploadAssetURI
    }
  }
`);

export const ProfileHeader = () => {
  const { theme } = useTheme();
  const { $t } = useI18n();
  const { data, refetch } = useQuery(ProfileHeaderQuery);
  const [uploadAvatar] = useMutation(ProfileHeaderMutation);
  const { showActionSheetWithOptions } = useActionSheet();
  const user = data && data.user;
  const { navigate } = useNavigation();

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        void ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
        return () => void ScreenOrientation.unlockAsync();
      }

      return;
    }, []),
  );

  const changeProfilePhoto = () => {
    showActionSheetWithOptions(
      {
        options: [
          $t({ id: 'Profile_choosePhotoCancelOption', defaultMessage: 'Cancel' }),
          $t({
            id: 'Profile_choosePhotoGalleryOption',
            defaultMessage: 'Choose from gallery',
          }),
          $t({
            id: 'Profile_choosePhotoCameraOption',
            defaultMessage: 'Take a photo',
          }),
        ],
        cancelButtonIndex: 0,
      },
      async (buttonIndex) => {
        if (typeof buttonIndex !== 'number') return;
        if (buttonIndex > 0) {
          const useGallery = buttonIndex === 1;
          const [{ status }] = await Promise.all(
            useGallery
              ? [ImagePicker.requestMediaLibraryPermissionsAsync()]
              : [
                  ImagePicker.requestCameraPermissionsAsync(),
                  ImagePicker.requestMediaLibraryPermissionsAsync(),
                ],
          );

          if (status === 'granted') {
            let permissionsPromise;

            if (global.e2e) {
              permissionsPromise = Promise.resolve<ImagePicker.ImagePickerResult>({
                canceled: false,
                assets: [
                  {
                    uri:
                      Platform.OS === 'ios'
                        ? // For some reason iOS can't fetch picsum.photos, but android can
                          // Web can't fetch placedog.net b/c of a CORS issue
                          'https://placedog.net/500/500'
                        : 'https://picsum.photos/500',
                    width: 500,
                    height: 500,
                  },
                ],
              });
            } else {
              const options = {
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: false,
                quality: 1,
              };
              permissionsPromise = useGallery
                ? ImagePicker.launchImageLibraryAsync(options)
                : ImagePicker.launchCameraAsync(options);
            }

            try {
              const result = await permissionsPromise;
              if (!result.canceled) {
                const avatarResult = await uploadAvatar({
                  refetchQueries: [ProfileHeaderQuery],
                });
                const id = await resumableUploadManager.uploadFile(
                  result.assets![0].uri,
                  avatarResult.data?.uploadResumableAsset.resumableUploadAssetURI as SessionUri,
                );
                const remove = resumableUploadManager.addListener(id, ({ percent }) => {
                  if (percent === 100) {
                    void refetch();
                    remove();
                  }
                });
              }
            } catch (e) {
              Sentry.captureException(e);
            }
          }
        }
      },
    );
  };

  const getEditIcon = (label: boolean) => (
    <HeaderButtons>
      <HeaderItem
        title=""
        testID="Profile_settingsButton"
        aria-label={
          label
            ? $t({
                id: 'Profile_settingsButton',
                defaultMessage: 'Settings',
              })
            : ''
        }
        iconName="settings"
        color={theme.color.gray400}
        onPress={() => {
          navigate('AccountSettings', { isEditing: 'false' });
        }}
      />
    </HeaderButtons>
  );

  return (
    <View>
      <View
        style={{
          ...StyleSheet.absoluteFillObject,
          backgroundColor: theme.color.dark,
          top: 50,
        }}
        pointerEvents="box-none"
      />
      <View
        style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'flex-start' }}
      >
        <View style={{ opacity: 0 }} aria-hidden>
          {getEditIcon(false)}
        </View>
        <View
          style={{
            paddingBottom: 15,
            alignItems: 'center',
            gap: 10,
            flex: 1,
          }}
        >
          <TouchableOpacity
            testID="Profile_profilePhotoButton"
            aria-label={$t({
              id: 'Profile_profilePhotoButton',
              defaultMessage: 'Profile photo',
            })}
            style={{
              width: 100,
              height: 100,
            }}
            role="button"
            onPress={changeProfilePhoto}
          >
            <Icon
              name="camera"
              color={theme.color.primary100}
              style={{
                bottom: 12,
                left: 40,
                position: 'absolute',
                zIndex: 1,
              }}
            />

            {data?.avatar ? (
              <Image
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: 50,
                  backgroundColor: theme.color.accentTwo100,
                }}
                source={{ uri: data?.avatar }}
                testID="Profile_avatar"
              />
            ) : (
              <Avatar name={user ? `${user.givenName} ${user.familyName}` : ''} size={100} round />
            )}
          </TouchableOpacity>

          <Heading
            text={user ? `${user.givenName} ${user.familyName}` : ''}
            level={1}
            variant="light"
          />
        </View>
        {getEditIcon(true)}
      </View>
    </View>
  );
};

import { useRef, useState } from 'react';

import { ChatInputSliderProps } from '@oui/lib/src/types';

import { Slider } from '../components/Slider';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { useTheme } from '../styles';

type Props = ChatInputSliderProps;

export function SliderInput(
  props: Props & {
    'aria-label'?: string;
    testID?: string;
    onChangeValue: (value: number) => void;
    showMinMaxValues?: boolean;
  },
) {
  const { theme } = useTheme();
  const [currentValue, setCurrentValue] = useState(props.value || props.minValue);
  const sliderRef = useRef<React.ElementRef<typeof Slider>>(null);

  return (
    <>
      <View
        accessible={true}
        role="slider"
        aria-label={
          props.minLabel && props.maxLabel
            ? `${props['aria-label'] ?? ''} Slider from ${props.minLabel} to ${props.maxLabel}`
            : undefined
        }
        aria-valuemin={props.minValue}
        aria-valuemax={props.maxValue}
        aria-valuenow={currentValue}
        accessibilityActions={[
          { name: 'increment', label: 'increment' },
          { name: 'decrement', label: 'decrement' },
        ]}
        onAccessibilityAction={(event) => {
          switch (event.nativeEvent.actionName) {
            case 'increment': {
              const newValue = Math.min(currentValue + 1, props.maxValue);
              sliderRef.current?.setValue(newValue);
              setCurrentValue(newValue);
              props.onChangeValue(newValue);
              break;
            }
            case 'decrement': {
              const newValue = Math.max(currentValue - 1, props.minValue);
              sliderRef.current?.setValue(newValue);
              setCurrentValue(newValue);
              props.onChangeValue(newValue);
              break;
            }
          }
        }}
      >
        <View style={{ alignItems: 'center', marginBottom: 8 }}>
          <Text
            aria-live="polite"
            role={props.labels?.[currentValue] ? undefined : 'none'}
            aria-label={
              props.labels?.[currentValue]
                ? `Current value: ${props.labels?.[currentValue]}`
                : undefined
            }
            text={props.labels?.[currentValue] || ' '}
            weight="semibold"
            size={17}
            color={theme.color.dark}
          />
        </View>
        <Slider
          ref={sliderRef}
          testID={props.testID ?? 'ChatInputSlider_slider'}
          step={props.step}
          minimumValue={props.minValue}
          maximumValue={props.maxValue}
          value={currentValue}
          onValueChange={(val: number) => {
            setCurrentValue(val);
            props.onChangeValue(val);
          }}
        />
      </View>
      {props.showMinMaxValues || props.displayStyle === 'both' || props.displayStyle === 'value' ? (
        <View row style={{ justifyContent: 'space-between', marginTop: -4 }} aria-hidden>
          <Text text={props.minValue.toString()} size={15} color={theme.color.gray300} />
          <Text text={props.maxValue.toString()} size={15} color={theme.color.gray300} />
        </View>
      ) : null}
      {props.displayStyle === 'both' || props.displayStyle === 'label' ? (
        <View row style={{ justifyContent: 'space-between' }} aria-hidden>
          <Text text={props.minLabel || ' '} size={15} color={theme.color.gray300} />
          <Text text={props.maxLabel || ' '} size={15} color={theme.color.gray300} />
        </View>
      ) : null}
    </>
  );
}

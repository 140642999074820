import { useActionSheet } from '@expo/react-native-action-sheet';
import * as Sentry from '@sentry/core';
import * as ImagePicker from 'expo-image-picker';
import { LinearGradient } from 'expo-linear-gradient';
import { ComponentProps, memo, ReactNode } from 'react';
import { Image, Platform, StyleSheet } from 'react-native';

import { Button } from '../components/Button';
import { View } from '../components/View';
import { AccessibleInput } from '../hooks/useAccessibleInput';
import { useI18n } from '../lib/i18n';

const ImageLinearGradient = memo(() => (
  <LinearGradient
    colors={['transparent', 'rgba(0,0,0,0.5)']}
    style={[StyleSheet.absoluteFillObject]}
    start={[0, 0]}
    end={[0, 1]}
  />
));

let assetDetailsCache = new Map<string, ImagePicker.ImagePickerAsset>();

export function getImageInputAssetDetails(uri: string) {
  return assetDetailsCache.get(uri);
}

export function ImageInput({
  value,
  chooseImageLabel,
  changeImageLabel,
  onChangeValue,
  ...accessibleProps
}: {
  value: string;
  chooseImageLabel: string;
  changeImageLabel: string;
  onChangeValue: (value: string) => void;
} & Omit<ComponentProps<typeof AccessibleInput>, 'children' | 'label' | 'accessibilityLabel'> &
  (
    | {
        label?: string;
        'aria-label'?: string;
      }
    | {
        label?: () => ReactNode;
        'aria-label': string;
      }
  )) {
  const { showActionSheetWithOptions } = useActionSheet();
  const { $t } = useI18n();

  const choosePhoto = () => {
    return showActionSheetWithOptions(
      {
        options: [
          $t({ id: 'ImageInput_choosePhotoCancelOption', defaultMessage: 'Cancel' }),
          $t({
            id: 'ImageInput_choosePhotoGalleryOption',
            defaultMessage: 'Choose from gallery',
          }),
          $t({
            id: 'ImageInput_choosePhotoCameraOption',
            defaultMessage: 'Take a photo',
          }),
        ],
        cancelButtonIndex: 0,
      },
      async (buttonIndex) => {
        if (typeof buttonIndex !== 'number') return;
        if (buttonIndex > 0) {
          const useGallery = buttonIndex === 1;
          const [{ status }] = await Promise.all(
            useGallery
              ? [ImagePicker.requestMediaLibraryPermissionsAsync()]
              : [
                  ImagePicker.requestCameraPermissionsAsync(),
                  ImagePicker.requestMediaLibraryPermissionsAsync(),
                ],
          );

          if (status === 'granted') {
            const options = {
              mediaTypes: ImagePicker.MediaTypeOptions.Images,
              allowsEditing: false,
              quality: 1,
            };
            const promise = global.e2e
              ? Promise.resolve<ImagePicker.ImagePickerResult>({
                  canceled: false,
                  assets: [
                    {
                      uri:
                        Platform.OS === 'ios'
                          ? // For some reason iOS can't fetch picsum.photos, but android can
                            // Web can't fetch placedog.net b/c of a CORS issue
                            'https://placedog.net/500/500'
                          : 'https://picsum.photos/500',
                      width: 500,
                      height: 500,
                    },
                  ],
                })
              : useGallery
                ? ImagePicker.launchImageLibraryAsync(options)
                : ImagePicker.launchCameraAsync(options);

            promise
              .then((result) => {
                if (!result.canceled) {
                  assetDetailsCache.set(result.assets[0].uri, result.assets[0]);
                  onChangeValue(result.assets[0].uri);
                }
              })
              .catch(Sentry.captureException);
          }
        }
      },
    );
  };

  return (
    <AccessibleInput {...accessibleProps}>
      {(_accessibilityProps) => (
        <View style={{ overflow: 'hidden', borderRadius: 10 }}>
          {value ? (
            <>
              <Image
                source={{ uri: value }}
                style={{ aspectRatio: 1, alignSelf: 'stretch', borderRadius: 10 }}
              />
              <View
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                  padding: 10,
                }}
              >
                <ImageLinearGradient />
                <View
                  row
                  style={{
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    icon="upload"
                    text={changeImageLabel || 'Replace'}
                    variant="text"
                    color="white"
                    onPress={choosePhoto}
                    size="small"
                  />
                  <Button
                    icon="bin"
                    text="Delete"
                    variant="text"
                    color="white"
                    onPress={() => onChangeValue(null!)}
                    size="small"
                  />
                </View>
              </View>
            </>
          ) : (
            <Button text={chooseImageLabel || 'Choose'} icon="plus" onPress={choosePhoto} />
          )}
        </View>
      )}
    </AccessibleInput>
  );
}

/*
 * This utility is a clever but hacky hack. In react-native we use imported SVGs to
 * render icons. We can directly set the SVG's color so that the icon color can be set via
 * react props. However, on (nextjs) web, we load the svgs via <img> which doesn't allow for setting
 * currentColor. As a workaround, we compute a css filter that approximates the desired color value
 *
 * Taken from https://stackoverflow.com/a/64844454/906283
 */
import ColorParser from 'color';

function parseColorToRgb(input: string) {
  const colorInstance = new ColorParser(input);

  return new RgbColor(colorInstance.red(), colorInstance.green(), colorInstance.blue());
}

function clampRgbPart(value: number): number {
  if (value > 255) {
    return 255;
  }

  if (value < 0) {
    return 0;
  }

  return value;
}

class RgbColor {
  constructor(
    public red: number,
    public green: number,
    public blue: number,
  ) {}

  toString() {
    return `rgb(${Math.round(this.red)}, ${Math.round(this.green)}, ${Math.round(this.blue)})`;
  }

  set(r: number, g: number, b: number) {
    this.red = clampRgbPart(r);
    this.green = clampRgbPart(g);
    this.blue = clampRgbPart(b);
  }

  hueRotate(angle = 0) {
    angle = (angle / 180) * Math.PI;
    const sin = Math.sin(angle);
    const cos = Math.cos(angle);

    this.multiply([
      0.213 + cos * 0.787 - sin * 0.213,
      0.715 - cos * 0.715 - sin * 0.715,
      0.072 - cos * 0.072 + sin * 0.928,
      0.213 - cos * 0.213 + sin * 0.143,
      0.715 + cos * 0.285 + sin * 0.14,
      0.072 - cos * 0.072 - sin * 0.283,
      0.213 - cos * 0.213 - sin * 0.787,
      0.715 - cos * 0.715 + sin * 0.715,
      0.072 + cos * 0.928 + sin * 0.072,
    ]);
  }

  grayscale(value = 1) {
    this.multiply([
      0.2126 + 0.7874 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 + 0.2848 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 + 0.9278 * (1 - value),
    ]);
  }

  sepia(value = 1) {
    this.multiply([
      0.393 + 0.607 * (1 - value),
      0.769 - 0.769 * (1 - value),
      0.189 - 0.189 * (1 - value),
      0.349 - 0.349 * (1 - value),
      0.686 + 0.314 * (1 - value),
      0.168 - 0.168 * (1 - value),
      0.272 - 0.272 * (1 - value),
      0.534 - 0.534 * (1 - value),
      0.131 + 0.869 * (1 - value),
    ]);
  }

  saturate(value = 1) {
    this.multiply([
      0.213 + 0.787 * value,
      0.715 - 0.715 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 + 0.285 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 - 0.715 * value,
      0.072 + 0.928 * value,
    ]);
  }

  multiply(matrix: number[]) {
    const newR = clampRgbPart(
      this.red * matrix[0] + this.green * matrix[1] + this.blue * matrix[2],
    );
    const newG = clampRgbPart(
      this.red * matrix[3] + this.green * matrix[4] + this.blue * matrix[5],
    );
    const newB = clampRgbPart(
      this.red * matrix[6] + this.green * matrix[7] + this.blue * matrix[8],
    );
    this.red = newR;
    this.green = newG;
    this.blue = newB;
  }

  brightness(value = 1) {
    this.linear(value);
  }

  contrast(value = 1) {
    this.linear(value, -(0.5 * value) + 0.5);
  }

  linear(slope = 1, intercept = 0) {
    this.red = clampRgbPart(this.red * slope + intercept * 255);
    this.green = clampRgbPart(this.green * slope + intercept * 255);
    this.blue = clampRgbPart(this.blue * slope + intercept * 255);
  }

  invert(value = 1) {
    this.red = clampRgbPart((value + (this.red / 255) * (1 - 2 * value)) * 255);
    this.green = clampRgbPart((value + (this.green / 255) * (1 - 2 * value)) * 255);
    this.blue = clampRgbPart((value + (this.blue / 255) * (1 - 2 * value)) * 255);
  }

  applyFilters(filters: Filters) {
    this.set(0, 0, 0);
    this.invert(filters[0] / 100);
    this.sepia(filters[1] / 100);
    this.saturate(filters[2] / 100);
    this.hueRotate(filters[3] * 3.6);
    this.brightness(filters[4] / 100);
    this.contrast(filters[5] / 100);
  }

  hsl(): HSLData {
    // Code taken from https://stackoverflow.com/a/9493060/2688027, licensed under CC BY-SA.
    const r = this.red / 255;
    const g = this.green / 255;
    const b = this.blue / 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h: number,
      s: number,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;

        case g:
          h = (b - r) / d + 2;
          break;

        case b:
          h = (r - g) / d + 4;
          break;
      }
      h! /= 6;
    }

    return {
      h: h! * 100,
      s: s * 100,
      l: l * 100,
    };
  }
}

interface HSLData {
  h: number;
  s: number;
  l: number;
}

interface ColorFilterSolveResult {
  loss: number;
  filters: Filters;
}

const reusedColor = new RgbColor(0, 0, 0);

function formatFilterValue(value: number, multiplier = 1) {
  return Math.round(value * multiplier);
}

type Filters = [
  invert: number,
  sepia: number,
  saturate: number,
  hueRotate: number,
  brightness: number,
  contrast: number,
];

function convertFiltersListToCSSFilter(filters: Filters) {
  // function fmt(idx: number, multiplier = 1) {
  //   return Math.round(filters[idx] * multiplier);
  // }
  const [invert, sepia, saturate, hueRotate, brightness, contrast] = filters;
  return `invert(${formatFilterValue(invert)}%) sepia(${formatFilterValue(
    sepia,
  )}%) saturate(${formatFilterValue(saturate)}%) hue-rotate(${formatFilterValue(
    hueRotate,
    3.6,
  )}deg) brightness(${formatFilterValue(brightness)}%) contrast(${formatFilterValue(contrast)}%)`;
}

function calculateLossForFilters(filters: Filters, targetColor: RgbColor, targetHSL: HSLData) {
  reusedColor.applyFilters(filters);
  const actualHSL = reusedColor.hsl();

  return (
    Math.abs(reusedColor.red - targetColor.red) +
    Math.abs(reusedColor.green - targetColor.green) +
    Math.abs(reusedColor.blue - targetColor.blue) +
    Math.abs(actualHSL.h - targetHSL.h) +
    Math.abs(actualHSL.s - targetHSL.s) +
    Math.abs(actualHSL.l - targetHSL.l)
  );
}

const _cache: Record<string, string | undefined> = {};

export function solveColor(input: string) {
  if (_cache[input]) return _cache[input];

  const targetColor = parseColorToRgb(input);
  const targetHSL = targetColor.hsl();

  function improveInitialSolveResult(initialResult: ColorFilterSolveResult) {
    const A = initialResult.loss;
    const c = 2;
    const A1 = A + 1;
    const a: Filters = [0.25 * A1, 0.25 * A1, A1, 0.25 * A1, 0.2 * A1, 0.2 * A1];
    return findColorFilters(A, a, c, initialResult.filters, 500);
  }

  function findColorFilters(
    initialLoss: number,
    filters: Filters,
    c: number,
    values: Filters,
    iterationsCount: number,
  ): ColorFilterSolveResult {
    const alpha = 1;
    const gamma = 0.16666666666666666;

    let best = null;
    let bestLoss = Infinity;
    const deltas = new Array(6);
    const highArgs = new Array(6) as Filters;
    const lowArgs = new Array(6) as Filters;

    for (let iterationIndex = 0; iterationIndex < iterationsCount; iterationIndex++) {
      const ck = c / Math.pow(iterationIndex + 1, gamma);
      for (let i = 0; i < 6; i++) {
        deltas[i] = Math.random() > 0.5 ? 1 : -1;
        highArgs[i] = values[i] + ck * deltas[i];
        lowArgs[i] = values[i] - ck * deltas[i];
      }

      const lossDiff =
        calculateLossForFilters(highArgs, targetColor, targetHSL) -
        calculateLossForFilters(lowArgs, targetColor, targetHSL);

      for (let i = 0; i < 6; i++) {
        const g = (lossDiff / (2 * ck)) * deltas[i];
        const ak = filters[i] / Math.pow(initialLoss + iterationIndex + 1, alpha);
        values[i] = fix(values[i] - ak * g, i);
      }

      const loss = calculateLossForFilters(values, targetColor, targetHSL);
      if (loss < bestLoss) {
        best = values.slice(0) as Filters;
        bestLoss = loss;
      }
    }
    return { filters: best!, loss: bestLoss };

    function fix(value: number, idx: number) {
      let max = 100;
      if (idx === 2 /* saturate */) {
        max = 7500;
      } else if (idx === 4 /* brightness */ || idx === 5 /* contrast */) {
        max = 200;
      }

      if (idx === 3 /* hue-rotate */) {
        if (value > max) {
          value %= max;
        } else if (value < 0) {
          value = max + (value % max);
        }
      } else if (value < 0) {
        value = 0;
      } else if (value > max) {
        value = max;
      }
      return value;
    }
  }

  function solveInitial(): ColorFilterSolveResult {
    const A = 5;
    const c = 15;
    const a: Filters = [60, 180, 18000, 600, 1.2, 1.2];

    let best: ColorFilterSolveResult = {
      loss: Infinity,
      filters: [0, 0, 0, 0, 0, 0],
    };
    for (let i = 0; best.loss > 25 && i < 3; i++) {
      const initial: Filters = [50, 20, 3750, 50, 100, 100];
      const result = findColorFilters(A, a, c, initial, 1000);
      if (result.loss < best.loss) {
        best = result;
      }
    }
    return best;
  }

  const result = improveInitialSolveResult(solveInitial());

  const finalResult = convertFiltersListToCSSFilter(result.filters);
  _cache[finalResult] = finalResult;
  return finalResult;
}

import { Icon, registerIcons } from '@oui/app-core/src/components/Icon';

import riskCurveFalling from '../assets/icons/risk-curve-falling.svg';
import riskCurvePeak from '../assets/icons/risk-curve-peak.svg';
import riskCurveRising from '../assets/icons/risk-curve-rising.svg';

const CUSTOM_ICONS = {
  'risk-curve-falling': riskCurveFalling,
  'risk-curve-peak': riskCurvePeak,
  'risk-curve-rising': riskCurveRising,
};

type CustomIconsType = typeof CUSTOM_ICONS;

declare global {
  // eslint-disable-next-line
  namespace AppCore {
    // eslint-disable-next-line
    interface IconGlyphs extends CustomIconsType {}
  }
}

registerIcons(CUSTOM_ICONS);

export { Icon };

import { Icon, registerIcons } from '@oui/app-core/src/components/Icon';

import caretFilledDown from '@src/assets/icons/caret-filled-down.svg';
import caretFilledRight from '@src/assets/icons/caret-filled-right.svg';
import childOrganization from '@src/assets/icons/child-organization.svg';
import arrowConnect from '@src/assets/icons/cms/arrow-connect.svg';
import button from '@src/assets/icons/cms/button.svg';
import check from '@src/assets/icons/cms/check.svg';
import choice from '@src/assets/icons/cms/choice.svg';
import cloudUpload from '@src/assets/icons/cms/cloud-upload.svg';
import exchange from '@src/assets/icons/cms/exchange.svg';
import image from '@src/assets/icons/cms/image.svg';
import input from '@src/assets/icons/cms/input.svg';
import mic from '@src/assets/icons/cms/mic.svg';
import png from '@src/assets/icons/cms/PNG.svg';
import popOutMenuIndicator from '@src/assets/icons/cms/pop-out-menu-indicator.svg';
import quizCard from '@src/assets/icons/cms/quiz-card.svg';
import section from '@src/assets/icons/cms/section.svg';
import slider from '@src/assets/icons/cms/slider.svg';
import specializedExchange from '@src/assets/icons/cms/specialized-exchange.svg';
import textCard from '@src/assets/icons/cms/text-card.svg';
import textPage from '@src/assets/icons/cms/text-page.svg';
import text from '@src/assets/icons/cms/text.svg';
import upload from '@src/assets/icons/cms/upload.svg';
import videoCard from '@src/assets/icons/cms/video-card.svg';
import videoFile from '@src/assets/icons/cms/video-file.svg';
import video from '@src/assets/icons/cms/video.svg';
import widget from '@src/assets/icons/cms/widget.svg';
import expandedLine from '@src/assets/icons/expanded-line.svg';
import moon from '@src/assets/icons/moon.svg';
import parentOrganization from '@src/assets/icons/parent-organization.svg';
import repeat from '@src/assets/icons/repeat.svg';
import say from '@src/assets/icons/say.svg';
import troubleshoot from '@src/assets/icons/troubleshoot.svg';
import spotIt from '@src/assets/thoughtDiary/spotIt.svg';
import switchIt from '@src/assets/thoughtDiary/switchIt.svg';
import testIt from '@src/assets/thoughtDiary/testIt.svg';

const CUSTOM_ICONS = {
  'cms-arrow-connect': arrowConnect,
  'parent-organization': parentOrganization,
  'cms-button': button,
  'caret-filled-down': caretFilledDown,
  'caret-filled-right': caretFilledRight,
  'cms-check': check,
  'cms-choice': choice,
  'cms-cloud-upload': cloudUpload,
  'cms-exchange': exchange,
  'expanded-line': expandedLine,
  'cms-image': image,
  'cms-input': input,
  'cms-mic': mic,
  moon,
  'cms-png': png,
  'cms-pop-out-menu-indicator': popOutMenuIndicator,
  'cms-quiz-card': quizCard,
  repeat,
  say,
  'cms-section': section,
  'cms-slider': slider,
  'cms-specialized-exchange': specializedExchange,
  'spot-it': spotIt,
  'child-organization': childOrganization,
  'switch-it': switchIt,
  'test-it': testIt,
  'cms-text': text,
  'cms-text-page': textPage,
  'cms-text-card': textCard,
  troubleshoot,
  'cms-upload': upload,
  'cms-video': video,
  'cms-video-card': videoCard,
  'cms-video-file': videoFile,
  'cms-widget': widget,
};
type CustomIconsType = typeof CUSTOM_ICONS;

declare global {
  // eslint-disable-next-line
  namespace AppCore {
    // eslint-disable-next-line
    interface IconGlyphs extends CustomIconsType {}
  }
}

registerIcons(CUSTOM_ICONS);

export { Icon };

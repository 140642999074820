import { useNavigation } from '@react-navigation/core';

import { useMyStoryMyPlanCompositionSections } from '@oui/app-core/src/hooks/useComposition';
import { MyPlanTab } from '@oui/myplan/src/screens/MyPlanTab';

import { TabScreenProps } from '@src/types';

export default function CRP() {
  const navigation = useNavigation<TabScreenProps<'Aid'>['navigation']>();
  const { loading, data, refetch } = useMyStoryMyPlanCompositionSections();
  return (
    <MyPlanTab
      loading={loading}
      data={data}
      refetch={refetch}
      onEdit={() => navigation.navigate('EditMyPlan', {})}
    />
  );
}

import { useApolloClient, useMutation } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Linking, Platform, TextInput as RNTextInput } from 'react-native';

import { graphql } from '@oui/lib/src/graphql/tada';

import { AuthScreenContainer } from '../components/AuthScreenContainer';
import { Button } from '../components/Button';
import { ErrorPresenter } from '../components/ErrorPresenter';
import { Link } from '../components/Link';
import { Text } from '../components/Text';
import { EmailInput, TextInput } from '../components/TextInput';
import { View } from '../components/View';
import { manifest } from '../constants';
import { useForm } from '../hooks/useForm';
import { login } from '../lib/auth';
import { useI18n } from '../lib/i18n';
import { firebaseErrors, isFirebaseErrorKey } from '../lib/isFirebaseErrorKey';
import { logEvent } from '../lib/log';
import { setDeviceInfo } from '../lib/setDeviceInfo';
import { useTheme } from '../styles';
import { AuthScreenProps } from '../types/navigation';

export const ClaimSignupTokenFromActivationCodeMutation = graphql(`
  mutation ClaimSignupTokenFromActivationCode($token: String!, $password: String!, $email: String) {
    updateOuiUserWithToken(value: $token, input: { email: $email, password: $password }) {
      username
    }
  }
`);

/**
 * SignUp a new User
 */
export default function SignUp(props: AuthScreenProps<'SignUp'>) {
  const { theme } = useTheme();
  const { signupTokenFromActivationCode, email } = props.route.params ?? {};
  const { bind, data, validate, humanErrors } = useForm<{
    email: string;
    password: string;
    passwordConfirmation: string;
  }>({
    email: email ?? '',
    password: '',
    passwordConfirmation: '',
  });
  const [claimSignupTokenFromActivationCode] = useMutation(
    ClaimSignupTokenFromActivationCodeMutation,
  );
  const emailInputRef = useRef<RNTextInput>(null);
  const passwordInputRef = useRef<RNTextInput>(null);
  const confirmationInputRef = useRef<RNTextInput>(null);
  const { $t } = useI18n();
  const apollo = useApolloClient();
  const passwordSetSuccessfullyRef = useRef(false);

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const replace = props.navigation.replace;
  useEffect(() => {
    if (Platform.OS === 'web' && !signupTokenFromActivationCode) {
      replace('Login', {});
    }
  }, [signupTokenFromActivationCode, replace]);

  const request = () => {
    if (!validate()) return;
    if (data.password !== data.passwordConfirmation) {
      setError('Your password and password confirmation do not match');
      return;
    }

    if (signupTokenFromActivationCode) {
      setIsLoading(true);
      logEvent('claim_account');
      const promise: Promise<unknown> =
        passwordSetSuccessfullyRef.current === true
          ? Promise.resolve()
          : claimSignupTokenFromActivationCode({
              variables: {
                token: signupTokenFromActivationCode,
                password: data.password,
                email: data.email,
              },
            });

      return promise
        .then(() => (passwordSetSuccessfullyRef.current = true))
        .then(() => login(data.email, data.password))
        .then(() => {
          // dont need to wait for this, can be async... best effort reporting of device info
          void setDeviceInfo(apollo);
        })
        .catch((e) => {
          const gqlErrors = e.graphQLErrors;
          let message = e.message;
          if (gqlErrors?.[0].message) {
            setError(gqlErrors?.[0].message);
          } else if (isFirebaseErrorKey(message)) {
            setError($t(firebaseErrors[message]));
          } else {
            setError(message);
          }
          setIsLoading(false);
        });
    }

    return;
  };

  return (
    <AuthScreenContainer
      testID="SignUp"
      heading={$t({ id: 'Signup_title', defaultMessage: 'Create your account' })}
    >
      <View style={{ paddingHorizontal: 14, flex: 1, marginTop: 20 }}>
        {signupTokenFromActivationCode ? (
          <View spacing={10}>
            <ErrorPresenter errorString={error} formErrors={humanErrors} />
            {signupTokenFromActivationCode ? (
              <EmailInput
                {...bind('email', {
                  validator: { type: 'email' },
                  label: $t({ id: 'Signup_emailLabel', defaultMessage: 'Email' }),
                })}
                ref={emailInputRef}
                onSubmitEditing={() => passwordInputRef.current?.focus()}
                enterKeyHint="next"
                testID="Signup_emailInput"
              />
            ) : null}
            <View>
              <TextInput
                secureTextEntry={true}
                autoComplete="new-password"
                placeholder={$t({ id: 'Signup_passwordPlaceholder', defaultMessage: 'Password' })}
                {...bind('password', {
                  label: $t({ id: 'Signup_passwordLabel', defaultMessage: 'Set a password' }),
                  validator: [{ type: 'present' }, { type: 'length', minimum: 8 }],
                })}
                hint={$t({
                  id: 'Signup_passwordHint',
                  defaultMessage: 'Must be at least 8 characters',
                })}
                ref={passwordInputRef}
                onSubmitEditing={() => confirmationInputRef.current?.focus()}
                enterKeyHint="next"
                testID="Signup_passwordInput"
              />
            </View>
            <View>
              <TextInput
                secureTextEntry={true}
                placeholder={$t({
                  id: 'Signup_confirmPasswordPlaceholder',
                  defaultMessage: 'Confirm password',
                })}
                {...bind('passwordConfirmation', {
                  label: $t({
                    id: 'Signup_confirmPasswordLabel',
                    defaultMessage: 'Confirm password',
                  }),
                })}
                enterKeyHint="done"
                ref={confirmationInputRef}
                onSubmitEditing={() => void request()}
                testID="Signup_passwordConfirmationInput"
              />
            </View>
            {error ? <Text text={error} color={theme.color.danger} /> : null}
            <View row style={{ justifyContent: 'center', marginBottom: 12 }}>
              <Link
                textAlign="center"
                text={
                  <FormattedMessage
                    id="Signup_termsOfService"
                    defaultMessage="By creating an account you agree to the {appName} <link>Terms of Service & Privacy Policy</link>."
                    values={{
                      appName: manifest.name,
                      link: (chunks: string[]) => (
                        <Link
                          text={chunks.join('')}
                          onPress={() => {
                            void Linking.openURL('https://about.oui.health/terms-and-privacy');
                          }}
                        />
                      ),
                    }}
                  />
                }
              />
            </View>
            <Button
              testID="Signup_loginButton"
              onPress={request}
              disabled={!data.password || !data.passwordConfirmation || isLoading}
              alignSelf="flex-end"
              style={{ paddingHorizontal: 16 }}
              text={$t({ id: 'Signup_loginButton', defaultMessage: 'Login' })}
              iconRight="arrow-right"
            />
          </View>
        ) : null}
      </View>
    </AuthScreenContainer>
  );
}

import AsyncStorage from '@react-native-async-storage/async-storage';
import { PermissionResponse, PermissionStatus } from 'expo-modules-core';
// import * as Permissions from 'expo-permissions';
import { ReactNode, useState } from 'react';
import { Linking } from 'react-native';

import { ConfirmationModal } from '../components/ConfirmationModal';
import { manifest } from '../constants';
import { useAppState } from '../hooks/useAppState';
import { addBreadcrumb } from '../lib/log';

type SettingsCallbacks = {
  onOpenSettings?: () => Promise<unknown>;
  onCloseSettings?: () => Promise<unknown>;
};

function PermissionDeniedRecoveryModal({
  permission,
  factory,
  response,
  onFinalResponse,
  onOpenSettings,
  onCloseSettings,
}: DeniedContextType & SettingsCallbacks) {
  const canAskAgain = response.canAskAgain;

  useAppState(async (appState) => {
    if (appState === 'active') {
      await onCloseSettings?.();
      const result = await factory();
      onFinalResponse(result);
    }
  });

  return (
    <ConfirmationModal
      visible={true}
      title={`${permission} permission needed`}
      confirmText={canAskAgain ? 'Ask me again' : 'Go to settings'}
      confirmTestID="PermissionDeniedRecoveryModal_goToSettingsButton"
      cancelText="Deny"
      cancelTestID="PermissionDeniedRecoveryModal_denyButton"
      description={
        canAskAgain
          ? `You've previously denied this permission. To use this feature, please allow ${manifest.name} access.`
          : `It looks like you've previously denied this permission.\n\nIn order to use this feature, please enable the permission on the settings screens and return to ${manifest.name} by clicking "back" at the top of the screen.`
      }
      onCancel={() => onFinalResponse(response)}
      onConfirm={async () => {
        if (canAskAgain) {
          const newResult = await askAsync(permission, factory, { retryIfDenied: false });
          onFinalResponse(newResult);
        } else {
          await onOpenSettings?.();
          addBreadcrumb({
            category: 'permissions',
            message: 'open-native-settings',
            data: { permission },
          });
          await Linking.openSettings();
        }
      }}
    />
  );
}

type AcceptedContextType = {
  permission: undefined;
  response?: never;
  onFinalResponse?: never;
};
type DeniedContextType = {
  permission: string;
  factory: () => Promise<PermissionResponse>;
  response: PermissionResponse;
  onFinalResponse: (result: PermissionResponse) => void;
};
type ContextType = AcceptedContextType | DeniedContextType;
const IntitialContext = { permission: undefined };

let setContext: (obj: ContextType) => void;

export async function askAsync<T extends PermissionResponse>(
  permission: string,
  factory: () => Promise<T>,
  { retryIfDenied = false }: { retryIfDenied?: boolean },
): Promise<T> {
  global.pauseAppState = true;
  // since AppState is paused, createSessionTimeoutNavigator doesn't get notified of the inactive
  // state when opening up the system modal and therefore doesn't update lastSeen, when coming back
  // due to the async nature of restoring appState, we may restore pauseAppState before checking
  // lastSeen for purposes of triggering reauthentication
  // https://github.com/ouihealth/oui/issues/917
  await AsyncStorage.setItem('lastSeen', Date.now().toString());
  const result = await factory();
  global.pauseAppState = false;

  addBreadcrumb({ category: 'permissions', message: 'ask', data: { permission, result } });

  if (result.status === PermissionStatus.DENIED && retryIfDenied) {
    return new Promise<T>((resolve) => {
      setContext({
        permission,
        factory,
        response: result,
        onFinalResponse: (final) => {
          addBreadcrumb({
            category: 'permissions',
            message: 'final-response',
            data: { permission, result: final },
          });
          setContext({ permission: undefined });
          resolve(final as T);
        },
      });
    });
  }
  return result;
}

export function PermissionsManagerProvider({
  children,
  onOpenSettings,
  onCloseSettings,
}: {
  children: ReactNode;
} & SettingsCallbacks) {
  let context;
  [context, setContext] = useState<ContextType>(IntitialContext);

  return (
    <>
      {children}
      {context.permission ? (
        <PermissionDeniedRecoveryModal
          {...(context as DeniedContextType)}
          onOpenSettings={onOpenSettings}
          onCloseSettings={onCloseSettings}
        />
      ) : null}
    </>
  );
}

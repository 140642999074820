import {
  ApolloError,
  OperationVariables,
  QueryRef,
  TypedDocumentNode,
  useMutation,
  useQuery,
  useReadQuery,
  useSuspenseQuery,
} from '@apollo/client';
import * as Sentry from '@sentry/core';
import { ComponentProps, useMemo } from 'react';
import { FormProvider, SubmitHandler, UseFormProps, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';

import { flattenErrorMessages } from '@oui/lib/src/flattenErrorMessages';
import { useZodForm } from '@oui/lib/src/form';

import { Button } from './components/Button/Button';
import { ErrorPresenter } from './components/ErrorPresenter';
import { Icon } from './components/Icon';
import { View } from './components/View';

type FormButtonProps = {
  text: string;
  icon?: ComponentProps<typeof Icon>['name'];
  onPress: ComponentProps<typeof Icon>['onPress'];
  size?: ComponentProps<typeof Button>['size'];
  variant?: ComponentProps<typeof Button>['variant'];
};

type Buttons = {
  hidden?: boolean;
  cancel?: FormButtonProps;
  submit: Omit<FormButtonProps, 'onPress'>;
  skip?: FormButtonProps;
};

export type FormProps<
  QueryVariables extends OperationVariables | undefined,
  QueryResult,
  MutationVariables,
  MutationResult,
  Schema extends z.Schema,
  CallbackData = UseFormReturn<z.infer<Schema>> & { data?: QueryResult },
> = {
  testID?: string;
  /**
   * A graphql query to execute when the form is mounted.
   */
  query?: TypedDocumentNode<QueryResult, QueryVariables>;
  queryRef?: QueryRef<QueryResult>;
  suspense?: boolean;

  /**
   * Variables to pass to the query.
   */
  variables?: QueryVariables;

  /**
   * A graphql mutation to execute when the form is submitted.
   */
  mutate: TypedDocumentNode<MutationResult, MutationVariables>;

  /**
   * A Zod schema to validate the form data against.
   */
  schema: Schema;

  /**
   * Initialize form state with query response data
   */
  getDefaultValues: (
    data: QueryResult | undefined,
  ) => UseFormProps<z.infer<Schema>>['defaultValues'];

  /**
   * If the Schema and mutation are of different schema shapes, this function
   * can be used to transform the form data before sending it to the operation
   */
  prepareVariables?: (data: z.output<Schema>) => MutationVariables;

  buttons?: Buttons | ((props: CallbackData) => Buttons);

  /**
   * Called after onSubmit with formData and mutationResult.
   */
  onSave?: (
    formData: z.infer<Schema>,
    mutationResult?: MutationResult,
  ) => unknown | Promise<unknown>;

  /**
   * Called after onSubmit with formData and mutationResult if an success response was received in GQL data.
   */
  onSuccess?: (
    formData: z.infer<Schema>,
    mutationResult: {
      [key in keyof MutationResult]: Exclude<
        MutationResult[key],
        { __typename?: `${string}Error` }
      >;
    },
  ) => unknown | Promise<unknown>;
  /**
   * Called after onSubmit with formData and mutationResult if an error response was received in GQL data.
   */
  onError?: (
    formData: z.infer<Schema>,
    mutationResult?:
      | {
          [key in keyof MutationResult]: Extract<
            MutationResult[key],
            { __typename?: `${string}Error` }
          >;
        }
      | Error,
  ) => unknown | Promise<unknown>;
} & {
  /**
   * Child elements to render inside the form.
   */
  children: (props: CallbackData) => React.ReactNode;
  errorChildren?: (
    error:
      | ApolloError
      | {
          [key in keyof MutationResult]: Extract<
            MutationResult[key],
            { __typename?: `${string}Error` }
          >;
        }[keyof MutationResult],
  ) => React.ReactNode;
};

const ErrorResponseSchema = z.discriminatedUnion('__typename', [
  z.object({
    __typename: z.literal('BaseError'),
    message: z.string(),
  }),
  z.object({
    __typename: z.literal('SimpleError'),
    message: z.string(),
  }),
  z.object({
    __typename: z.literal('ValidationError'),
    message: z.string(),
    fieldErrors: z.array(z.object({ path: z.array(z.string()), message: z.string() })),
  }),
]);

const SomeErrorSchema = z.object({
  __typename: z.string().refine((s) => s.endsWith('Error')),
});

export function Form<
  QueryVariables extends OperationVariables | undefined,
  QueryResult,
  MutationVariables,
  MutationResult,
  Schema extends z.Schema,
>({
  testID,
  children,
  query,
  queryRef,
  variables,
  mutate,
  schema,
  getDefaultValues,
  prepareVariables,
  buttons,
  onSuccess,
  onError,
  onSave,
  errorChildren,
  suspense,
}: FormProps<QueryVariables, QueryResult, MutationVariables, MutationResult, Schema>) {
  const { data } = queryRef
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useReadQuery(queryRef)
    : query
      ? suspense
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
          useSuspenseQuery(query, { variables: variables })
        : // eslint-disable-next-line react-hooks/rules-of-hooks
          useQuery(query, { variables: variables })
      : { data: undefined };
  const [mutation, { error: gqlError, data: mutationResult }] = useMutation(mutate);

  const methods = useZodForm(schema, {
    defaultValues: getDefaultValues(data),
  });

  const {
    formState: { errors },
    handleSubmit,
  } = methods;

  const onSubmit: SubmitHandler<z.infer<typeof schema>> = async (data) => {
    try {
      const result = await mutation({
        variables: prepareVariables ? prepareVariables(data) : data,
      });
      if (result.data) {
        const hasDataError = !!Object.entries(result.data ?? {}).find(
          ([_key, value]) => SomeErrorSchema.safeParse(value).success,
        );

        if (hasDataError) {
          await onError?.(data, result.data as any); // eslint-disable-line
        } else {
          await onSuccess?.(data, result.data as any); // eslint-disable-line
        }
        await onSave?.(data, result.data);
      }
    } catch (error) {
      await onError?.(data, error as any); // eslint-disable-line
    }
  };

  const error = useMemo(() => {
    const maybeResultError = Object.values(mutationResult ?? {})
      .map((value) => ErrorResponseSchema.safeParse(value))
      .find((res) => res.success);
    if (maybeResultError?.success) {
      return maybeResultError.data;
    }
    return;
  }, [mutationResult]);

  const errorMessages = flattenErrorMessages(errors, methods.labels);

  let errorMessage = undefined;
  if (error && 'fieldErrors' in error) {
    // flatten ValidationError.fieldErrors into a single string
    errorMessage = error.fieldErrors
      .map((fieldError) => {
        const path = fieldError.path;

        // strip "input" from the path if it's present
        if (path[0] === 'input') {
          path.shift();
        }

        return `${path.join('.')} ${fieldError.message}`;
      })
      .join('\n');
  } else if (error) {
    errorMessage = error?.message || 'An unknown error has occurred.';
  }

  const callbackData = { ...methods, data };
  buttons = typeof buttons === 'function' ? buttons(callbackData) : buttons;

  return (
    <FormProvider {...methods}>
      <View
        role="form"
        // @ts-ignore
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e).catch(Sentry.captureException);
        }}
        testID={testID}
      >
        {errorChildren && !!(gqlError || error) ? (
          errorChildren((gqlError || error) as any) // eslint-disable-line
        ) : (
          <ErrorPresenter formErrors={errorMessages} errorString={errorMessage} error={gqlError} />
        )}
        <View style={{ gap: 15, paddingVertical: 15 }}>{children(callbackData)}</View>
        {buttons?.hidden ? null : (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: buttons?.cancel ? 'space-between' : 'center',
            }}
          >
            {buttons?.cancel && <Button variant="text" size="normal" {...buttons?.cancel} />}

            <View style={{ gap: 20 }}>
              <Button
                variant="solid"
                size="normal"
                text="Save"
                onPress={handleSubmit(onSubmit)}
                type="submit"
                {...buttons?.submit}
              />
              {buttons?.skip && <Button variant="text" size="normal" {...buttons?.skip} />}
            </View>
          </View>
        )}
      </View>
    </FormProvider>
  );
}

import { Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useI18n } from '../lib/i18n';

export function TestIntl() {
  const i18n = useI18n();
  const insets = useSafeAreaInsets();

  return (
    <View testID="TestIntl" style={{ paddingTop: insets.top, paddingBottom: insets.bottom }}>
      <Text testID="TestIntl_date">
        {i18n.formatDate(new Date('2022-11-18T22:00:00.000Z'), {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        })}
      </Text>
      <Text testID="TestIntl_list">{i18n.formatList(['a', 'b', 'c'])}</Text>
      <Text testID="TestIntl_number">{i18n.formatNumber(99999.123)}</Text>
      <Text testID="TestIntl_numberPlural">
        {i18n.formatNumber(10, {
          style: 'unit',
          unit: 'hour',
          unitDisplay: 'long',
        })}
      </Text>
    </View>
  );
}

import { useNavigation, useRoute } from '@react-navigation/native';
import * as Sentry from '@sentry/core';
import { produce } from 'immer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StatusBar, StyleProp, ViewStyle } from 'react-native';
import { Writable } from 'type-fest';
import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuid } from 'uuid';

import { Button } from '@oui/app-core/src/components/Button';
import {
  CardStack,
  Card as CardStackCard,
  CardStackRef,
} from '@oui/app-core/src/components/CardStack';
import { CheckboxListInput } from '@oui/app-core/src/components/CheckboxListInput';
import { RadioInput } from '@oui/app-core/src/components/RadioInput';
import {
  RoundedSection,
  RoundedSectionTopChild,
} from '@oui/app-core/src/components/RoundedSection';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import {
  useArtifactRequest,
  useConversationRoute,
} from '@oui/app-core/src/hooks/useArtifactResult';
import { useMyStoryMyPlanCompositionSections } from '@oui/app-core/src/hooks/useComposition';
import { useCurrentUser } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import type { CrisisTimeline, PeakMethod, RiskFactors } from '@oui/lib/src/types/avro';
import { ProductVariant } from '@oui/lib/src/types/graphql.generated';
import {
  CrisisTimelineList,
  CrisisTimelineSectionHeading,
  getCrisisTimelineSections,
} from '@oui/myplan/src/components/CrisisTimelineList';
import { RiskCurveBaselineGraph, RiskCurveGraph } from '@oui/myplan/src/components/RiskCurve';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { Icon } from '@src/components/Icon';
import { MobileHeaderStep } from '@src/components/PatientMyStoryMyPlanContainer';
import { SoloOnboardingCard, SoloOnboardingStepBadge } from '@src/components/SoloOnboarding';
import { SortableCrisisTimelineList } from '@src/components/SortableCrisisTimelineList';
import {
  FALLING_SECTIONS,
  formatCrisisPeakText,
  getCheckboxListInputProps,
  LETHAL_METHODS,
  RISING_SECTIONS,
  useRiskFactorsState,
} from '@src/lib/soloOnboarding';
import { StackScreenProps } from '@src/types';

const OverviewA = require('@src/assets/soloOnboarding/A-set-your-baseline.png');
const OverviewB = require('@src/assets/soloOnboarding/B-organize-what-happened.png');

function VerticalCard(props: {
  title: string;
  text: string;
  step: '1' | '2' | '3';
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <View flex={1}>
      <View style={{ alignSelf: 'center', marginBottom: -20, zIndex: 1 }}>
        <SoloOnboardingStepBadge step={props.step} />
      </View>
      <View
        style={[
          {
            backgroundColor: 'white',
            borderRadius: 16,
            paddingHorizontal: 8,
            paddingVertical: 16,
          },
          props.style,
        ]}
      >
        <Text
          text={props.title}
          weight="bold"
          size={20}
          style={{ marginVertical: 8 }}
          textAlign="center"
        />
        <Text text={props.text} textAlign="center" />
      </View>
    </View>
  );
}

function SoloRiskCurveStepOverview(props: StackScreenProps<'SoloRiskCurve'>) {
  const { theme } = useTheme();

  return (
    <>
      <RoundedSectionTopChild backgroundColor={theme.color.gray800}>
        <Heading
          level={2}
          text="Two sections"
          textAlign="center"
          style={{ marginBottom: 40, marginTop: 12 }}
        />
        <SoloOnboardingCard
          step="A"
          title="Set your baseline"
          text="Everyone has a different risk starting point. Here, you’ll select risk factors for suicide to set your risk baseline."
          graphicSource={OverviewA}
          style={{ marginBottom: 20 }}
        />
        <SoloOnboardingCard
          step="B"
          title="Organize what happened"
          text="It helps to lay out what happened up to the point you wanted to take your life to show how risk rises, then falls as time passes."
          graphicSource={OverviewB}
        />
      </RoundedSectionTopChild>
      <Button
        text="Start"
        onPress={() => props.navigation.setParams({ step: 'a-overview' })}
        alignSelf="center"
        style={{ marginTop: 20 }}
        testID="SoloRiskCurveStepOverview_nextButton"
      />
    </>
  );
}

function SoloRiskCurveStepAOverview(props: StackScreenProps<'SoloRiskCurve'>) {
  const { theme } = useTheme();

  return (
    <>
      <RoundedSectionTopChild backgroundColor={theme.color.gray800}>
        <Heading
          level={2}
          text="A. Set your baseline"
          textAlign="center"
          style={{ marginBottom: 40, marginTop: 12 }}
        />
        <RiskCurveBaselineGraph
          backgroundColor={theme.color.gray800}
          numRiskFactors={3}
          aria-label="A risk curve showing elevated baseline risk"
        />
      </RoundedSectionTopChild>
      <Text
        text={`In this first part, you'll mark your risk factors for suicide.

Baseline risk factors are things about you or things that have happened to you that increase your likelihood of becoming suicidal.

These set your baseline risk for your risk curve.`}
      />
      <Button
        text="Next"
        onPress={() => props.navigation.setParams({ step: 'a-riskfactors' })}
        alignSelf="center"
        style={{ marginTop: 40 }}
        testID="SoloRiskCurveStepAOverview_nextButton"
      />
    </>
  );
}

function SoloRiskCurveStepARiskFactors(props: StackScreenProps<'SoloRiskCurve'>) {
  const { data, update } = useMyStoryMyPlanCompositionSections();
  const { theme } = useTheme();
  const { value, inputProps } = useRiskFactorsState(
    (data?.RISK_FACTORS as Writable<RiskFactors>) ?? [],
  );

  async function save() {
    await update({ RISK_FACTORS: value });
    props.navigation.setParams({ step: 'a-review' });
  }

  return (
    <>
      <RoundedSectionTopChild backgroundColor={theme.color.gray800}>
        <Heading
          level={2}
          text="Set your baseline"
          textAlign="center"
          style={{ marginBottom: 40, marginTop: 12 }}
        />
        <RiskCurveBaselineGraph
          baselineText="Your baseline"
          backgroundColor={theme.color.gray800}
          numRiskFactors={value.length}
        />
      </RoundedSectionTopChild>
      <CheckboxListInput
        label="What are your risk factors?"
        {...inputProps}
        testID="SoloRiskCurveStepARiskFactors_input"
      />
      <Button
        text="Set"
        onPress={save}
        alignSelf="center"
        style={{ marginTop: 40 }}
        testID="SoloRiskCurveStepARiskFactors_nextButton"
      />
    </>
  );
}

function SoloRiskCurveStepAReview(props: StackScreenProps<'SoloRiskCurve'>) {
  const { data } = useMyStoryMyPlanCompositionSections();
  const numRiskFactors = data?.RISK_FACTORS?.length ?? 0;

  return (
    <>
      <Heading level={2} text="Ok. Set" textAlign="center" style={{ marginBottom: 16 }} />
      <Text
        text={`You have ${
          numRiskFactors === 1 ? '1 risk factor' : `${numRiskFactors} risk factors`
        } for suicide that move your baseline risk up. That means you have a higher likelihood of becoming suicidal.`}
        style={{ marginBottom: 40 }}
      />
      <RiskCurveBaselineGraph baselineText="Your baseline" numRiskFactors={numRiskFactors} />
      <View style={{ marginTop: 40, justifyContent: 'space-between' }} row>
        <Button
          text="Back"
          variant="text"
          onPress={() => props.navigation.setParams({ step: 'a-riskfactors' })}
        />
        <Button
          text="Next"
          onPress={() => props.navigation.setParams({ step: 'b-overview' })}
          testID="SoloRiskCurveStepAReview_nextButton"
        />
      </View>
    </>
  );
}

function SoloRiskCurveStepBOverview(props: StackScreenProps<'SoloRiskCurve'>) {
  const { data } = useMyStoryMyPlanCompositionSections();
  const numRiskFactors = data?.RISK_FACTORS?.length ?? 0;
  const { theme } = useTheme();

  return (
    <>
      <Heading
        level={2}
        text="B. Organize what happened"
        textAlign="center"
        style={{ marginBottom: 40, marginTop: 12 }}
      />
      <RiskCurveGraph
        aria-label="A suicide risk curve"
        timeline={[]}
        crisisPeakID=""
        numRiskFactors={numRiskFactors}
      />
      <View
        style={{
          marginHorizontal: -20,
          marginVertical: 20,
          padding: 20,
          backgroundColor: theme.color.gray800,
        }}
      >
        <Heading
          level={2}
          text="Organize your curve in 3 parts"
          textAlign="center"
          style={{ marginBottom: 12, marginTop: 12 }}
        />
        <View row style={{ alignItems: 'flex-start' }} spacing={8}>
          <VerticalCard
            step="1"
            title="Peak"
            text="Pick what happened at the height of your crisis"
          />
          <VerticalCard step="2" title="Rising" text="Choose & order what led up to the crisis" />
          <VerticalCard step="3" title="Falling" text="Choose & order what happened after" />
        </View>
      </View>
      <Button
        text="Next"
        onPress={() => props.navigation.setParams({ step: 'b-peak' })}
        alignSelf="center"
        style={{ marginTop: 30 }}
        testID="SoloRiskCurveStepBOverview_nextButton"
      />
    </>
  );
}

function SoloRiskCurveStepBPeak(props: StackScreenProps<'SoloRiskCurve'>) {
  const cardsRef = useRef<CardStackRef>(null);
  const [type, setType] = useState<'attempt' | 'ideation'>();
  const [checked, setChecked] = useState<PeakMethod[]>([]);
  const [followupsValue, setFollowupsValue] = useState<Record<string, string>>({});
  const { data, update } = useMyStoryMyPlanCompositionSections();
  const { formatList } = useI18n();

  function save() {
    const crisisPeakText = formatCrisisPeakText({
      followupsValue,
      checked,
      type,
      formatList,
    });
    return update((originalData) => {
      return produce(originalData, (draft) => {
        const crisisPeakID = draft.CRISIS_TIMELINE.crisisPeakID;
        const crisisPeak = draft.CRISIS_TIMELINE.timeline.find(
          (event) => event.ID === crisisPeakID,
        );
        if (crisisPeak) {
          crisisPeak.text = crisisPeakText;
          crisisPeak.peakMethods = checked;
        } else {
          const newEvent = {
            ID: uuid(),
            text: crisisPeakText,
            isWarningSign: false,
            peakMethods: checked,
          };
          draft.CRISIS_TIMELINE = {
            crisisPeakID: newEvent.ID,
            timeline: [...draft.CRISIS_TIMELINE.timeline, newEvent],
          };
        }
      });
    });
  }

  return (
    <>
      <CardStack
        hideNextButtonIndexes={[1, 2]}
        ref={cardsRef}
        header={
          <CrisisTimelineSectionHeading icon="risk-curve-peak" text="Peak" alignSelf="center" />
        }
      >
        <CardStackCard>
          <RiskCurveGraph
            aria-label="The peak of a suicide risk curve"
            timeline={[]}
            crisisPeakID=""
            focusedSection="peak"
            numRiskFactors={data?.RISK_FACTORS?.length}
          />
          <Text
            text="Start building your suicide risk curve by identifying what happened at the height of your crisis."
            style={{ marginTop: 30 }}
          />
        </CardStackCard>
        <CardStackCard>
          <RadioInput<'attempt' | 'ideation'>
            testID="SoloRiskCurveStepBPeak_eventInput"
            label="What happened at the height of your suicide crisis?"
            value={type}
            onChangeValue={(value) => {
              setType(value);
              setTimeout(() => {
                cardsRef.current?.next();
              }, 300);
            }}
            items={[
              { label: 'I made an attempt', value: 'attempt' },
              { label: 'I had strong thoughts and/or a plan', value: 'ideation' },
            ]}
          />
        </CardStackCard>
        <CardStackCard>
          <CheckboxListInput
            testID="SoloRiskCurveStepBPeak_methodInput"
            label={
              type === 'attempt'
                ? 'How did you try to kill yourself?'
                : 'How did you think about killing yourself?'
            }
            defaultFollowup="Describe"
            followups={{}}
            onChangeFollowupsValue={setFollowupsValue}
            followupsValue={followupsValue}
            value={checked}
            onChangeValue={setChecked}
            items={LETHAL_METHODS}
          />
          <Button
            text="Next"
            onPress={async () => {
              await save();
              props.navigation.setParams({ step: 'b-rising' });
            }}
            alignSelf="center"
            style={{ marginTop: 30 }}
            testID="SoloRiskCurveStepBPeak_nextButton"
          />
        </CardStackCard>
      </CardStack>
    </>
  );
}

function SoloRiskCurveStepBRising(props: StackScreenProps<'SoloRiskCurve'>) {
  const cardsRef = useRef<CardStackRef>(null);
  const { data: user } = useCurrentUser();
  const productVariant =
    (user?.currentUser?.user?.__typename === 'Patient' &&
      user.currentUser.user.role.product.slug) ??
    ProductVariant.AVIVA_ADULT;
  const { data, update } = useMyStoryMyPlanCompositionSections();
  const [timeline, setTimeline] = useState<NonNullable<typeof data>['CRISIS_TIMELINE']['timeline']>(
    () => getCrisisTimelineSections(data?.CRISIS_TIMELINE!).before,
  );

  async function save() {
    if (data) {
      const { peak, after } = getCrisisTimelineSections(data.CRISIS_TIMELINE);
      await update({
        CRISIS_TIMELINE: { ...data.CRISIS_TIMELINE, timeline: [...timeline, ...peak, ...after] },
      });
    }
  }

  const checkedKeysSet = useMemo(() => {
    const keySet = new Set<string>();

    Object.values(RISING_SECTIONS).forEach((section) => {
      Object.entries(section).forEach(([key, value]) => {
        if (timeline.find((e) => e.text.startsWith(value))) {
          keySet.add(key);
        }
      });
    });

    return keySet;
  }, [timeline]);

  function getSectionProps<T extends typeof RISING_SECTIONS | typeof FALLING_SECTIONS>(
    collection: T,
    section: keyof T,
    suicideModeSection: NonNullable<CrisisTimeline['timeline'][number]['suicideModeSection']>,
  ) {
    return getCheckboxListInputProps({
      checkedKeysSet,
      collection,
      section,
      suicideModeSection,
      setTimeline,
      timeline,
    });
  }

  return (
    <>
      <CardStack
        onIndexChanged={() => {
          void save();
        }}
        hideNextButtonIndexes={[5]}
        ref={cardsRef}
        header={
          <CrisisTimelineSectionHeading icon="risk-curve-rising" text="Rising" alignSelf="center" />
        }
      >
        <CardStackCard>
          <RiskCurveGraph
            aria-label="The rising portion of a suicide risk curve"
            timeline={[]}
            crisisPeakID=""
            focusedSection="rising"
            numRiskFactors={data?.RISK_FACTORS?.length}
          />
          <Text
            text="Now, let’s look at what happened in the hours leading up to the peak of your crisis."
            style={{ marginTop: 30 }}
          />
        </CardStackCard>
        <CardStackCard>
          <CheckboxListInput
            label={`In the hours before the peak of your crisis...

What events happened?`}
            defaultFollowup="Describe"
            followups={{}}
            {...getSectionProps(
              RISING_SECTIONS,
              productVariant === ProductVariant.AVIVA_ADULT
                ? 'beforeEvents'
                : 'beforeEventsAdolescent',
              'beforeEvents',
            )}
            testID="SoloRiskCurveStepBRising_beforeEventsInput"
          />
        </CardStackCard>
        <CardStackCard>
          <CheckboxListInput
            label={`In the hours before the peak of your crisis...

What feelings did you have?`}
            defaultFollowup="Describe"
            followups={{}}
            {...getSectionProps(RISING_SECTIONS, 'feelings', 'feelings')}
            testID="SoloRiskCurveStepBRising_feelingsInput"
          />
        </CardStackCard>
        <CardStackCard>
          <CheckboxListInput
            label={`In the hours before the peak of your crisis...

What thoughts did you have?`}
            followups={{ other_thought: 'Describe' }}
            {...getSectionProps(RISING_SECTIONS, 'thoughts', 'thoughts')}
            testID="SoloRiskCurveStepBRising_thoughtsInput"
          />
        </CardStackCard>
        <CardStackCard>
          <CheckboxListInput
            label={`In the hours before the peak of your crisis...

What behaviors or things did you do?`}
            defaultFollowup="Describe"
            followups={{}}
            {...getSectionProps(RISING_SECTIONS, 'behaviors', 'behaviors')}
            testID="SoloRiskCurveStepBRising_behaviorsInput"
          />
        </CardStackCard>
        <CardStackCard>
          <CheckboxListInput
            label={`In the hours before the peak of your crisis...

What bodily responses did you have?`}
            defaultFollowup="Describe"
            followups={{}}
            {...getSectionProps(RISING_SECTIONS, 'physiological', 'physiological')}
            testID="SoloRiskCurveStepBRising_physiologicalInput"
          />
          <Button
            text="Next"
            onPress={async () => {
              await save();
              props.navigation.setParams({ step: 'b-rising-review' });
            }}
            alignSelf="center"
            style={{ marginTop: 30 }}
            testID="SoloRiskCurveStepBRising_nextButton"
          />
        </CardStackCard>
      </CardStack>
    </>
  );
}

function SoloRiskCurveStepBRisingReview(props: StackScreenProps<'SoloRiskCurve'>) {
  const { data, update } = useMyStoryMyPlanCompositionSections();
  const [localData, setLocalData] = useState(data);

  useEffect(() => {
    if (!localData && data) {
      setLocalData(data);
    }
  }, [localData, data]);

  const debouncedUpdate = useDebouncedCallback(update, 500, { maxWait: 5000 });

  useEffect(() => {
    if (localData) {
      void debouncedUpdate({ CRISIS_TIMELINE: localData.CRISIS_TIMELINE });
    }
  }, [debouncedUpdate, localData]);

  return localData?.CRISIS_TIMELINE ? (
    <SortableCrisisTimelineList
      testID="SoloRiskCurveStepBRisingReview_timeline"
      value={localData.CRISIS_TIMELINE}
      onChangeValue={(v) => {
        setLocalData({ ...localData, CRISIS_TIMELINE: v });
      }}
      activeSection="before"
      renderFooter={() => {
        return (
          <View
            row
            style={{
              justifyContent: 'space-between',
              paddingHorizontal: 30,
              paddingVertical: 20,
              backgroundColor: 'white',
            }}
          >
            <Button
              variant="text"
              text="Back"
              onPress={() => props.navigation.setParams({ step: 'b-rising' })}
            />
            <Button
              text="Next"
              onPress={() => props.navigation.setParams({ step: 'b-falling' })}
              testID="SoloRiskCurveStepBRisingReview_nextButton"
            />
          </View>
        );
      }}
    />
  ) : null;
}

function SoloRiskCurveStepBFalling(props: StackScreenProps<'SoloRiskCurve'>) {
  const cardsRef = useRef<CardStackRef>(null);
  const { data, update } = useMyStoryMyPlanCompositionSections();
  const [timeline, setTimeline] = useState<NonNullable<typeof data>['CRISIS_TIMELINE']['timeline']>(
    () => getCrisisTimelineSections(data?.CRISIS_TIMELINE!).after,
  );

  async function save() {
    if (data) {
      const { before, peak } = getCrisisTimelineSections(data.CRISIS_TIMELINE);
      await update({
        CRISIS_TIMELINE: { ...data.CRISIS_TIMELINE, timeline: [...before, ...peak, ...timeline] },
      });
    }
  }

  const checkedKeysSet = useMemo(() => {
    const keySet = new Set<string>();

    Object.values(FALLING_SECTIONS).forEach((section) => {
      Object.entries(section).forEach(([key, value]) => {
        if (timeline.find((e) => e.text.startsWith(value))) {
          keySet.add(key);
        }
      });
    });

    return keySet;
  }, [timeline]);

  const afterEventProps = getCheckboxListInputProps({
    timeline,
    setTimeline,
    checkedKeysSet,
    collection: FALLING_SECTIONS,
    section: 'afterEvents',
    suicideModeSection: 'afterEvents',
  });

  return (
    <>
      <CardStack
        hideNextButtonIndexes={[1]}
        ref={cardsRef}
        header={
          <CrisisTimelineSectionHeading
            icon="risk-curve-falling"
            text="Falling"
            alignSelf="center"
          />
        }
      >
        <CardStackCard>
          <RiskCurveGraph
            aria-label="The falling portion of a suicide risk curve"
            timeline={[]}
            crisisPeakID=""
            focusedSection="falling"
            numRiskFactors={data?.RISK_FACTORS?.length}
          />
          <Text
            text="Lastly, let’s look at what happened in the hours after the peak of your suicide crisis."
            style={{ marginTop: 30 }}
          />
        </CardStackCard>
        <CardStackCard>
          <CheckboxListInput
            label={`In the minutes & hours after the peak of your crisis...

What events happened?`}
            defaultFollowup="Describe"
            followups={{}}
            {...afterEventProps}
            testID="SoloRiskCurveStepBFalling_input"
          />
          <Button
            text="Next"
            onPress={async () => {
              await save();
              props.navigation.setParams({ step: 'b-falling-review' });
            }}
            alignSelf="center"
            style={{ marginTop: 30 }}
            testID="SoloRiskCurveStepBFalling_nextButton"
          />
        </CardStackCard>
      </CardStack>
    </>
  );
}

function SoloRiskCurveStepBFallingReview(props: StackScreenProps<'SoloRiskCurve'>) {
  const { data, update } = useMyStoryMyPlanCompositionSections();
  const [localData, setLocalData] = useState(data);

  useEffect(() => {
    if (!localData && data) {
      setLocalData(data);
    }
  }, [localData, data]);

  const debouncedUpdate = useDebouncedCallback(update, 500, { maxWait: 5000 });

  useEffect(() => {
    if (localData) {
      void debouncedUpdate({ CRISIS_TIMELINE: localData.CRISIS_TIMELINE });
    }
  }, [debouncedUpdate, localData]);

  return localData?.CRISIS_TIMELINE ? (
    <SortableCrisisTimelineList
      testID="SoloRiskCurveStepBFallingReview_timeline"
      value={localData.CRISIS_TIMELINE}
      onChangeValue={(v) => {
        setLocalData({ ...localData, CRISIS_TIMELINE: v });
      }}
      activeSection="after"
      renderFooter={() => {
        return (
          <View
            row
            style={{
              justifyContent: 'space-between',
              paddingHorizontal: 30,
              paddingVertical: 20,
              paddingBottom: 200, // need extra padding in case we scroll up on android due to keyboard
              backgroundColor: 'white',
            }}
          >
            <Button
              variant="text"
              text="Back"
              onPress={() => props.navigation.setParams({ step: 'b-falling' })}
            />
            <Button
              text="Next"
              onPress={() => props.navigation.setParams({ step: 'review' })}
              testID="SoloRiskCurveStepBFallingReview_nextButton"
            />
          </View>
        );
      }}
    />
  ) : null;
}

function SoloRiskCurveReview(props: StackScreenProps<'SoloRiskCurve' | 'RiskCurve'>) {
  const { theme } = useTheme();
  const { name } = props.route;
  const { data } = useMyStoryMyPlanCompositionSections();
  const [activeItemIndex, setActiveItemIndex] = useState<number | undefined>(undefined);
  const onPressItem = useCallback((_: unknown, i: number) => setActiveItemIndex(i), []);
  const conversationRoute = useConversationRoute();

  useArtifactRequest(props.route.name, true);

  return data ? (
    <View spacing={20}>
      <Heading level={2} text="My risk curve" />
      <RiskCurveGraph
        {...data.CRISIS_TIMELINE}
        activeItemIndex={activeItemIndex}
        onPressPoint={onPressItem}
        onPress={() => setActiveItemIndex(undefined)}
        numRiskFactors={data.RISK_FACTORS?.length}
      />
      <View
        style={{ backgroundColor: theme.color.gray800, marginHorizontal: -20, padding: 20 }}
        spacing={20}
      >
        <View row style={{ justifyContent: 'space-between' }}>
          <Text
            text="Suicide crisis timeline"
            weight="semibold"
            color={theme.color.gray100}
            size={17}
            role="heading"
          />
          <Icon
            aria-label="Edit timeline"
            name="edit"
            color={theme.color.gray400}
            onPress={() => props.navigation.setParams({ step: 'edit' })}
          />
        </View>
        <CrisisTimelineList
          {...data.CRISIS_TIMELINE}
          showRiskColor={true}
          onPress={onPressItem}
          testID="SoloRiskCurveReview_timeline"
        />
      </View>
      <View>
        <Button
          alignSelf="center"
          text="Done"
          onPress={() => {
            if (name === 'SoloRiskCurve') {
              props.navigation.navigate('Conversation', {
                ...conversationRoute.params,
                _artifactResult: { complete: true },
              });
            } else {
              props.navigation.goBack();
            }
          }}
        />
      </View>
    </View>
  ) : null;
}

function SoloRiskCurveEdit(props: StackScreenProps<'SoloRiskCurve'>) {
  const { data, update } = useMyStoryMyPlanCompositionSections();
  const [localData, setLocalData] = useState(data);

  useEffect(() => {
    if (!localData && data) {
      setLocalData(data);
    }
  }, [localData, data]);

  const debouncedUpdate = useDebouncedCallback(update, 500, { maxWait: 5000 });

  useEffect(() => {
    if (localData) {
      void debouncedUpdate({ CRISIS_TIMELINE: localData.CRISIS_TIMELINE });
    }
  }, [debouncedUpdate, localData]);

  return localData?.CRISIS_TIMELINE ? (
    <SortableCrisisTimelineList
      value={localData.CRISIS_TIMELINE}
      onChangeValue={(v) => {
        setLocalData({ ...localData, CRISIS_TIMELINE: v });
      }}
      activeSection="all"
      renderFooter={() => {
        return (
          <View
            row
            style={{
              justifyContent: 'center',
              paddingHorizontal: 30,
              paddingVertical: 20,
              paddingBottom: 200, // need extra padding in case we scroll up on android due to keyboard
              backgroundColor: 'white',
            }}
          >
            <Button
              alignSelf="center"
              text="Done"
              onPress={() => props.navigation.setParams({ step: 'review' })}
            />
          </View>
        );
      }}
    />
  ) : null;
}

export const SoloRiskCurve = () => {
  const navigation = useNavigation<StackScreenProps<'SoloRiskCurve'>['navigation']>();
  const route = useRoute<StackScreenProps<'SoloRiskCurve'>['route']>();
  const props = { navigation, route };
  const { theme } = useTheme();
  const step = props.route.params?.step ?? '';
  const showHeaderSteps = step.startsWith('b') && step !== 'b-overview';
  const { data } = useMyStoryMyPlanCompositionSections({ createIfUndefined: true });

  const setParams = props.navigation.setParams;
  useEffect(() => {
    // @ts-expect-error when this component is nested inside ChatArtifactPreview we may not be on the SoloSuicideMode screen
    if (route.name === 'Conversation') return;
    if (data) {
      const { before, peak, after } = getCrisisTimelineSections(data.CRISIS_TIMELINE);

      if (data.CRISIS_TIMELINE.crisisPeakID && !peak.length) {
        Sentry.captureMessage('crisisPeakID not in CRISIS_TIMELINE', {
          level: 'warning',
        });
      }

      if (after.length) {
        setParams({ step: 'review' });
      } else if (before.length) {
        setParams({ step: 'b-falling' });
      } else if (peak.length) {
        setParams({ step: 'b-rising' });
      } else if (data.RISK_FACTORS?.length) {
        setParams({ step: 'b-overview' });
      } else {
        setParams({ step: 'overview' });
      }
    }
    // eslint-disable-next-line
  }, [!!data, setParams]);

  return (
    <>
      <StatusBar barStyle="light-content" />
      <RoundedSection
        applyHeaderOptions
        noScrollView={step === 'b-rising-review' || step === 'b-falling-review' || step === 'edit'}
        testID="SoloRiskCurve_scrollView"
        subtitle="Risk Curve"
        title={
          <View style={showHeaderSteps ? { marginTop: -10 } : null}>
            <Text
              text="Risk Curve"
              weight="bold"
              color="white"
              size={28}
              style={{
                lineHeight: 35,
                marginBottom: 20,
                textAlign: 'center',
              }}
            />
            {showHeaderSteps ? (
              <View row style={{ paddingBottom: 20 }}>
                <MobileHeaderStep
                  backgroundColor={theme.color.primary100}
                  num={1}
                  label="Peak"
                  active={step === 'b-peak'}
                  complete={step !== 'b-peak'}
                />
                <MobileHeaderStep
                  backgroundColor={theme.color.primary100}
                  num={2}
                  label="Rising"
                  active={step === 'b-rising' || step === 'b-rising-review'}
                  complete={step === 'b-falling' || step === 'b-falling-review'}
                />
                <MobileHeaderStep
                  backgroundColor={theme.color.primary100}
                  num={3}
                  label="Falling"
                  active={step === 'b-falling' || step === 'b-falling-review'}
                  complete={false}
                />
              </View>
            ) : null}
          </View>
        }
        color={theme.color.primary100}
        secondaryColor={
          step === 'b-rising' || step === 'b-falling' ? theme.color.gray800 : undefined
        }
      >
        {!data || !step ? (
          <ActivityIndicator />
        ) : step === 'review' ? (
          <SoloRiskCurveReview {...props} />
        ) : step === 'edit' ? (
          <SoloRiskCurveEdit {...props} />
        ) : step === 'overview' ? (
          <SoloRiskCurveStepOverview {...props} />
        ) : step === 'a-overview' ? (
          <SoloRiskCurveStepAOverview {...props} />
        ) : step === 'a-riskfactors' ? (
          <SoloRiskCurveStepARiskFactors {...props} />
        ) : step === 'a-review' ? (
          <SoloRiskCurveStepAReview {...props} />
        ) : step === 'b-overview' ? (
          <SoloRiskCurveStepBOverview {...props} />
        ) : step === 'b-peak' ? (
          <SoloRiskCurveStepBPeak {...props} />
        ) : step === 'b-rising' ? (
          <SoloRiskCurveStepBRising {...props} />
        ) : step === 'b-rising-review' ? (
          <SoloRiskCurveStepBRisingReview {...props} />
        ) : step === 'b-falling' ? (
          <SoloRiskCurveStepBFalling {...props} />
        ) : step === 'b-falling-review' ? (
          <SoloRiskCurveStepBFallingReview {...props} />
        ) : null}
      </RoundedSection>
    </>
  );
};

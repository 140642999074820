import { useQuery } from '@apollo/client';
import { ReactNode, useMemo, useState } from 'react';
import { Linking, Platform } from 'react-native';
import semverCompare from 'semver-compare';

import { graphql } from '@oui/lib/src/graphql/tada';

import { ConfirmationModal } from '../components/ConfirmationModal';
import { getNativeBuildVersionAsSemver, manifest } from '../constants';
import { usePersistedState } from '../hooks/usePersistedState';
import { useI18n } from '../lib/i18n';

/*
 * minimumAppVersion query returns version minimums based on other requesting client so it is OK
 * to compare to the build's native version vs using this as a feature flag when different app clients
 * may be on inconsistent versions
 */
function nativeVersionAtLeast(version: string) {
  const nativeBuildVersion = getNativeBuildVersionAsSemver();
  return semverCompare(nativeBuildVersion ?? '99.99.99', version) >= 0;
}

const MinimumAppVersionQuery = graphql(`
  query MinimumAppVersion {
    minimumAppVersion {
      recommendedMinimumVersion
      requiredMinimumVersion
    }
  }
`);

export function MinimumAppVersion({ children }: { children: ReactNode }) {
  const [minOuiAppVersion] = usePersistedState('minOuiAppVersion', null);
  const [isVisible, setIsVisibile] = useState(true);
  const { $t } = useI18n();

  let requestContext = {};
  if (minOuiAppVersion) {
    requestContext = {
      headers: {
        'X-Mock-Oui-Min-Version': JSON.stringify(minOuiAppVersion),
      },
    };
  }

  const { data } = useQuery(MinimumAppVersionQuery, {
    context: requestContext,
  });

  const versionDetails = useMemo(() => {
    const currentAppVersion = getNativeBuildVersionAsSemver();
    const { recommendedMinimumVersion, requiredMinimumVersion } = data?.minimumAppVersion || {
      recommendedMinimumVersion: '0.0.0',
      requiredMinimumVersion: '0.0.0',
    };
    const isAtLeastMinimumRecomendedVersion = nativeVersionAtLeast(recommendedMinimumVersion);
    const isAtLeastMinimumRequiredVersion = nativeVersionAtLeast(requiredMinimumVersion);

    const requiresAppUpgrade =
      !isAtLeastMinimumRecomendedVersion || !isAtLeastMinimumRequiredVersion;
    return {
      recommendedMinimumVersion,
      requiredMinimumVersion,
      isAtLeastMinimumRecomendedVersion,
      isAtLeastMinimumRequiredVersion,
      requiresAppUpgrade: !!currentAppVersion && requiresAppUpgrade,
      canBeDismissed: isAtLeastMinimumRequiredVersion,
    };
  }, [data]);

  const dismissAppUpgradeModal = () => {
    setIsVisibile(false);
  };

  const onConfirm = () => {
    if (Platform.OS === 'ios') {
      void Linking.openURL(`https://itunes.apple.com/us/app/aviva/id1480869629`);
    } else if (Platform.OS === 'android') {
      void Linking.openURL(
        `https://play.google.com/store/apps/details?id=com.ouitherapeutics.aviva`,
      );
    } else {
      global.window.location.reload();
    }
  };

  const onDismiss = () => {
    dismissAppUpgradeModal();
  };

  return (
    <>
      {versionDetails.requiresAppUpgrade && isVisible ? (
        <ConfirmationModal
          visible={true}
          title={$t({ id: 'MinimumAppVersion_title', defaultMessage: 'Update Available' })}
          description={
            !versionDetails.canBeDismissed
              ? $t(
                  {
                    id: 'MinimumAppVersion_optionalUpgrade',
                    defaultMessage:
                      "There's a newer version of {appName} that's available for download. Please update to continue using {appName}.",
                  },
                  { appName: manifest.name },
                )
              : $t(
                  {
                    id: 'MinimumAppVersion_requiredUpgrade',
                    defaultMessage:
                      "There's a newer version of {appName} that's available for download. We recommend updating to ensure you have the best experience possible.",
                  },
                  { appName: manifest.name },
                )
          }
          onCancel={versionDetails.canBeDismissed ? onDismiss : null}
          cancelText={$t({ id: 'MinimumAppVersion_dismissButton', defaultMessage: 'Not now' })}
          cancelTestID="MinimumAppVersion_dismissButton"
          onConfirm={onConfirm}
          confirmText={$t({ id: 'MinimumAppVersion_upgradeButton', defaultMessage: 'Update' })}
          confirmTestID="MinimumAppVersion_upgradeButton"
        />
      ) : null}
      {children}
    </>
  );
}

import { ComponentProps, ReactNode } from 'react';
import { View } from 'react-native';
import Svg, { Path } from 'react-native-svg';

import { useTheme } from '../styles';

type Position = [number, number];
export const BezierCurve = ({
  children,
  viewBoxWidth,
  viewBoxHeight,
  startPoint,
  firstControlPoint,
  secondControlPoint,
  endPoint,
  fill,
  inverse,
  accessible,
  role,
  'aria-label': ariaLabel,
}: {
  children?: ReactNode;
  viewBoxHeight: number;
  viewBoxWidth: number;
  startPoint: Position;
  firstControlPoint: Position;
  secondControlPoint: Position;
  endPoint: Position;
  fill?: string;
  inverse?: boolean;
} & Pick<ComponentProps<typeof Svg>, 'accessible' | 'role' | 'aria-label'>) => {
  const { theme } = useTheme();
  fill ||= theme.color.accentTwo100;
  return (
    <View
      style={{
        width: '100%',
        aspectRatio: viewBoxWidth / viewBoxHeight,
      }}
    >
      <Svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        preserveAspectRatio="none"
        accessible={accessible}
        role={role}
        aria-label={ariaLabel}
      >
        <Path
          d={`
          M ${startPoint}
          C ${firstControlPoint}
            ${secondControlPoint}
            ${endPoint}
          L ${inverse ? `${viewBoxWidth},${viewBoxHeight} 0,${viewBoxHeight}` : `${viewBoxWidth},0`}
          L 0,${0}
        `}
          fill={fill}
          strokeWidth={inverse ? 0 : 1}
          stroke={fill}
        />
        {children}
      </Svg>
    </View>
  );
};

// @ts-expect-error
import computeMd5 from 'blueimp-md5';
import * as FileSystem from 'expo-file-system';
import { Platform } from 'react-native';

import Sentry from '../sentry';

export function getCachedUri<T extends string | number>(uri: T): T extends number ? null : string {
  type Return = T extends number ? null : string;
  if (Platform.OS === 'web' || typeof uri === 'number') return null as Return;
  const extension = (uri as string).split('.').reverse()[0];
  const hash = computeMd5(uri);
  return `${FileSystem.documentDirectory}mediaplayer-preload/${hash}.${extension}` as Return;
}

export const getMinuteSecondFromMillis = (millis: number) => {
  const totalSeconds = millis / 1000;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);
  return {
    minutes,
    seconds,
  };
};

// Adapted from
// https://github.com/ihmpavel/expo-video-player/blob/3448d17d0773f967082b194453742ebc2f6f88f9/lib/index.tsx#L433
export const getMMSSFromMillis = (millis: number, duration: number) => {
  const { minutes, seconds } = getMinuteSecondFromMillis(millis);

  const durationLongerThanTenMinutes = duration / 1000 / 60 >= 10;

  const padWithZero = (n: number, isMinuteValue: boolean) => {
    const str = n.toString();
    if ((!isMinuteValue && n < 10) || (n < 10 && durationLongerThanTenMinutes)) {
      return '0' + str;
    }
    return str;
  };

  return padWithZero(minutes, true) + ':' + padWithZero(seconds, false);
};

export async function preloadMedia(uri: string) {
  if (Platform.OS === 'web') return;
  await FileSystem.makeDirectoryAsync(`${FileSystem.documentDirectory}mediaplayer-preload`, {
    intermediates: true,
  });
  const cachedUri = getCachedUri(uri);
  const { exists } = await FileSystem.getInfoAsync(cachedUri);
  if (!exists) {
    const result = await FileSystem.downloadAsync(uri, cachedUri);
    Sentry.addBreadcrumb({ message: 'preloadMedia', data: result });
  }
}

import color from 'color';
import hexToRgba from 'hex-to-rgba';
import { ComponentProps } from 'react';
import { TouchableOpacity } from 'react-native';

import { Icon } from '../components/Icon';
import { Small, Text } from '../components/Text';
import { View } from '../components/View';
import { useI18n } from '../lib/i18n';
import { Shadow, useTheme } from '../styles';

export function PracticeTile(props: {
  testID: string;
  title: string;
  description: string;
  color: string;
  textColor?: string;
  icon: ComponentProps<typeof Icon>['name'];
  onPress: () => void;
  lockedUntil?: number;
  practiceCount: number | undefined;
}) {
  const { theme } = useTheme();
  const isDark = color(props.color).darken(0.1).isDark();
  const lockedUntil = props.lockedUntil;

  let textColor = props.textColor ?? (isDark ? 'white' : theme.color.gray200);
  if (lockedUntil) textColor = hexToRgba(theme.color.gray100, 0.7);

  const { $t } = useI18n();
  const completedText = $t(
    { id: 'PracticeTile_practiceCount', defaultMessage: '{count} done' },
    { count: props.practiceCount ?? 0 },
  );

  return (
    <TouchableOpacity
      testID={props.testID}
      onPress={props.onPress}
      disabled={!!lockedUntil}
      aria-label={`${props.title}. ${props.description}. ${
        lockedUntil ? $t({ id: 'PracticeTile_locked', defaultMessage: 'locked' }) : completedText
      }`}
      role="button"
      aria-disabled={typeof lockedUntil === 'number'}
      style={[
        lockedUntil
          ? { backgroundColor: '#efeff4' }
          : [Shadow.high, { backgroundColor: props.color }],
        { borderRadius: 20, padding: 16, flex: 1 },
      ]}
    >
      <View
        row
        style={{
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <View
          style={{
            width: 35,
            height: 35,
            backgroundColor: 'rgba(255,255,255,0.3)',
            borderRadius: 10,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon name={props.icon} color={textColor} />
        </View>
        {lockedUntil ? <Icon name="lock" color={textColor} size={20} /> : null}
      </View>
      <View style={{ flexGrow: 1 }} spacing={8}>
        <Text text={props.title} size={17} weight="semibold" color={textColor} />
        {props.description ? <Text text={props.description} size={13} color={textColor} /> : null}
      </View>
      {lockedUntil ? (
        <View
          style={{
            marginTop: 20,
          }}
        >
          <Small
            text={$t(
              { id: 'PracticeTile_lockedUntilHint', defaultMessage: 'Finish session {sessionNum}' },
              { sessionNum: lockedUntil },
            )}
          />
        </View>
      ) : (
        <View
          style={{
            backgroundColor: 'rgba(255,255,255,0.3)',
            borderRadius: 10,
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'flex-start',
            paddingHorizontal: 14,
            paddingVertical: 2,
            marginTop: 20,
          }}
        >
          <Small text={completedText} testID="PracticeTile_practiceCount" color={textColor} />
        </View>
      )}
    </TouchableOpacity>
  );
}

import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet } from 'react-native';

import { AppActivityName } from '@oui/lib/src/types';

import { useI18n } from '../../../lib/i18n';
import { useTheme } from '../../../styles';
import ChatArtifactPreview from '../../ChatArtifactPreview';
import { Icon } from '../../Icon';
import { Heading } from '../../Text';
import { View } from '../../View';
import { SessionVideosQuery } from '../Videos/Videos';

export type SessionActivitiesProps = {
  contentType: string;
  testID?: string;
};

const LINK_TEXT_BY_ARTIFACT_NAME: Record<AppActivityName, string> = {
  ActivityDiary: 'ActivityDiary',
  BingeEatingMyPlan: 'MyPlan',
  CopingCards: 'Coping cards',
  EatingLog: 'Eating log',
  EditEatingSchedule: 'Eating schedule',
  HopeKit: 'Hope kit',
  MyPlanReview: 'MyPlan Review',
  Relaxation: 'Relaxation',
  RiskCurve: 'Risk Curve',
  SleepDiary: 'Sleep Diary',
  SoloMyPlan: 'MyPlan',
  SoloRiskCurve: 'Risk Curve',
  SoloSuicideMode: 'Suicide Mode',
  SuicideMode: 'Suicide Mode',
  ThoughtDiary: 'Thought Diary',
};

export function SessionActivities({ contentType, testID }: SessionActivitiesProps) {
  const { $t } = useI18n();
  const { navigate } = useNavigation();
  const { theme, Shadow } = useTheme();

  const { data } = useQuery(SessionVideosQuery, {
    variables: {
      contentType,
    },
    fetchPolicy: 'cache-only',
  });

  const activities = data?.user?.role?.sessions[0]?.session.content?.activities;
  return (
    <View
      testID={testID}
      style={[
        {
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      ]}
      spacing={15}
    >
      <Heading
        level={2}
        text={$t({ id: 'Conversation_videoTabActivitiesHeadline', defaultMessage: 'Activities' })}
      />
      <View
        style={[
          {
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
          },
        ]}
      >
        {activities?.map((item) => (
          <View key={item.name}>
            <ChatArtifactPreview
              artifactName={item.name}
              linkText={LINK_TEXT_BY_ARTIFACT_NAME[item.name]}
              testID={`ChatArtifactPreview_${item.name}`}
              params={{ ...item.params }}
              // @ts-ignore
              _onSideEffect={({ routeName, params }) => navigate(routeName, params)}
              _width={159}
              _height={177}
              _borderColor="transparent"
            />
            <View
              style={{
                ...StyleSheet.absoluteFillObject,
                alignItems: 'center',
                justifyContent: 'center',
                bottom: 50,
              }}
              pointerEvents="none"
            >
              <View
                style={[
                  {
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 50,
                    height: 50,
                    backgroundColor: 'rgba(255,255,255,1)',
                    borderRadius: 50 / 2,
                  },
                  Shadow.high,
                ]}
              >
                <Icon color={theme.color.primary100} name="arrow-right" size={30} />
              </View>
            </View>
          </View>
        ))}
        {(activities?.length ?? 0) % 2 === 1 ? <View style={{ width: 159 }} /> : null}
      </View>
    </View>
  );
}

import { useNavigation, useRoute } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import hexToRgba from 'hex-to-rgba';
import { ComponentProps, memo, useState } from 'react';
import { StyleSheet } from 'react-native';

import { CardStack, FlippableCard as CardStackCard } from '@oui/app-core/src/components/CardStack';
import { Markdown } from '@oui/app-core/src/components/Markdown';
import { ReviewCompleteCard } from '@oui/app-core/src/components/ReviewCompleteCard';
import { RoundedSection } from '@oui/app-core/src/components/RoundedSection';
import { View } from '@oui/app-core/src/components/View';
import { useAddAction } from '@oui/app-core/src/hooks/practices';
import { useCurrentPatient } from '@oui/app-core/src/hooks/useCurrentUser';
import { getStaticReview } from '@oui/lib/src/getStaticReview';
import { ActionType, ContentType, ProductVariant } from '@oui/lib/src/types/graphql.generated';

import { StaticRouteProp } from '../types/navigation';

const ACCENT_COLORS = ['#9b469f', '#202E53', '#1B9FA0'];

const LinearGradientMemo = memo(({ color }: { color: string }) => {
  return (
    <LinearGradient
      colors={[color, hexToRgba('#ffffff', 0.05)]}
      style={[StyleSheet.absoluteFillObject, { top: 40 }]}
      start={[0, 0.3]}
      end={[0, 1]}
    />
  );
});

function InnerCard(props: ComponentProps<typeof View>) {
  return (
    <View
      {...props}
      style={[
        { justifyContent: 'center', minHeight: 80, paddingTop: 20, paddingHorizontal: 20 },
        props.style,
      ]}
    />
  );
}

function getAccentColorIndex(contentType: ContentType) {
  if (([(ContentType.STATIC_01, ContentType.STATIC_02)] as ContentType[]).includes(contentType)) {
    return 0;
  } else if (
    (
      [ContentType.STATIC_10, ContentType.STATIC_11, ContentType.STATIC_12] as ContentType[]
    ).includes(contentType)
  ) {
    return 2;
  }
  return 1;
}

const ACTION_TYPE_BY_CONTENT_TYPE = {
  [ContentType.STATIC_01]: ActionType.STATIC_01,
  [ContentType.STATIC_02]: ActionType.STATIC_02,
  [ContentType.STATIC_03]: ActionType.STATIC_03,
  [ContentType.STATIC_04]: ActionType.STATIC_04,
  [ContentType.STATIC_05]: ActionType.STATIC_05,
  [ContentType.STATIC_06]: ActionType.STATIC_06,
  [ContentType.STATIC_07]: ActionType.STATIC_07,
  [ContentType.STATIC_08]: ActionType.STATIC_08,
  [ContentType.STATIC_09]: ActionType.STATIC_09,
  [ContentType.STATIC_10]: ActionType.STATIC_10,
  [ContentType.STATIC_11]: ActionType.STATIC_11,
  [ContentType.STATIC_12]: ActionType.STATIC_12,
};

export function StaticReview(props: { preview?: boolean }) {
  const { goBack } = useNavigation();
  const { params } = useRoute<StaticRouteProp<'StaticReview'>>();
  const [cardStackKey, setCardStackKey] = useState('0');
  const [addAction] = useAddAction();
  const { user } = useCurrentPatient();

  if (!user) return null;

  const cards =
    getStaticReview({
      contentType: params.slug,
      productSlug: user.role.product.slug as ProductVariant,
      roleType: user.role.roleType,
      productStatic: user.productStatic,
    }) ?? [];

  function markPractice() {
    addAction({
      actionType: ACTION_TYPE_BY_CONTENT_TYPE[params.slug],
    });
  }

  function reset() {
    setCardStackKey((k) => k + '1');
  }
  function done() {
    goBack();
  }

  const accentColor = ACCENT_COLORS[getAccentColorIndex(params.slug)];

  return (
    <View style={{ flex: 1 }}>
      <RoundedSection
        color="white"
        secondaryColor={accentColor}
        bottomSafeAreaColor={hexToRgba(accentColor, 0.95)}
        title={params.sessionTitle}
        preview={props.preview}
        testID="StaticReview"
      >
        {null ?? <LinearGradientMemo color={accentColor} />}
        {cards?.length ? (
          <CardStack
            hideNextButtonIndexes={[cards.length]}
            pageColor="white"
            key={cardStackKey}
            hasCompleteCard
          >
            {cards.map(({ frontChildren, backChildren }, i) => (
              <CardStackCard
                key={i.toString()}
                frontChildren={
                  <InnerCard>
                    {typeof frontChildren === 'string' ? (
                      <Markdown
                        fontFamily="OpenSansSemiBold"
                        style={{
                          root: { fontSize: 21 },
                          paragraph: { textAlign: 'center' },
                        }}
                      >
                        {frontChildren}
                      </Markdown>
                    ) : (
                      frontChildren
                    )}
                  </InnerCard>
                }
                backChildren={
                  <InnerCard>
                    {typeof backChildren === 'string' ? (
                      <Markdown fontFamily="OpenSansSemiBold" style={{ root: { fontSize: 21 } }}>
                        {backChildren}
                      </Markdown>
                    ) : (
                      backChildren
                    )}
                  </InnerCard>
                }
              />
            ))}
            <ReviewCompleteCard
              onDone={done}
              onReset={reset}
              onActive={() => {
                markPractice();
              }}
            />
          </CardStack>
        ) : null}
      </RoundedSection>
    </View>
  );
}

import { QueryResult, useQuery } from '@apollo/client';
import { createContext, ReactNode, useMemo } from 'react';

import { ResultOf, VariablesOf } from '@oui/lib/src/graphql/tada';

import { CurrentUserQuery } from '../hooks/useCurrentUser.query';
import { useIsLoggedIn } from '../hooks/useIsLoggedIn';
import { setUserProperties } from '../lib/log';

export const CurrentUserContext = createContext<
  QueryResult<ResultOf<typeof CurrentUserQuery>, VariablesOf<typeof CurrentUserQuery>> & {
    isAdmin: boolean;
    isLoggedIn: boolean;
  }
>({} as any); // eslint-disable-line

export function CurrentUserProvider({ children }: { children: ReactNode }) {
  const isLoggedIn = useIsLoggedIn();
  const { data, ...rest } = useQuery(CurrentUserQuery, {
    skip: !isLoggedIn,
  });

  const value = useMemo(() => {
    const user = data?.currentUser;

    if (isLoggedIn && user?.user?.__typename === 'Patient') {
      setUserProperties({
        productVersion: user.user.productVersion,
        productVariant: user.user.role.product.slug,
        productSlug: user.user.role.product.slug,
        productStatic: user.user.productStatic,
        roleID: user.user.role.ID,
        onboardingVariant: user.user.onboardingVariant,
      });
    }

    return {
      data,
      ...rest,
      loading: rest.loading && !data?.currentUser,
      isAdmin: !!data?.currentUser?.attributes.admin,
      isLoggedIn: isLoggedIn && !!data?.currentUser,
    };
  }, [data, rest, isLoggedIn]);

  return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>;
}

// Taken from https://github.com/hyakt/expo-multiple-media-imagepicker

import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import type { Subscription } from 'expo-modules-core';
import chunk from 'lodash/chunk';
import { Component, memo, ReactNode, useState } from 'react';
import {
  Alert,
  Dimensions,
  FlatList,
  ImageBackground,
  SectionListProps,
  StyleSheet,
  Text,
  TouchableHighlight,
} from 'react-native';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { Button } from '../components/Button';
import { Icon } from '../components/Icon';
import { View } from '../components/View';
import Sentry from '../sentry';

const { width } = Dimensions.get('window');
const MAX_FILE_SIZE = 30 * 1024 * 1024;

export const ImageTile = memo(function ImageTile({
  item,
  index,
  selected,
  selectImage,
  selectedItemCount,
  badgeColor,
}: {
  item: MediaLibrary.Asset;
  index: number;
  selected: boolean;
  selectImage: (i: number) => Promise<{ isAllowed: boolean }>;
  selectedItemCount: number;
  badgeColor: string;
}) {
  const [isSelecting, setIsSelecting] = useState(false);
  const [isUnavailable, setIsUnavailable] = useState(false);
  const [isRestricted, setIsRestricted] = useState(false);
  if (!item) return null;
  return (
    <TouchableHighlight
      style={{ opacity: selected ? 0.8 : 1 }}
      underlayColor="transparent"
      onPress={() => {
        async function onPress() {
          if (isRestricted) {
            return MediaLibrary.requestPermissionsAsync();
          }

          setIsSelecting(true);
          const { isAllowed } = await selectImage(index);
          if (!isAllowed) {
            setIsUnavailable(true);
          }
          setIsSelecting(false);
          return;
        }
        void onPress();
      }}
    >
      <View style={{ position: 'relative' }}>
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <ImageBackground
            style={{ width: width / 4, height: width / 4 }}
            source={{ uri: item.uri }}
            onError={(e) => {
              if (e.nativeEvent.error.includes('Cocoa error')) {
                // limited photo access. user hasn't selected this photo
                setIsRestricted(true);
              }
            }}
            resizeMethod="resize"
          >
            {item.mediaType === MediaLibrary.MediaType.video ? (
              <View style={{ width: '100%', height: '100%', justifyContent: 'center' }} row>
                <View
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: 25,
                    backgroundColor: 'rgba(255,255,255,0.3)',
                    justifyContent: 'center',
                  }}
                  row
                >
                  <Icon name="play" />
                </View>
              </View>
            ) : null}
            {selected && (
              <View style={[styles.countBadge, { backgroundColor: badgeColor }]}>
                <Text style={styles.countBadgeText}>{selectedItemCount}</Text>
              </View>
            )}
          </ImageBackground>
        </View>
        {isSelecting ? (
          <View
            style={[
              {
                position: 'absolute',
                right: 5,
                bottom: 5,
                backgroundColor: 'rgba(0,0,0,0.3)',
                padding: 5,
                borderRadius: 20,
              },
            ]}
          >
            <ActivityIndicator />
          </View>
        ) : isUnavailable || isRestricted ? (
          <View
            style={[
              {
                position: 'absolute',
                right: 5,
                bottom: 5,
                borderRadius: 20,
              },
            ]}
          >
            <Icon name="lock" />
          </View>
        ) : null}
      </View>
    </TouchableHighlight>
  );
});

export class MultipleMediaLibraryOld extends Component<
  {
    badgeColor: string;
    callback: (assets: MediaLibrary.AssetInfo[]) => void;
    emptyText?: string;
    headerButtonColor?: string;
    headerSelectText?: string;
    loadingColor?: string;
    max: number;
    mediaSubtype?: MediaLibrary.MediaSubtype;
    render?: (renderData: Partial<SectionListProps<MediaLibrary.Asset[]>>) => ReactNode;
    immediateCallback?: boolean;
  },
  {
    photos: MediaLibrary.Asset[];
    selected: number[];
    after: string | null;
    hasNextPage: boolean;
    isLoading: boolean;
  }
> {
  assetInfosById: Record<string, Promise<MediaLibrary.AssetInfo>> = {};
  mediaListener: Subscription | undefined;

  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: false,
      photos: [],
      selected: [],
      after: null,
      hasNextPage: true,
    };
  }

  componentDidMount() {
    this.getPhotos();
    this.mediaListener = MediaLibrary.addListener((data) => {
      console.log(data);
    });
  }

  componentWillUnmount() {
    this.mediaListener?.remove();
  }

  selectImage = async (index: number): Promise<{ isAllowed: boolean }> => {
    const asset = this.state.photos[index];
    const assetInfoPromise = this.assetInfosById[asset.id] ?? MediaLibrary.getAssetInfoAsync(asset);
    this.assetInfosById[asset.id] = assetInfoPromise;
    const assetInfo = await assetInfoPromise;
    const fileInfo = await FileSystem.getInfoAsync(assetInfo.localUri ?? assetInfo.uri);

    const validSize = fileInfo.exists && fileInfo.size && fileInfo.size < MAX_FILE_SIZE;

    if (validSize) {
      let newSelected = Array.from(this.state.selected);

      if (newSelected.indexOf(index) === -1) {
        newSelected.push(index);
      } else {
        const deleteIndex = newSelected.indexOf(index);
        newSelected.splice(deleteIndex, 1);
      }

      if (newSelected.length > this.props.max) {
        Alert.alert("You've reached the maximum number of allowed selections.");
        return { isAllowed: false };
      }
      if (newSelected.length === 0) newSelected = [];

      this.setState({ selected: newSelected });
      if (this.props.immediateCallback) this.props.callback([assetInfo]);
      return { isAllowed: true };
    }

    Alert.alert('The selected media is too large. Please choose a different file.');
    return { isAllowed: false };
  };

  getPhotos = () => {
    let params: MediaLibrary.AssetsOptions = {
      first: 40,
      mediaType: [MediaLibrary.MediaType.video, MediaLibrary.MediaType.photo],
      sortBy: [MediaLibrary.SortBy.modificationTime],
    };
    if (this.state.after) params.after = this.state.after;
    if (!this.state.hasNextPage) return;
    this.setState({ isLoading: true });
    MediaLibrary.getAssetsAsync(params)
      .then((assets) => {
        return this.processPhotos(assets);
      })
      .catch((e) => Sentry.captureException(e));
  };

  processPhotos = async (assets: MediaLibrary.PagedInfo<MediaLibrary.Asset>) => {
    if (this.state.after === assets.endCursor) return;

    let displayAssets;
    if (!this.props.mediaSubtype) {
      displayAssets = assets.assets;
    } else {
      displayAssets = assets.assets.filter((asset) => {
        return asset.mediaSubtypes?.includes(this.props.mediaSubtype!);
      });
    }

    const originalLength = displayAssets.length;

    Sentry.addBreadcrumb({
      type: 'media-library',
      message: 'processPhotos',
      data: {
        originalLength,
        currentLength: this.state.photos.length,
        appendLength: displayAssets.length,
        sample: displayAssets[0],
      },
    });

    this.setState({
      photos: [...this.state.photos, ...displayAssets],
      after: assets.endCursor,
      hasNextPage: assets.hasNextPage,
      isLoading: false,
    });
  };

  getItemLayout = (_data: unknown, index: number) => {
    let length = width / 4;
    return { length, offset: length * index, index };
  };

  prepareCallback = async () => {
    let { selected, photos } = this.state;
    const selectedPhotos = selected.map((i) => {
      return this.assetInfosById[photos[i].id];
    });
    this.props.callback(await Promise.all(selectedPhotos));
  };

  renderHeader = () => {
    let selectedCount = this.state.selected.length;

    let headerText = `${selectedCount} ${
      this.props.headerSelectText ? this.props.headerSelectText : 'Selected'
    }`;
    if (selectedCount === this.props.max) headerText = headerText + ' (Max)';
    const headerButtonColor = this.props.headerButtonColor
      ? this.props.headerButtonColor
      : '#007aff';

    return (
      <SafeAreaInsetsContext.Consumer>
        {(insets) => (
          <View style={[styles.header, { paddingTop: insets?.top }]}>
            <View style={{ width: 100 }}>
              <Button
                aria-label="Cancel and go back"
                color={headerButtonColor}
                icon="close"
                onPress={() => this.props.callback([])}
                variant="text"
              />
            </View>
            <Text style={styles.headerText}>{headerText}</Text>
            <View style={{ width: 100 }}>
              <Button
                color={headerButtonColor}
                text="Done"
                onPress={() => this.prepareCallback()}
                alignSelf="flex-end"
                style={{ paddingHorizontal: 10 }}
              />
            </View>
          </View>
        )}
      </SafeAreaInsetsContext.Consumer>
    );
  };

  renderImageTile = ({ item, index }: { item?: MediaLibrary.Asset; index: number }) => {
    const selected = this.state.selected.indexOf(index) !== -1;
    const selectedItemCount = this.state.selected.indexOf(index) + 1;

    return item ? (
      <ImageTile
        key={item.id}
        item={item}
        selectedItemCount={selectedItemCount}
        index={index}
        selected={selected}
        selectImage={this.selectImage}
        badgeColor={this.props.badgeColor}
      />
    ) : null;
  };

  renderImageTileRow = ({ item: row, index }: { item: MediaLibrary.Asset[]; index: number }) => {
    return (
      <View row style={{ backgroundColor: 'white' }}>
        {row.map((item, i) => this.renderImageTile({ item, index: index * 4 + i }))}
      </View>
    );
  };

  renderLoading = () => {
    return (
      <View style={styles.emptyContent}>
        <ActivityIndicator
          size="large"
          color={this.props.loadingColor ? this.props.loadingColor : '#bbb'}
        />
      </View>
    );
  };

  renderEmpty = () => {
    return this.state.isLoading ? (
      this.renderLoading()
    ) : (
      <View style={styles.emptyContent}>
        <Text style={styles.emptyText}>
          {this.props.emptyText ? this.props.emptyText : 'No media available'}
        </Text>
      </View>
    );
  };

  renderImages = () => {
    return (
      <FlatList
        contentContainerStyle={{ flexGrow: 1 }}
        data={this.state.photos}
        numColumns={4}
        renderItem={this.renderImageTile}
        keyExtractor={(_, index) => index.toString()}
        onEndReached={() => {
          this.getPhotos();
        }}
        onEndReachedThreshold={0.5}
        ListEmptyComponent={this.renderEmpty}
        initialNumToRender={24}
        getItemLayout={this.getItemLayout}
      />
    );
  };

  render() {
    if (this.props.render) {
      return this.props.render({
        sections: [
          {
            title: '',
            data: chunk(this.state.photos, 4),
          },
        ],
        renderItem: this.renderImageTileRow,
        keyExtractor: (row) => row.map((item) => item.id).join('-'),
        onEndReached: () => this.getPhotos(),
        onEndReachedThreshold: 0.5,
        ListEmptyComponent: this.renderEmpty,
        initialNumToRender: 24,
        getItemLayout: this.getItemLayout,
      });
    }

    return (
      <View style={styles.container}>
        {this.renderHeader()}
        {this.renderImages()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    width: width,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 19,
  },
  emptyContent: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyText: {
    color: '#bbb',
    fontSize: 20,
  },
  countBadge: {
    minWidth: 20,
    minHeight: 20,
    borderRadius: 50,
    position: 'absolute',
    right: 3,
    bottom: 3,
    justifyContent: 'center',
  },
  countBadgeText: {
    color: '#fff',
    fontWeight: 'bold',
    alignSelf: 'center',
    padding: 'auto',
  },
});

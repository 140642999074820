import { produce } from 'immer';
import { ComponentProps, createRef, ReactNode } from 'react';
import {
  AccessibilityInfo,
  Animated,
  findNodeHandle,
  I18nManager,
  PixelRatio,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import { renderers, MenuOption as RNMenuOption, withMenuContext } from 'react-native-popup-menu';

import { Shadow } from '../styles';

export {
  Menu,
  MenuTrigger,
  MenuOptions,
  withMenuContext,
  MenuContext,
} from 'react-native-popup-menu';

// https://github.com/instea/react-native-popup-menu/blob/master/doc/extensions.md#custom-renderer
class ContextMenuInner extends (renderers.ContextMenu as any) {
  menuRef = createRef<View>();

  componentDidMount() {
    super.componentDidMount();
    this._focusRef(this.menuRef.current);
  }

  _focusRef = (ref: any) => {
    if (ref) {
      const handle = findNodeHandle(ref);
      if (handle) {
        AccessibilityInfo.setAccessibilityFocus(handle);
      }
    }
  };

  render() {
    const { style, customStyle, children, layouts, ...other } = this.props;
    const animation = {
      transform: [{ scale: this.state.scaleAnim }],
      opacity: this.state.scaleAnim,
    };
    const newLayouts = produce(layouts, (draft: any) => {
      // We want to render our ContextMenus just below the trigger (with some padding)
      draft.triggerLayout.y = draft.triggerLayout.y + draft.triggerLayout.height + 5;
    });
    const position = (renderers.ContextMenu as any).computePosition(newLayouts, I18nManager.isRTL);
    return (
      <Animated.View
        {...other}
        ref={this.menuRef}
        style={[
          styles.options,
          style,
          customStyle,
          animation,
          position,
          Shadow.high,
          // for some reason shadowColor doesn't work with a Gray1 hex value here on web
          Platform.OS === 'web'
            ? {
                shadowColor: '#000000',
              }
            : undefined,
        ]}
        role="menu"
        aria-label="Menu"
        accessibilityActions={[{ name: 'activate', label: 'Close' }]}
        onAccessibilityAction={(e) => {
          if (e.nativeEvent.actionName === 'activate') {
            const openMenu = this.props.ctx.menuRegistry
              .getAll()
              .find((m: any) => m.instance._opened);
            const trigger = openMenu?.instance._getTrigger();
            this.props.ctx.menuActions.closeMenu();
            // The menu trigger is the child of the trigger (see ref linked)
            // https://github.com/instea/react-native-popup-menu/blob/5e545c808c9c9aa05a6ed9478f79f48a7fef8e8f/src/MenuTrigger.js#L22
            this._focusRef(trigger._children[0]);
          }
        }}
      >
        {children}
      </Animated.View>
    );
  }
}

// @ts-expect-error
export const ContextMenu = withMenuContext(ContextMenuInner);

export function MenuOption(
  props: ComponentProps<typeof RNMenuOption> & {
    children?: ReactNode;
    testID?: string; // not present on RNMenuOption type but it is valid
  },
) {
  return (
    <RNMenuOption
      {...props}
      customStyles={{
        ...props.customStyles,
        optionTouchable: { accessibilityRole: 'menuitem', ...props.customStyles?.optionTouchable },
      }}
    />
  );
}

export const styles = StyleSheet.create({
  options: {
    position: 'absolute',
    borderRadius: 2,
    backgroundColor: 'white',
    width: PixelRatio.roundToNearestPixel(200),

    // Shadow only works on iOS.
    shadowColor: 'black',
    shadowOpacity: 0.3,
    shadowOffset: { width: 3, height: 3 },
    shadowRadius: 4,

    // This will elevate the view on Android, causing shadow to be drawn.
    elevation: 5,
  },
});

import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { RiskCurveGraph } from '@oui/myplan/src/components/RiskCurve';

import {
  ExplanationVideos,
  PatientMyStoryMyPlanContainer,
  PatientMyStoryMyPlanContainerSection,
  PatientMyStoryMyPlanQuestionAnswer,
} from '@src/components/PatientMyStoryMyPlanContainer';

export function PatientMyPlanIntroduction() {
  const { $t } = useI18n();
  return (
    <PatientMyStoryMyPlanContainer
      heading={$t({ id: 'PatientMyPlanIntroduction_heading', defaultMessage: 'MyPlan' })}
      testID="PatientMyPlanIntroduction"
    >
      <PatientMyStoryMyPlanContainerSection>
        <PatientMyStoryMyPlanQuestionAnswer
          question={$t({
            id: 'PatientMyPlanIntroduction_question1',
            defaultMessage: 'What is it?',
          })}
          answer={$t({
            id: 'PatientMyPlanIntroduction_answer1',
            defaultMessage: 'A plan you can use to stop the risk curve getting too high.',
          })}
        />
      </PatientMyStoryMyPlanContainerSection>
      <PatientMyStoryMyPlanContainerSection alternate>
        <View
          style={{ backgroundColor: 'white', padding: 16, paddingBottom: 8, marginHorizontal: -16 }}
        >
          <RiskCurveGraph preview crisisPeakID="" timeline={[]} numRiskFactors={0} />
        </View>
      </PatientMyStoryMyPlanContainerSection>
      <PatientMyStoryMyPlanContainerSection>
        <View spacing={24}>
          <PatientMyStoryMyPlanQuestionAnswer
            question={$t({
              id: 'PatientMyPlanIntroduction_question2',
              defaultMessage: 'Why is this important?',
            })}
            answer={$t({
              id: 'PatientMyPlanIntroduction_answer2',
              defaultMessage:
                'During a crisis, often it is hard to think what to do. MyPlan has all the steps you can take to keep you safe.',
            })}
          />
          <PatientMyStoryMyPlanQuestionAnswer
            question={$t({
              id: 'PatientMyPlanIntroduction_question3',
              defaultMessage: 'How does it work?',
            })}
            answer={$t({
              id: 'PatientMyPlanIntroduction_answer3',
              defaultMessage:
                'When your warning signs tell you your risk is rising, you can use the coping steps in the plan. And if you feel the risk still rising, the plan has a number of ways to help you.',
            })}
          />
        </View>
      </PatientMyStoryMyPlanContainerSection>
      <ExplanationVideos
        videos={[
          {
            caption: $t({
              id: 'PatientMyPlanIntroduction_voiceoverCaption',
              defaultMessage: "See how we'll create MyPlan and how it'll keep you safe.",
            }),
            slug: 'MYPLAN_VOICEOVER',
          },
          {
            caption: $t({
              id: 'PatientMyPlanIntroduction_vignetteCaption',
              defaultMessage: 'Watching how others make their MyPlan may help you create yours.',
            }),
            slug: 'MYPLAN_LEARN_VIGNETTE',
          },
        ]}
      />
    </PatientMyStoryMyPlanContainer>
  );
}

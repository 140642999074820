import { ReactNode, useEffect, useMemo } from 'react';

import { MandatoryModal } from '@oui/app-core/src/components/Modal';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useCurrentPatient } from '@oui/app-core/src/hooks/useCurrentUser';
import { ProductVersion } from '@oui/lib/src/types/graphql.generated';

import Sentry from '@src/sentry';

export function RequiredClinicianUpgrade({ children }: { children: ReactNode }) {
  const { user } = useCurrentPatient();

  const requiresAppUpgrade = useMemo(() => {
    const deprecatedVersions: ProductVersion[] = [
      ProductVersion.AVIVA_1_0,
      ProductVersion.AVIVA_1_1,
    ];

    if (user && deprecatedVersions.includes(user.productVersion)) {
      return true;
    }

    return false;
  }, [user]);

  useEffect(() => {
    if (requiresAppUpgrade) {
      Sentry.captureMessage('User logged in with deprecated ProductVersion');
    }
  }, [requiresAppUpgrade]);

  return (
    <>
      {requiresAppUpgrade ? (
        <MandatoryModal visible={true}>
          <View spacing={16} style={{ alignItems: 'center' }}>
            <Text text="Update Required" weight="bold" size={21} />
            <Text
              text={`Your version of Aviva has been discontinued.

Please contact your clinician to upgrade and continue using Aviva.`}
              textAlign="center"
            />
          </View>
        </MandatoryModal>
      ) : null}
      {children}
    </>
  );
}

import {
  Children,
  cloneElement,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { AccessibilityInfo, findNodeHandle, Platform, View as RNView } from 'react-native';
import { PanGestureHandler, PanGestureHandlerGestureEvent } from 'react-native-gesture-handler';
import Animated, {
  Extrapolate,
  interpolate,
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';

import { useAccessibilityContext } from '../components/AccessibilityContext';
import { Button } from '../components/Button';
import { Icon } from '../components/Icon';
import { Text } from '../components/Text';
import { View } from '../components/View';
import { useI18n } from '../lib/i18n';
import { card, Shadow, useTheme } from '../styles';

type Props = {
  hideNextButtonIndexes?: number[] | true;
  header?: ReactNode;
  children: ReactNode;
  onIndexChanged?: (index: number) => void;
  pageColor?: string;
  hasCompleteCard?: boolean;
};

export type CardStackRef = { next: () => void; prev: () => void; setIndex: (i: number) => void };
export type InternalCardProps = {
  animatedIndex: Animated.SharedValue<number>;
  header: ReactNode;
  hideNextButtonIndexes: number[] | true;
  index: number;
  count: number;
  next?: () => void;
  onHeight: (h: number) => void;
  stackHeight: Animated.SharedValue<number>;
  // used by FlippableCard
  renderFooter?: () => ReactNode;
  isInactive?: boolean;
  flippable?: boolean;
  // used by QuizSet
  _disableGesture?: true;
  _padding?: number;
};
type ExternalCardProps = {
  header?: ReactNode;
  children?: ReactNode;
};

const BASE_CARD_STYLE = card;

function InternalCard(props: InternalCardProps & ExternalCardProps) {
  const [height, setHeight] = useState(0);
  const a11yRef = useRef<RNView>(null);
  // Before implementing this we need to re-implement ReviewCompleteCard.onReset to be compatible
  // currently reset changes the CardStack key so we dont know we do need to focus in that scenario
  // const isFirstTimeVisibleRef = useRef(props.index === 0);
  const onHeightRef = useRef(props.onHeight);
  onHeightRef.current = props.onHeight;
  const isInvisible = props.index !== props.animatedIndex.value;
  const translateX = useSharedValue(0);
  const [isGestureEnabled, setIsGestureEnabled] = useState(true);
  const { $t } = useI18n();

  const propsNext = props.next;
  const next = useCallback(() => {
    setIsGestureEnabled(false);
    propsNext?.();
  }, [propsNext]);

  useEffect(() => {
    if (!isInvisible) {
      translateX.value = 0;
      setIsGestureEnabled(true);
    }
  }, [translateX, isInvisible]);

  const gestureHandler = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    { startX: number }
  >(
    {
      onStart: (_, ctx) => {
        ctx.startX = translateX.value;
      },
      onActive: (event, ctx) => {
        translateX.value = ctx.startX + event.translationX;
      },
      onEnd: (_) => {
        const direction = translateX.value >= 0 ? 1 : -1;
        if (next && Math.abs(translateX.value) > 100) {
          translateX.value = withTiming(direction * 400);
          runOnJS(next)();
        } else {
          translateX.value = withSpring(0);
        }
      },
    },
    [next],
  );

  const { animatedIndex, stackHeight, index } = props;
  const fakeCardStyle = useAnimatedStyle(() => {
    return {
      height: stackHeight.value,
      backgroundColor: 'white',
      opacity:
        stackHeight.value === 0 || stackHeight.value < height
          ? withTiming(
              interpolate(
                animatedIndex.value,
                [index - 3, index - 2, index - 1, index, index + 1],
                [0, 0.9, 1, 1, 0],
                {
                  extrapolateLeft: Extrapolate.CLAMP,
                  extrapolateRight: Extrapolate.CLAMP,
                },
              ),
            )
          : withTiming(0),
      transform: [
        {
          scaleX: withTiming(
            interpolate(
              animatedIndex.value,
              [index - 2, index - 1, index, index + 1],
              [0.9, 0.95, 0, 0],
              {
                extrapolateLeft: Extrapolate.CLAMP,
                extrapolateRight: Extrapolate.CLAMP,
              },
            ),
          ),
        },
        {
          translateY: withTiming(
            interpolate(
              animatedIndex.value,
              [index - 2, index - 1, index, index + 1],
              [-20, -10, 0, 100],
              {
                extrapolateLeft: Extrapolate.CLAMP,
                extrapolateRight: Extrapolate.CLAMP,
              },
            ),
          ),
        },
        { translateX: translateX.value },
      ],
    };
  }, [height, index, stackHeight, animatedIndex]);

  const style = useAnimatedStyle(() => {
    return {
      opacity: withTiming(
        interpolate(
          animatedIndex.value,
          [index - 3, index - 2, index - 1, index, index + 1],
          stackHeight.value === 0 || stackHeight.value < height
            ? [0, 0, 0, 1, 0]
            : [0, 0.9, 1, 1, 0],
          {
            extrapolateLeft: Extrapolate.CLAMP,
            extrapolateRight: Extrapolate.CLAMP,
          },
        ),
      ),
      transform: [
        {
          scaleX: withTiming(
            interpolate(
              animatedIndex.value,
              [index - 2, index - 1, index, index + 1],
              [0.9, 0.95, 1, 1],
              {
                extrapolateLeft: Extrapolate.CLAMP,
                extrapolateRight: Extrapolate.CLAMP,
              },
            ),
          ),
        },
        {
          translateY: withTiming(
            interpolate(
              animatedIndex.value,
              [index - 2, index - 1, index, index + 1],
              [-20, -10, 0, 100],
              {
                extrapolateLeft: Extrapolate.CLAMP,
                extrapolateRight: Extrapolate.CLAMP,
              },
            ),
          ),
        },
        { translateX: translateX.value },
      ],
    };
  }, [height, index, stackHeight, animatedIndex]);

  const isActive = !isInvisible && !props.isInactive;
  useEffect(() => {
    if (height && isActive) {
      // Set current active height of the card stack
      onHeightRef.current?.(height);

      // handle a11y focus
      // if (a11yRef.current && !isFirstTimeVisibleRef.current) {
      if (a11yRef.current) {
        const handle = findNodeHandle(a11yRef.current!);
        if (handle) {
          AccessibilityInfo.setAccessibilityFocus(handle);
        }
      }
      // isFirstTimeVisibleRef.current = false;
    }
  }, [height, isActive]);

  return (
    <View
      // Previously on Android we were able to forego adding zIndex and maintain proper
      // visual stacking of cards. Recently something changed that forces us to add zIndex.
      // To maintain onPress behavior of child Picker components, we need to expand the View
      // so that the children stay inside of this parent's container. At the same time,
      // we don't want this container to capture pointer events because then cards earlier
      // in the stack that have visually been swiped off will leave their containers behind
      // and we won't be able to press through since the animation happens inside this View.
      style={{
        zIndex: 100 - props.index,
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
      pointerEvents="box-none"
    >
      <Animated.View
        aria-hidden
        pointerEvents="none"
        style={[
          BASE_CARD_STYLE,
          fakeCardStyle,
          Platform.OS === 'web'
            ? null
            : props.index <= props.animatedIndex.value + 2
              ? Shadow.high
              : Shadow.low,
          {
            padding: props._padding ?? 25,
            position: 'absolute',
            width: '100%',
          },
        ]}
      />
      <PanGestureHandler
        onGestureEvent={gestureHandler}
        enabled={!props.flippable && isGestureEnabled && props._disableGesture !== true}
        activeOffsetX={[-10, 10]}
      >
        <Animated.View
          onLayout={(e) => {
            if (height !== e.nativeEvent.layout.height) {
              setHeight(e.nativeEvent.layout.height);
            }
          }}
          style={[
            BASE_CARD_STYLE,
            Platform.OS === 'web'
              ? null
              : props.index <= props.animatedIndex.value + 2
                ? Shadow.high
                : Shadow.low,
            {
              backgroundColor: 'white',
              padding: props._padding ?? 25,
              position: 'absolute',
              width: '100%',
              zIndex: 10 - props.index,
              // same elevation as card, but with a float value added to properly stack
              elevation: 6 + (100 - props.index) / 10,
            },
            style,
          ]}
          pointerEvents={isInvisible ? 'none' : undefined}
          aria-hidden={isInvisible}
          testID={isInvisible || props.isInactive ? undefined : 'CardStack_activeCard'}
        >
          <View
            ref={a11yRef}
            accessible
            aria-label={
              props.index === props.count
                ? $t({ id: 'CardStack_endAccessibilityLabel', defaultMessage: 'End of cards' })
                : $t(
                    {
                      id: 'CardStack_indexAccessibilityLabel',
                      defaultMessage: 'Card {index} of {count}',
                    },
                    { index: props.index + 1, count: props.count },
                  )
            }
            role="heading"
            // We need an accessible ref to focus on when card becomes active. However, we cannot
            // use the parent View because on iOS it doesn't allow swiping to focus on children
            style={{ height: 5, width: '100%', marginBottom: -4 }}
          />
          <View spacing={30}>
            {props.header}
            {props.children}
            {props.renderFooter ? (
              props.renderFooter()
            ) : props.hideNextButtonIndexes === true ||
              props.hideNextButtonIndexes?.includes(props.index) ? null : (
              <Button
                testID={isInvisible ? undefined : 'CardStack_nextButton'}
                _useGestureHandler
                aria-label={$t({
                  id: 'CardStack_nextCardAccessibilityLabel',
                  defaultMessage: 'Next card',
                })}
                text={$t({ id: 'CardStack_nextButton', defaultMessage: 'Next' })}
                alignSelf="center"
                onPress={() => {
                  translateX.value = withTiming(400);
                  next();
                }}
              />
            )}
          </View>
        </Animated.View>
      </PanGestureHandler>
    </View>
  );
}

export function Card(
  props: ExternalCardProps & {
    _disableGesture?: true;
    _padding?: number;
  },
) {
  // eslint-disable-next-line
  return <InternalCard {...(props as any as InternalCardProps)} />;
}

export function FlippableCard(props: {
  frontChildren?: ReactNode;
  backChildren?: ReactNode;
  onReset?: () => void;
}) {
  const { isScreenReaderEnabled } = useAccessibilityContext();
  const height = useSharedValue(0);
  const [flipped, setFlipped] = useState(false);
  const rotation = useSharedValue(0);
  const { $t } = useI18n();
  const gestureHandler = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    { startX: number }
  >(
    {
      onStart: (_, ctx) => {
        ctx.startX = rotation.value;
      },
      onActive: (event, ctx) => {
        rotation.value = ctx.startX + event.translationX;
      },
      onEnd: (_) => {
        const direction = rotation.value >= 0 ? 1 : -1;
        const THRESHOLD = 70;
        const adjustedValue = Math.abs(rotation.value);
        if (adjustedValue > THRESHOLD) {
          rotation.value = withTiming(direction * 180);
          runOnJS(setFlipped)(true);
        } else {
          rotation.value = withSpring(0);
          runOnJS(setFlipped)(false);
        }
      },
    },
    [flipped],
  );

  const propsOnHeight = (props as InternalCardProps).onHeight;
  const onHeight = useCallback(
    (h: number) => {
      height.value = withTiming(h);
      propsOnHeight?.(h);
    },
    [height, propsOnHeight],
  );

  const style = useAnimatedStyle(() => {
    const zIndex = interpolate(rotation.value, [-91, -90, 90, 91], [0, 1, 1, 0], {
      extrapolateLeft: Extrapolate.CLAMP,
      extrapolateRight: Extrapolate.CLAMP,
    });
    return {
      zIndex,
      elevation: zIndex,
      opacity: zIndex,
      transform: [
        { perspective: 800 },
        { rotateY: interpolate(rotation.value, [0, 180], [0, 180]) + 'deg' },
      ],
    };
  }, [flipped]);

  const backStyle = useAnimatedStyle(() => {
    const zIndex = interpolate(rotation.value, [-91, -90, 90, 91], [1, 0, 0, 1], {
      extrapolateLeft: Extrapolate.CLAMP,
      extrapolateRight: Extrapolate.CLAMP,
    });
    return {
      zIndex,
      elevation: zIndex,
      opacity: zIndex,
      transform: [
        { perspective: 800 },
        { rotateY: interpolate(rotation.value, [0, 180], [-180, 0]) + 'deg' },
      ],
    };
  }, [flipped]);

  const internalProps = props as InternalCardProps;
  const { index } = internalProps;
  const wrapperStyle = useAnimatedStyle(() => {
    return {
      zIndex: 100 - index,
      position: 'absolute',
      height: height.value,
      width: '100%',
    };
  }, [height, index]);
  const isInvisible = internalProps.index !== internalProps.animatedIndex.value;

  useEffect(() => {
    // If coming back to a card we want to reset state
    if (!isInvisible) {
      rotation.value = 0;
      setFlipped(false);
      if (height.value) {
        (props as InternalCardProps).onHeight?.(height.value);
      }
    }
    // eslint-disable-next-line
  }, [rotation, isInvisible, height]);

  return isScreenReaderEnabled ? (
    <InternalCard
      // eslint-disable-next-line
      {...(props as any)}
      renderFooter={
        internalProps.next || !props.onReset
          ? undefined
          : () => {
              return (
                <Button
                  testID="CardStack_resetButton"
                  _useGestureHandler
                  icon="retry"
                  aria-label={$t({
                    id: 'CardStack_resetButtonAccessibilityLabel',
                    defaultMessage: 'Reset cards',
                  })}
                  text={$t({ id: 'CardStack_resetButton', defaultMessage: 'Reset' })}
                  alignSelf="center"
                  onPress={() => {
                    props.onReset?.();
                  }}
                />
              );
            }
      }
    >
      {props.frontChildren}
      {props.backChildren}
    </InternalCard>
  ) : (
    <PanGestureHandler
      onGestureEvent={gestureHandler}
      enabled={!flipped && props.backChildren !== null}
    >
      <Animated.View pointerEvents={isInvisible ? 'none' : undefined} style={wrapperStyle}>
        {flipped ? null : (
          <Animated.View style={style}>
            <InternalCard
              // eslint-disable-next-line
              {...(props as any)}
              flippable={!flipped && props.backChildren !== null}
              renderFooter={
                props.backChildren === null
                  ? null
                  : () => {
                      return (
                        <Button
                          testID={isInvisible ? undefined : 'CardStack_flipButton'}
                          _useGestureHandler
                          icon="retry"
                          text={$t({
                            id: 'CardStack_flippableCard_flipButton',
                            defaultMessage: 'Flip',
                          })}
                          alignSelf="center"
                          onPress={() => {
                            rotation.value = withTiming(180);
                            setFlipped(true);
                          }}
                        />
                      );
                    }
              }
              onHeight={onHeight}
            >
              {props.frontChildren}
            </InternalCard>
          </Animated.View>
        )}
        <Animated.View
          style={backStyle}
          aria-hidden={isInvisible || !flipped}
          pointerEvents={isInvisible || !flipped ? 'none' : undefined}
        >
          <InternalCard
            // eslint-disable-next-line
            {...(props as any)}
            flippable={!flipped}
            isInactive={!flipped}
            onHeight={onHeight}
            renderFooter={
              internalProps.next || !props.onReset
                ? undefined
                : () => {
                    return (
                      <Button
                        testID="CardStack_resetButton"
                        _useGestureHandler
                        icon="retry"
                        text={$t({
                          id: 'CardStack_resetButton',
                          defaultMessage: 'Reset',
                        })}
                        alignSelf="center"
                        onPress={() => {
                          props.onReset?.();
                        }}
                      />
                    );
                  }
            }
          >
            {props.backChildren}
          </InternalCard>
        </Animated.View>
      </Animated.View>
    </PanGestureHandler>
  );
}

export const CardStack = forwardRef<CardStackRef, Props>((props: Props, ref) => {
  const { theme } = useTheme();
  const [i, setI] = useState(0);
  const iRef = useRef(0);
  iRef.current = i;
  const index = useSharedValue(0);
  const initializedRef = useRef(false);
  const currentHeight = useSharedValue(0);
  const isChangingIndexRef = useRef(false);
  const onIndexChangedRef = useRef(props.onIndexChanged);
  onIndexChangedRef.current = props.onIndexChanged;
  const { $t } = useI18n();

  const children = Children.toArray(props.children);
  // prefer array length over React.Children.Count b/c of null values
  const count = children.length;

  useEffect(() => {
    if (initializedRef.current) {
      isChangingIndexRef.current = false;
      onIndexChangedRef?.current?.(i);
    }
  }, [i]);

  useEffect(() => {
    if (initializedRef.current) {
      onIndexChangedRef?.current?.(index.value);
    } else {
      initializedRef.current = true;
    }
  }, [index]);

  const setIndex = useCallback(
    (v: number) => {
      if (!isChangingIndexRef.current && iRef.current !== v) {
        isChangingIndexRef.current = true;
        index.value = withTiming(v, {}, () => {
          runOnJS(setI)(v);
        });
      }
    },
    [index],
  );
  const next = useCallback(() => {
    setIndex(Math.min(count - 1, i + 1));
  }, [i, count, setIndex]);
  const prev = useCallback(() => {
    setIndex(Math.max(0, i - 1));
  }, [i, setIndex]);

  useEffect(() => {
    if (initializedRef.current && count <= i) {
      setIndex(count - 1);
    }
  }, [setIndex, i, count]);

  useImperativeHandle(
    ref,
    () => ({
      prev,
      next,
      setIndex,
    }),
    [prev, next, setIndex],
  );

  const pageNavStyle = useAnimatedStyle(() => {
    return {
      alignSelf: 'center',
      marginTop: withTiming(currentHeight.value + 20),
    };
  }, []);

  return (
    <View style={{ marginTop: 15 }}>
      {children.map((c, j) =>
        cloneElement(
          c as any, // eslint-disable-line
          {
            index: j,
            count: props.hasCompleteCard ? count - 1 : count,
            animatedIndex: index,
            // eslint-disable-next-line
            header: (c as any).props?.header ?? props.header,
            hideNextButtonIndexes: props.hideNextButtonIndexes,
            next: j < count - 1 ? next : undefined,
            onHeight: (h: number) => {
              currentHeight.value = h;
            },
            stackHeight: currentHeight,
          } as InternalCardProps,
        ),
      )}
      {props.hasCompleteCard && i === count - 1 ? null : (
        <Animated.View style={pageNavStyle}>
          <View row spacing={24}>
            <Icon
              disabled={i === 0}
              name="caret-left"
              color={props.pageColor ?? theme.color.gray400}
              onPress={prev}
              aria-label={$t({
                id: 'CardStack_previousButtonAccessibilityLabel',
                defaultMessage: 'Previous',
              })}
            />
            <Text
              text={$t(
                { id: 'CardStack_progress', defaultMessage: '{index} of {count}' },
                {
                  index: i + 1,
                  count: props.hasCompleteCard ? count - 1 : count,
                },
              )}
              testID={`CardStack_progress_${i + 1}_of_${props.hasCompleteCard ? count - 1 : count}`}
              color={props.pageColor ?? theme.color.gray200}
            />
            <Icon
              disabled={i === count - 1}
              name="caret-right"
              color={props.pageColor ?? theme.color.gray400}
              onPress={next}
              aria-label={$t({
                id: 'CardStack_nextButtonAccessibilityLabel',
                defaultMessage: 'Next',
              })}
            />
          </View>
        </Animated.View>
      )}
    </View>
  );
});

import { useNavigation } from '@react-navigation/native';

import { SegmentedProgressBar } from '@oui/app-core/src/components/SegmentedProgressBar';
import { SessionCard, SessionCardBlank } from '@oui/app-core/src/components/SessionCard';
import { Heading, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { flatCard, useTheme } from '@oui/app-core/src/styles';
import { FragmentOf, graphql, readFragment } from '@oui/lib/src/graphql/tada';

import { ContentType, OnboardingVariant } from '@src/types';

export const NextSessionFragment = graphql(`
  fragment NextSession on Role {
    sessions {
      session {
        sessionID
        order
        name
        description
        contentType
        agenda
        illustration {
          fileUploadID
          gcsPath
        }
        completedIllustration {
          fileUploadID
          gcsPath
        }
        group {
          groupID
          name
        }
      }
      status
    }
    productConfig {
      ... on RoleAvivaConfig {
        onboardingVariant
      }
    }
  }
`);

/**
 * Shows the next learn session, if any
 */
export const NextSession = ({
  data: _data,
}: {
  data: FragmentOf<typeof NextSessionFragment> | null | undefined;
}) => {
  const navigation = useNavigation();
  const data = readFragment(NextSessionFragment, _data);
  const { theme } = useTheme();
  const { $t } = useI18n();

  const item =
    data?.sessions.find((s) => s.status === 'IN_PROGRESS') ||
    data?.sessions.find((s) => s.status === 'UNLOCKED');
  const sessionNum =
    (data?.sessions.findIndex((s) => s.session.sessionID === item?.session.sessionID) ?? 0) + 1;

  const isCollaborativeOnboarding =
    data?.productConfig?.__typename === 'RoleAvivaConfig' &&
    data?.productConfig?.onboardingVariant === OnboardingVariant.COLLABORATIVE;

  const allComplete: boolean = [...(data?.sessions ?? [])].reverse()[0]?.status === 'COMPLETED';

  return (
    <View
      testID="NextSession"
      style={[
        flatCard,
        {
          gap: 15,
        },
      ]}
    >
      {allComplete ? null : (
        <Heading
          text={$t({ defaultMessage: 'Learn', id: 'NextSession_heading' })}
          testID="NextSession_heading"
          level={3}
        />
      )}
      {item ? (
        <View
          style={{
            gap: 10,
          }}
        >
          <SessionCard
            testID={`SessionCard_${item.session.contentType}`}
            state={item.status === 'COMPLETED' ? 'complete' : 'unlocked'}
            session={{
              num: sessionNum,
              details: [], // Never display agenda in this component
              title: item.session.name,
              subtitle: item.session.description,
              illustrationUrl: item.session.illustration?.gcsPath,
            }}
            onPressTop={() => {
              if (isCollaborativeOnboarding && item.session.contentType === ContentType.MYPLAN) {
                navigation.navigate('ControlledMyStoryMyPlan');
              } else {
                navigation.navigate('Conversation', {
                  num: sessionNum.toString(),
                  title: item.session.name,
                  ID: item.session.contentType || 'TEST::convo',
                  completed: item.status === 'COMPLETED' ? ('true' as const) : ('false' as const),
                });
              }
            }}
          />
          <View
            row
            spacing={10}
            style={{ marginLeft: 6 }}
            accessible
            aria-label={$t(
              {
                id: 'Home_sessionsDoneAccessibilityLabel',
                defaultMessage: 'Session progress: {completedCount} of {totalCount} complete',
              },
              {
                completedCount: sessionNum - 1,
                totalCount: data?.sessions.length ?? 12,
              },
            )}
          >
            <Text
              text={$t({ id: 'Home_sessionsDone', defaultMessage: 'Sessions done' })}
              weight="semibold"
              size={15}
              color={theme.color.gray300}
            />
            <Text
              text={$t(
                {
                  id: 'Home_sessionProgress',
                  defaultMessage: `{completedCount}/{totalCount}`,
                },
                {
                  completedCount: sessionNum - 1,
                  totalCount: data?.sessions.length ?? 12,
                },
              )}
              testID="Home_sessionProgress"
              size={15}
              color={theme.color.gray300}
            />
            <SegmentedProgressBar
              length={data?.sessions.length ?? 12}
              height={6}
              progress={sessionNum - 1}
            />
          </View>
        </View>
      ) : allComplete ? null : (
        <SessionCardBlank />
      )}
    </View>
  );
};

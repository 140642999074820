import { useCallback, useEffect, useRef, useState } from 'react';

import type { MyStoryMyPlanCompositionDataHash } from '@oui/lib/src/myStoryMyPlanComposition';
import type { ConvoNode } from '@oui/lib/src/stateMachine';
import type { ProductVariant } from '@oui/lib/src/types';

import { IS_PRODUCTION } from '../constants';

type CMSConversationPreviewEvent = {
  type: 'CMSConversationPreviewEvent';
  props: { content: Extract<ConvoNode, { type: 'Seq' }> };
};

type ClinicianControlledMyStoryMyPlanPreviewEvent = {
  type: 'ClinicianControlledMyStoryMyPlanPreviewEvent';
  props: {
    productVariant: ProductVariant;
    subscriptionData?: MyStoryMyPlanCompositionDataHash | null;
  };
};

type Message = CMSConversationPreviewEvent | ClinicianControlledMyStoryMyPlanPreviewEvent;

function isValidPostMessageOrigin(origin: string) {
  if (IS_PRODUCTION) {
    return origin.endsWith('.oui.health');
  }
  return origin.endsWith('.oui.dev') || origin.endsWith('//localhost:3000');
}

export function useSendToIframe<T extends Message['type']>(eventType: T) {
  // must be initialized after global property set on page load
  const IFRAME_ORIGIN = global.window?.OUI_AVIVA_DOMAIN
    ? `https://${global.window?.OUI_AVIVA_DOMAIN}`
    : 'https://localhost:19006';
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const sendToIframe = useCallback(
    (content: Extract<Message, { type: T }>['props']) => {
      const message = {
        type: eventType,
        props: content,
      };
      iframeRef.current?.contentWindow?.postMessage(message, IFRAME_ORIGIN);
    },
    [eventType, IFRAME_ORIGIN],
  );

  return { iframeRef, sendToIframe, iframeOrigin: IFRAME_ORIGIN };
}

export function useIframeProps<T extends Message['type']>(eventType: T) {
  const [props, setProps] = useState<Extract<Message, { type: T }>['props']>();

  useEffect(() => {
    function receiver(event: MessageEvent<Message | string | {}>) {
      if (isValidPostMessageOrigin(event.origin)) {
        if (
          typeof event.data === 'object' &&
          'type' in event.data &&
          event.data.type === eventType
        ) {
          // @ts-expect-error TS isn't smart enough to refine event.data.props even though
          // we check data.type === eventType
          setProps(event.data.props);
        }
      }
    }
    window.addEventListener('message', receiver, false);
  }, [eventType]);

  return props;
}

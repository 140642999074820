import { useCallback } from 'react';

import { LessonLearned } from '@oui/lib/src/types';

import { useTheme } from '../../styles';
import { Text } from '../Text';
import { TextInput } from '../TextInput';
import { View } from '../View';

export const EditLessonsLearnedForm = ({
  data,
  onEdit,
}: {
  data: LessonLearned[];
  onEdit: (toMerge: LessonLearned[]) => void;
}) => {
  const { theme } = useTheme();

  const updateLessonsLearned = useCallback(
    (index: number, lesson: string) => {
      const newData = data.map((item, i) => (i === index ? { ...item, text: lesson } : item));
      onEdit(newData);
    },
    [data, onEdit],
  );

  return (
    <View
      style={{
        gap: 15,
      }}
    >
      <Text
        text="Lesson from each session"
        weight="semibold"
        color={theme.color.gray300}
        size={15}
      />

      <View>
        {data?.length
          ? data.map((lesson, index) => (
              <View key={index} row style={{ marginBottom: 10, alignItems: 'flex-start' }}>
                <Text
                  style={{
                    marginTop: 12,
                    width: 35,
                  }}
                  text={index + 1}
                  color={theme.color.gray300}
                />
                <TextInput
                  testID={`lesson-${index}`}
                  style={{
                    flexGrow: 1,
                  }}
                  value={lesson.text}
                  onChangeValue={(newValue) => {
                    updateLessonsLearned(index, newValue);
                  }}
                  multiline
                />
              </View>
            ))
          : null}
      </View>
    </View>
  );
};

import type { ApolloClient } from '@apollo/client';

import { CurrentUserOrganizationsQuery } from '@oui/app-core/src/hooks/useCurrentUser';
import { setUserProperties } from '@oui/app-core/src/lib/log';

export async function setOrganizationUserProperties(client: ApolloClient<unknown>) {
  const { data } = await client.query({ query: CurrentUserOrganizationsQuery });
  const organization = data?.organizations?.[0];
  if (organization) {
    setUserProperties({
      organizationID: organization.ID,
      organizationName: organization.name ?? 'Unknown',
    });
  }
}

'use client';

import merge from 'lodash/merge';
import {
  createContext,
  createElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Appearance, Platform, StyleSheet } from 'react-native';
import { PartialDeep } from 'type-fest';

type FontWeight = 'normal' | 'bold' | 'semibold';
type FontFamily = 'sans-serif' | 'serif';

export type Theme = {
  button: {
    small: ButtonTheme;
    normal: ButtonTheme;
    large: ButtonTheme;
  };
  color: {
    primary100: string;
    primary200: string;
    primary300: string;
    accent100: string;
    accent200: string;
    accent300: string;
    accentTwo100: string;
    accentTwo200: string;
    accentTwo300: string;
    accentThree100: string;
    accentThree200: string;
    accentThree300: string;
    dark: string;
    gray100: string;
    gray200: string;
    gray300: string;
    gray400: string;
    gray500: string;
    gray600: string;
    gray700: string;
    gray800: string;
    success: string;
    warning: string;
    danger: string;
  };
  typography: {
    display: FontTheme;
    heading1: FontTheme;
    heading2: FontTheme;
    heading3: FontTheme;
    lead: FontTheme;
    label: FontTheme;
    body: FontTheme;
    bodySmall: FontTheme;
    small: FontTheme;
  };
  checkbox: {
    size: number;
    sizeSmall: number;
    groupLabel: keyof Theme['typography'];
    optionLabel: keyof Theme['typography'];
    optionSpacing: number;
    optionLabelSpacing: number;
  };
  textInput: {
    outlined: {
      paddingVertical: number;
      marginVertical: number;
      fontSize?: number;
      lineHeight?: number;
    };
  };
  slider: {
    color: string;
  };
};
export type PartialTheme = PartialDeep<Theme>;

export type FontTheme = {
  size: number;
  lineHeight: number;
  color?: keyof Theme['color'];
  weight?: FontWeight;
  family?: FontFamily;
};

type ButtonTheme = {
  fontSize: number;
  lineHeight: number;
  iconSize: number;
  paddingHorizontal: number;
  paddingVertical: number;
  borderRadius: number;
};

export const DEFAULT_THEME: Theme = {
  button: {
    small: {
      paddingVertical: 3,
      paddingHorizontal: 15,
      fontSize: 15,
      lineHeight: 20,
      borderRadius: 22,
      iconSize: 14,
    },
    normal: {
      paddingVertical: 8,
      paddingHorizontal: 20,
      fontSize: 17,
      lineHeight: 23,
      borderRadius: 22,
      iconSize: 18,
    },
    large: {
      paddingVertical: 28,
      paddingHorizontal: 30,
      fontSize: 21,
      lineHeight: 20,
      borderRadius: 80,
      iconSize: 26,
    },
  },
  color: {
    primary100: '#ae595b',
    primary200: '#ce6a6b',
    primary300: '#ebaca2',
    accent100: '#bed3c3',
    accent200: '#ebf1ed',
    accent300: '#f4f8f5',
    accentTwo100: '#008689',
    accentTwo200: '#008689',
    accentTwo300: '#008689',
    accentThree100: '#8b688d',
    accentThree200: '#8b688d',
    accentThree300: '#8b688d',
    dark: '#202e53',
    gray100: '#242226',
    gray200: '#322c3a',
    gray300: '#454154',
    gray400: '#6a6a7c',
    gray500: '#9191a3',
    gray600: '#c5c5d3',
    gray700: '#efeff4',
    gray800: '#f7f7f9',
    success: '#128918',
    warning: '#ecbf2d',
    danger: '#932a2a',
  },
  typography: {
    display: {
      size: 28,
      lineHeight: 35,
      weight: 'semibold',
      family: 'serif',
    },
    heading1: {
      size: 28,
      lineHeight: 38,
      weight: 'bold',
    },
    heading2: {
      size: 21,
      lineHeight: 29,
      weight: 'bold',
    },
    heading3: {
      size: 17,
      lineHeight: 23,
      weight: 'bold',
    },
    lead: {
      size: 21,
      lineHeight: 29,
      weight: 'semibold',
    },
    label: {
      size: 17,
      lineHeight: 23,
      weight: 'semibold',
    },
    body: {
      size: 17,
      lineHeight: 20,
    },
    bodySmall: {
      size: 15,
      lineHeight: 20,
    },
    small: {
      size: 13,
      lineHeight: 20,
      weight: 'semibold',
    },
  },
  checkbox: {
    size: 25,
    sizeSmall: 20,
    optionLabel: 'label',
    groupLabel: 'label',
    optionSpacing: 20,
    optionLabelSpacing: 15,
  },
  textInput: {
    outlined: {
      paddingVertical: 11,
      marginVertical: 0,
      fontSize: 15,
      lineHeight: 20,
    },
  },
  slider: {
    color: '#008689',
  },
};

const webDefault = merge({}, DEFAULT_THEME, {
  typography: {
    body: {
      size: 15,
      lineHeight: 20,
    },
    label: {
      size: 15,
      lineHeight: 20,
    },
  },
  checkbox: {
    size: 20,
    groupLabel: 'label',
    optionLabel: 'body',
    optionSpacing: 10,
    optionLabelSpacing: 10,
  },
  textInput: {
    outlined: {
      paddingVertical: 11,
      marginVertical: 0,
      fontSize: 15,
      lineHeight: 20,
    },
  },
} satisfies PartialTheme);

let initializedTheme = Platform.OS === 'web' ? webDefault : DEFAULT_THEME;

export function getPlatformTheme(key?: 'native' | 'web', overrides?: PartialTheme) {
  const base = key === 'web' ? webDefault : DEFAULT_THEME;
  return merge({}, base, overrides);
}

export function mergeTheme(toMerge: PartialTheme) {
  return merge({}, initializedTheme, toMerge);
}

const ThemeContext = createContext<Theme>(initializedTheme);

export function ThemeProvider({
  theme,
  children,
}: {
  theme: PartialTheme;
  children?: React.ReactNode;
}) {
  const value = useMemo(() => mergeTheme(theme), [theme]);
  return createElement(ThemeContext.Provider, { value }, children);
}

export function useSystemColorScheme() {
  const [scheme, setScheme] = useState(Appearance.getColorScheme());
  useEffect(() => {
    const listener = Appearance.addChangeListener((preferences) => {
      setScheme(preferences.colorScheme);
    });
    return () => listener.remove();
  }, []);
  return scheme ?? 'light';
}

// TODO this came from AppContext flags before splitting out into separate package
const ALLOW_DARK_THEME = false;

export function useTheme() {
  const theme = useContext(ThemeContext);
  const _scheme = useSystemColorScheme();
  const scheme = ALLOW_DARK_THEME ? _scheme : 'light';
  return { scheme, Shadow, theme };
}

export function WithTheme({
  children,
}: {
  children: (theme: ReturnType<typeof useTheme>) => ReactNode;
}) {
  const theme = useTheme();
  return children(theme);
}

export const CHAT_PREVIEW_BORDER_RADIUS = 15;

export const Shadow = StyleSheet.create({
  none: {
    elevation: 0,
    shadowColor: 'transparent',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 5,
  },
  default: {
    elevation: 2,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
  },
  low: {
    elevation: 2,
    shadowColor: DEFAULT_THEME.color.gray100,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
  },
  medium: {
    elevation: 4,
    shadowColor: DEFAULT_THEME.color.gray100,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 10,
  },
  high: {
    elevation: 6,
    shadowColor: DEFAULT_THEME.color.gray100,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 16,
  },
  overlay: {
    elevation: 16,
    shadowColor: DEFAULT_THEME.color.gray100,
    shadowOffset: { width: 0, height: 20 },
    shadowOpacity: 0.3,
    shadowRadius: 50,
  },
});

export const card = [
  {
    backgroundColor: 'white',
    borderRadius: 16,
  },
  Shadow.high,
];

export const flatCard = [
  {
    backgroundColor: 'white',
    borderRadius: 20,
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
];

export const CardContainer = [
  {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
  },
];

export const ScrollContainer = [
  {
    backgroundColor: DEFAULT_THEME.color.gray800,
    padding: 20,
  },
];
